import React from "react";
import { RoundButton, Text } from "@foris/avocado-suite";
import { AnimatePresence, motion, Variants } from "motion/react";
import styles from "./simpleResourceList.module.scss";

type ResourceItem = {
  id: string;
  label: string;
  rawData?: any;
};

interface SimpleResourceListProps {
  items?: ResourceItem[];
  isDisabled?: boolean;
  onDeleteResource?: (resourceData?: any) => void;
}

const variantsBoxContainer: Variants = {
  hidden: {
    opacity: 0,
    y: -100,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
      type: "spring",
      duration: 0.3,
      bounce: 0.25,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
      type: "spring",
      visualDuration: 0.3,
      bounce: 0.35,
    },
  },
};

const SimpleResourceList = ({
  items = [],
  isDisabled,
  onDeleteResource,
}: SimpleResourceListProps) => {
  return (
    <motion.ul className={styles.simpleResourceList}>
      <AnimatePresence>
        {items?.map(item => (
          <motion.li
            key={item?.id}
            className={styles.simpleResourceList_item}
            variants={variantsBoxContainer}
            initial="hidden"
            animate="show"
            exit="hidden"
          >
            <div className={styles.itemContent}>
              {item?.id && (
                <Text className={styles.itemContent_text} type="sm" weight="medium">
                  {item.id}
                </Text>
              )}

              {item?.label && (
                <Text className={styles.itemContent_text} type="sm">
                  {item.label}
                </Text>
              )}
            </div>

            {onDeleteResource && (
              <RoundButton
                icon="trash"
                size="sm"
                variant="ghost"
                disabled={isDisabled}
                onClick={() => onDeleteResource?.(item?.rawData)}
              />
            )}
          </motion.li>
        ))}
      </AnimatePresence>
    </motion.ul>
  );
};

export default SimpleResourceList;
