import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Select } from "@foris/avocado-suite";
import cx from "classnames";
import { programQuery } from "../graphql/program.queries";
import { Program, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorProgram, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const ProgramSelect: React.FC<ISelectorProgram> = ({
  modality,
  campus,
  department,
  shift,
  filterBy,
  onCallback,
  className,
  shouldHaveFilters = true,
  label,
  placeholder,
}: ISelectorProgram) => {
  const client = useApolloClient();
  const { scenario }: IParams = useParams();
  const [optionsPrograms, setOptionsPrograms] = useState([]);
  const [valuePrograms, setValuePrograms] = useState(null);
  let filterProgram = null;
  switch (filterBy) {
    case "shift":
      filterProgram = shift;
      break;
    case "modality":
      filterProgram = modality;
      break;
    case "department":
      filterProgram = department;
      break;
  }
  const [prevFilter, setPrevFilter] = useState(filterProgram);
  const [disabled, setDisabled] = useState(false);

  const getVariables = useCallback(() => {
    const campusObj = campus ? { campusId: { eq: campus } } : {};
    const departmentObj = department ? { departmentId: { eq: department } } : {};
    const modalityObj = modality ? { modalityId: { eq: modality } } : {};
    const shiftObj = shift ? { shiftId: { eq: shift } } : {};
    const variables = [];
    switch (filterBy) {
      case "shift":
        variables.push(campusObj, departmentObj, modalityObj, shiftObj);
        break;
      case "modality":
        variables.push(campusObj, departmentObj, modalityObj);
        break;
      case "department":
        variables.push(campusObj, departmentObj);
        break;
    }
    return {
      query: programQuery,
      variables: {
        scenarioId: scenario,
        filter: {
          fields: {
            and: [...variables].filter(filter => !!Object.values(filter).length),
          },
        },
      },
    };
  }, [scenario, campus, department, modality, shift, filterBy]);

  const setProgramsOptions = useCallback(async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optPrograms = [];
      if (dataQuery.programs.items.length > 0) {
        dataQuery.programs.items.forEach((program: Program) => {
          optPrograms.push({
            label: `${program.code} - ${program.name.replace(/-/g, "")}`,
            value: program.id,
          });
        });
      }
      setOptionsPrograms(optPrograms);
    }
  }, []);

  const getProgramsOptions = useCallback(async () => {
    try {
      const data = await client.query(getVariables());
      await setProgramsOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [client, getVariables, setProgramsOptions]);

  const clearSelect = useCallback(() => {
    setPrevFilter(null);
    setValuePrograms("");
    setDisabled(true);
  }, []);

  const getDataByFilter = useCallback(
    (filter?: any) => {
      if (shouldHaveFilters) {
        if (filter && filter !== prevFilter) {
          setPrevFilter(filter);
          getProgramsOptions();
          setDisabled(false);
        }
        if (!filter) clearSelect();
      }

      if (!shouldHaveFilters) {
        getProgramsOptions();
        setDisabled(false);
      }
    },
    [getProgramsOptions, prevFilter, clearSelect],
  );

  useEffect(() => {
    switch (filterBy) {
      case "shift":
        getDataByFilter(shift);
        break;
      case "modality":
        getDataByFilter(modality);
        break;
      case "department":
        getDataByFilter(department);
        break;
      default:
        clearSelect();
    }
  }, [modality, campus, department, shift, filterBy, clearSelect, getDataByFilter]);

  return (
    <div className={cx(css.selectors, className)}>
      <Select
        label={label}
        options={optionsPrograms}
        value={valuePrograms}
        placeholder={placeholder}
        isClearable
        disabled={disabled}
        onChange={(value: IOption) => {
          if (value) {
            setValuePrograms(value);
            onCallback(value);
          } else {
            setValuePrograms("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default ProgramSelect;
