import React, { useState, useEffect, useContext, useCallback, memo, FC } from "react";
import cx from "classnames";
import {
  add,
  applySpec,
  assoc,
  flip,
  has,
  identity,
  isEmpty,
  map,
  not,
  pipe,
  prop,
  reduce,
  reject,
  values,
} from "ramda";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import queryString from "query-string";
import { Button, TextField, SelectPagination, Text } from "@foris/avocado-suite";
import { populationsQuery } from "../graphql/populations.query";
import TermSelect from "@modules/vacancies/Groups/term-select";
import CampusSelect from "@modules/shared/selectors/Selectors/Campus";
import ModalitySelect from "@modules/shared/selectors/Selectors/Modality";
import ProgramSelect from "@modules/shared/selectors/Selectors/Program";
import { PathParams } from "@edhtypes/general";
import { Population } from "@models/ISchema";
import { IOption } from "@common/components/Select/Select";
import { DataReducerType } from "../context/types";
import { Context } from "../context/PackagesContext";
import { Types as PageTypes } from "../context/page.reducer";
import { Types as DataTypes } from "../context/data.reducer";
import css from "./packages.module.scss";

interface ISelectedPopulation {
  index: number;
  value: string;
  level: number;
  self: Population;
}

interface CreationFormProps {
  isLoading?: boolean;
  onSubmit: (dryRun: boolean, skipValidations: boolean, data?: DataReducerType) => void;
  onCancel?: () => void;
}

const CreationForm: FC<CreationFormProps> = ({ onSubmit, isLoading = false, onCancel }) => {
  // Hooks for handle the context and the client
  const client = useApolloClient();
  const { state, dispatch } = useContext(Context);
  const { origin, scenario }: PathParams = useParams();
  const params: queryString.ParsedQuery<string> = queryString?.parse(location?.search);
  const { t } = useTranslation();
  // States for handle the form
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [submitAndGoBack, setSubmitAndGoBack] = useState(false);
  const [selectedPopulation, setSelectedPopulation] = useState<ISelectedPopulation>(null);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [selectedCampus, setSelectedCampus] = useState("");
  const [selectedModality, setSelectedModality] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [packageName, setPackageName] = useState("");

  const selectorLabel = (population: Population) => {
    if (!population) return "";

    const strongWrapper = (value: string | number) => <strong>{value}</strong>;

    const campus = strongWrapper(population?.campus?.code);
    const program = strongWrapper(population?.program?.code);
    const curriculum = strongWrapper(population?.curriculum);
    const level = strongWrapper(population?.level);
    const modality = strongWrapper(population?.modality?.code);
    const department = strongWrapper(population?.program?.department?.code);
    const shift = strongWrapper(population?.shift?.code);

    return (
      <div className={css.select}>
        <Text type="sm" className={css.select_topLabel}>
          SE: {campus} | CA: {program} | CU: {curriculum} | NI: {level}
        </Text>
        <Text type="xs" className={css.select_bottomLabel}>
          ES: {department} | MO: {modality} | JO: {shift}
        </Text>
      </div>
    );
  };

  /**
   * Set "EDITION" as the active page so the context take us to the edition page.
   */
  const navigateToEdition = () => {
    dispatch({ type: PageTypes.SetActivePage, payload: "EDITION" });
  };

  const handlePackageCreation = () => {
    dispatch({
      type: DataTypes.AddCreation,
      payload: {
        population: selectedPopulation?.self,
        termId: selectedTerm,
        linkId: state?.data?.link?.id || undefined,
        packageName,
      },
    });
    setSubmitAndGoBack(true);
  };

  /**
   * Reject the populations that have already been created
   *
   * @param populations - Population[]
   * @return Population[]
   */
  const selectablePopulations = (populations: Population[]): Population[] => {
    const createdPopulationCodes = pipe(
      values,
      map(prop<Population>("population")),
      reduce((acc, { code }) => assoc(code, true, acc), {}),
    )(state?.data?.creations);
    return reject(pipe(prop<any>("code"), flip(has)(createdPopulationCodes)), populations);
  };

  /**
   * If a creation was added, submit a `dryRun` `packageCrud` request and
   * return to the `EDITION` view.
   */
  useEffect(() => {
    if (not(isEmpty(state?.data?.creations)) && submitAndGoBack) {
      setSubmitAndGoBack(false);
      onSubmit(true, true, state?.data);
      navigateToEdition();
    }
  }, [state?.data?.creations]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const size = 20;

      try {
        const queryParams = {
          query: populationsQuery,
          variables: {
            termId: selectedTerm,
            scenarioId: scenario,
            originId: origin,
            filterId: params?.advance,
            filter: {
              fields: {
                campusId: selectedCampus
                  ? {
                      eq: selectedCampus,
                    }
                  : undefined,
                modalityId: selectedModality
                  ? {
                      eq: selectedModality,
                    }
                  : undefined,
                programId: selectedProgram
                  ? {
                      eq: selectedProgram,
                    }
                  : undefined,
              },
              pagination: { page, size, searchTerm },
            },
          },
        };

        const { data } = await client.query(queryParams);
        const { pageInfo, items } = data?.cube?.complementedPopulations ?? {};

        const options = map<Population, Population>(
          applySpec({
            label: selectorLabel,
            value: prop("id"),
            index: pipe(prop<number, "packageIndex">("packageIndex"), add(1)),
            self: identity,
          }),
          selectablePopulations(items ?? []),
        );

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, selectedTerm, selectedCampus, selectedModality, selectedProgram],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = searchTerm === newSearchTerm ? page + 1 : 1;

    setSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(searchTerm, newSearchPage);
    dispatch({ type: DataTypes.SetPopulations, payload: options ?? [] });

    return {
      options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  const resetPopulationOptions = () => {
    setSearchTerm("");
    setSelectedPopulation(null);
    dispatch({ type: DataTypes.SetPopulations, payload: [] });
  };

  const handleTermSelect = (term = "") => {
    resetPopulationOptions();
    setSelectedTerm(term);
  };

  const termKey = `${selectedTerm}${selectedCampus}${selectedModality}${selectedProgram}`;
  const isRequiredFieldsSelected = !!(
    selectedTerm &&
    (selectedCampus || selectedModality || selectedProgram)
  );
  const linkCampus = state?.data?.link?.course?.curriculum?.program?.campus;
  const isGlobalCampus = !!linkCampus?.isGlobal;

  return (
    <section className={css.creationForm}>
      <div className={css.creationForm_content}>
        <TermSelect
          placeholder={t("create-package.forms.select-period.placeholder")}
          className={css.select}
          sendValues={({ term }) => handleTermSelect(term ?? "")}
          defaultTerm={state?.data?.link?.bundle?.term}
          isDisabled={!!state?.data?.link?.bundle?.term?.id}
        />

        <CampusSelect
          label={t("create-package.forms.select-campus.label")}
          placeholder={t("create-package.forms.select-campus.placeholder")}
          className={css.select}
          onCallback={(campus: IOption) => setSelectedCampus(campus?.value || "")}
          defaultCampus={!isGlobalCampus && linkCampus}
          isDisabled={!!linkCampus?.id && !isGlobalCampus}
        />
        <ModalitySelect
          label={t("create-package.forms.select-modality.label")}
          placeholder={t("create-package.forms.select-modality.placeholder")}
          className={css.textField}
          shouldHasDepartment={false}
          onCallback={(modality: IOption) => setSelectedModality(modality?.value || "")}
        />
        <ProgramSelect
          className={css.select}
          label={t("create-package.forms.select-career.label")}
          placeholder={t("create-package.forms.select-career.placeholder")}
          campus={selectedCampus}
          modality={selectedModality}
          shouldHaveFilters={false}
          filterBy="modality"
          onCallback={(program: IOption) => setSelectedProgram(program?.value || "")}
        />

        <SelectPagination
          key={termKey}
          className={css.select}
          disabled={!isRequiredFieldsSelected}
          value={selectedPopulation}
          debounceTimeout={500}
          label={t("create-package.forms.select-population.label")}
          placeholder={t("create-package.forms.select-population.placeholder")}
          onChange={setSelectedPopulation}
          loadOptions={loadOptions}
          onMenuClose={() => {
            if (searchTerm === "") return;

            setSearchTerm("");
            setPage(0);
          }}
        />
        <TextField
          className={css.textField}
          disabled={true}
          label={t("create-package.forms.input-numerator.label")}
          placeholder={t("create-package.forms.input-numerator.placeholder")}
          type={"number"}
          value={Boolean(selectedPopulation) ? selectedPopulation?.index : ""}
        />
        <TextField
          className={css.textField}
          disabled={!selectedPopulation || !selectedPopulation?.index}
          label={t("create-package.forms.input-package.label")}
          placeholder={t("create-package.forms.input-package.placeholder")}
          type="text"
          value={packageName}
          onChange={e => setPackageName(e.target.value)}
        />
      </div>
      <div className={css.creationForm_actionsButtons}>
        <Button
          className={cx(css.selector__button)}
          disabled={isLoading}
          variant="ghost"
          onClick={() => (onCancel ? onCancel() : navigateToEdition())}
        >
          {t("create-package.btn-cancel")}
        </Button>
        <Button
          disabled={!(selectedPopulation && packageName) || isLoading}
          className={cx(css.selector__button)}
          onClick={handlePackageCreation}
        >
          {t("create-package.btn-create")}
        </Button>
      </div>
    </section>
  );
};

export default memo(CreationForm);
