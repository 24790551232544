import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import cx from "classnames";
import {
  Weekly,
  Loading,
  Heading,
  ThemeStore,
  CardNotification,
  Button,
  Text,
} from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";
import { useWarnings } from "../../hooks/useWarnings";
import { AppContext } from "../../context/EditSessionsContext";
import { Week, Types as LinkTypes } from "../../context/linkData.reducer";
import { EditedSession, FormPageType, Types } from "../../context/formData.reducer";
import FormItem from "../FormEdit/FormItem";
import WeeksMultiSelect from "../../../components/WeeksMultiSelect";
import { useGetTermPartsByCategory } from "../../../hooks/useGetTermPartsByCategory";
import { TermPartsByCategory } from "@modules/sections/ISections";
import { isOutsideThreshold } from "../../utils/isOutsideThreshold";
import { useLazyQuery } from "react-apollo";
import { BLOCKING_THRESHOLD_QUERY } from "../../graphql/blockingThreshold.query";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import { ContextEDH } from "@context/ContextEDH";
import WeekTooltipLabel from "@modules/sections/components/week-tooltip-label";
import ReadableValuesList from "../readable-values-list";
import css from "./repeat.module.scss";

export interface IWeeklyItem {
  id: string;
  label: string;
  disabled: boolean;
  state?: "active" | "current";
  tooltip: { label: string };
  onClick: (item: IWeeklyItem) => void;
  highlight?: boolean;
}

interface IRepeatProps {
  originalWeeksBySessionId: { [key: string]: string[] };
  disabled?: boolean;
}

const getUpdatedIntervals = (intervals: Week[], weeks: { [id: string]: { checked: boolean } }) => {
  return intervals.map(interval => {
    const week = weeks && weeks?.[interval?.id] ? weeks[interval.id] : null;

    if (!!week) {
      return {
        ...interval,
        checked: week?.checked,
      };
    }

    return interval;
  });
};

const Repeat: React.FC<IRepeatProps> = ({ originalWeeksBySessionId, disabled = false }) => {
  const { scenario }: IParams = useParams();
  const { state: stateEDH } = useContext(ContextEDH);
  const { state, dispatch } = useContext(AppContext);
  const themeMode = ThemeStore.useThemeStore(state => state.theme.mode);
  const { t } = useTranslation();

  const [isReadOnly, setIsReadOnly] = useState(!state?.form?.isEditionEnabled?.["intervals"]);
  const [weeksByYear, setWeeksByYear] = useState(null);
  const [hasThreshold, setHasThreshold] = useState(false);
  const [warnings, setWarnings] = useWarnings(
    {
      numberOfSelectedWeeks: {
        message:
          "assignation-edit.form.notifications.weeks-repeat-validations.number-of-selected-weeks",
        active: false,
        predicate: (form: FormPageType) => {
          return !(form?.editedSessions?.intervals ?? []).some(interval => interval.checked);
        },
      },
    },
    form => !form?.savedSessionsToCreateIds && !form?.selectedSessions?.length,
  );
  const weeks = state?.form?.editedSessions?.intervals;
  const errors = state?.form?.errors;
  const thresholdDate = state?.form?.blockingThreshold;

  const [loadBlockingThreshold, { loading: isLoadingThreshold }] = useLazyQuery(
    BLOCKING_THRESHOLD_QUERY,
    {
      onCompleted(data) {
        if (hasThreshold) {
          dispatch({
            type: Types.SetBlockingThreshold,
            payload: data?.data?.blockingThreshold?.current_datetime ?? null,
          });
        } else {
          setHasThreshold(!!data?.data?.blockingThreshold?.current_datetime);
        }
      },
    },
  );

  const handleLoadBlockingThreshold = async () => {
    loadBlockingThreshold({
      variables: {
        scenarioId: scenario,
      },
    });
  };

  const [termPartsByCategory, getTermPartsByCategory] = useGetTermPartsByCategory({
    onMount: () => (termPartsByCategory: TermPartsByCategory[]) => {
      if (!state?.link?.termPartsByCategory?.length && termPartsByCategory?.length) {
        dispatch({ type: LinkTypes.TermPartsByCategory, payload: termPartsByCategory });
      }
    },
    linkId: state?.link?.info?.id,
  });

  const someDeletedSessionIsSelected = useMemo(() => {
    return state?.form?.selectedSessions
      ?.map(session => session?.id ?? "-")
      ?.some(id => state?.form?.sessionsToDelete?.hasOwnProperty(id));
  }, [state?.form?.selectedSessions, state?.form?.sessionsToDelete]);

  useEffect(() => {
    getTermPartsByCategory();
    handleLoadBlockingThreshold();
  }, []);

  const originalSelectedWeekIds = useMemo(() => {
    const result: { [key: string]: string } = {};
    const selectedSessionsIds = state?.form?.selectedSessions?.map(session => session.id);

    for (const sessionId in originalWeeksBySessionId) {
      if (selectedSessionsIds.includes(sessionId)) {
        originalWeeksBySessionId[sessionId]?.forEach(weekId => {
          result[weekId] = weekId;
        });
      }
    }

    return result;
  }, [originalWeeksBySessionId, state?.form?.selectedSessions]);

  const someSelectedSessionIsClonedOrNew = useMemo(() => {
    return state?.form?.selectedSessions?.some((session: EditedSession) => {
      return session?.isCloned || session?.isNew || session?.id?.includes("-");
    });
  }, [state?.form?.selectedSessions]);

  /**
   * Handle Week's form validations
   */
  useEffect(() => {
    setWarnings(state?.form);

    const selectedSessions = (state?.form?.selectedSessions ?? []).filter(
      (session: EditedSession) => !session?.id?.includes("-"),
    );

    const currentIntervalIds = (state?.form?.editedSessions?.intervals ?? [])
      .filter(interval => interval.checked)
      .map(interval => interval.id);

    const newRemovedWeekIdsBySessionId: { [key: string]: string[] } = {};

    for (const session of selectedSessions) {
      const originalWeekIds = originalWeeksBySessionId[session.id] ?? [];
      newRemovedWeekIdsBySessionId[session.id] = originalWeekIds.filter(
        id => !currentIntervalIds.includes(id),
      );
    }

    dispatch({ type: Types.SetRemovedWeekIdsBySessionId, payload: newRemovedWeekIdsBySessionId });
  }, [state?.form?.editedSessions?.intervals]);

  const handleWeekCheckboxClick = useCallback(
    (clickedWeek: Week) => () => {
      const intervals = getUpdatedIntervals(weeks, {
        [clickedWeek.id]: { checked: !clickedWeek.checked },
      });

      const weeksToModify = {
        [clickedWeek.id]: {
          ...clickedWeek,
          checked: !clickedWeek.checked,
        },
      };

      dispatch({
        type: Types.IntervalsEditedSessions,
        payload: { intervals, weeks: weeksToModify, originalWeeksBySessionId },
      });
    },
    [weeks, thresholdDate],
  );

  const getWeeksByYear = useCallback(
    (params?: { externalWeeksObj: { [id: string]: Week } }) => {
      const { externalWeeksObj } = params ?? {};

      const newWeeksByYear: { [key: string]: Week[] } = weeks?.reduce((acc, week) => {
        const year = dayjs(week.startingDate).year();
        if (year in acc) acc[year].push(week);
        else acc[year] = [week];
        return acc;
      }, {});

      const weeksToDisableCheck = {};

      const weeksByYear = Object.entries(newWeeksByYear ?? {}).reduce((acc, [year, itemWeeks]) => {
        const weeklyItems = itemWeeks.map((week, idx) => {
          const canEditPastSessions = stateEDH?.base?.base?.user?.abilities?.can_edit_past_sessions;
          const isOutsideThresholdDate =
            thresholdDate && hasThreshold
              ? isOutsideThreshold(thresholdDate, week, state?.form?.editedSessions?.blocks)
              : false;

          const canUnassignWeek =
            originalSelectedWeekIds?.[week.id] &&
            stateEDH?.base?.base?.user?.abilities?.can_unassign_intervals &&
            !someSelectedSessionIsClonedOrNew;

          const isWeekDisabled =
            someDeletedSessionIsSelected ||
            !week?.isInTerm ||
            (disabled && !canUnassignWeek) ||
            (isOutsideThresholdDate && !canEditPastSessions);

          if (isOutsideThresholdDate && week?.checked) {
            weeksToDisableCheck[week.id] = {
              ...week,
              checked: false,
            };
          }

          return {
            id: week.id,
            label: idx + 1,
            isDisabled: isWeekDisabled,
            isSelected: !!externalWeeksObj?.[week?.id]
              ? externalWeeksObj?.[week?.id]?.checked
              : week.checked,
            tooltipLabel: (
              <WeekTooltipLabel
                weekLabel={`S${week.value}`}
                startingDate={dayjs(week.startingDate).format("DD.MM")}
                endingDate={dayjs(week.endingDate).format("DD.MM")}
              />
            ) as any,
            onClick: !isWeekDisabled ? handleWeekCheckboxClick(week) : () => null,
            className: !!week.highlight ? css.weeklyItem__highlight : "",
          };
        });
        acc.push({ year, weeklyItems });
        return acc;
      }, []);

      return { weeksByYear, weeksToDisableCheck };
    },
    [weeks, thresholdDate],
  );

  const handleSetWeeksByYear = useCallback(
    (cb?: (weeks: Week[], weeksToDisable: any) => void) => {
      const { weeksByYear, weeksToDisableCheck } = getWeeksByYear();

      cb?.(weeks, weeksToDisableCheck);
      setWeeksByYear(weeksByYear);
    },
    [weeks, thresholdDate],
  );

  useEffect(() => {
    handleSetWeeksByYear();
  }, [weeks]);

  useEffect(() => {
    if (thresholdDate) {
      handleSetWeeksByYear((weeks, weeksToDisableCheck) => {
        dispatch({
          type: Types.IntervalsEditedSessions,
          payload: {
            intervals: getUpdatedIntervals(weeks, weeksToDisableCheck),
            weeks: weeksToDisableCheck,
            originalWeeksBySessionId,
          },
        });
      });
    }
  }, [thresholdDate]);

  /**
   * Transform the `highlight` into `checked` in the context's intervals.
   */
  const applySelectionOnContext = (weeksToModify: { [key: number]: Week }) => {
    if (!weeks?.length) return;

    dispatch({
      type: Types.IntervalsEditedSessions,
      payload: {
        intervals: getUpdatedIntervals(weeks, weeksToModify as any),
        weeks: weeksToModify,
        originalWeeksBySessionId,
      },
    });
  };

  /**
   * Highlight all `intervals` whose `id` is in the `weekIdsToHighlight`
   * object.
   */
  const highlightSelectionOnContext = (weekIdsToHighlight: { [key: number]: boolean }) => {
    if (!weeks?.length) return;

    const intervals = weeks.map(week => ({
      ...week,
      highlight: !!weekIdsToHighlight[week.id],
    }));

    dispatch({ type: Types.IntervalsEditedSessions, payload: { intervals } });
  };

  /**
   * Set all interval's highlight to the given param
   */
  const setWeeksHighlight = (highlight: boolean) => {
    if (!weeks?.length) return;
    dispatch({
      type: Types.IntervalsEditedSessions,
      payload: {
        intervals: weeks?.map(week => ({
          ...week,
          highlight: highlight && week?.isInTerm,
        })),
      },
    });
  };

  const readableSelectedWeeksByYear = useMemo(() => {
    const selectedByYear = [];

    (weeksByYear ?? [])?.forEach(year => {
      const selectedWeeks = [...(year?.weeklyItems ?? [])].filter(week => week.isSelected);

      const item = {
        title: year.year,
        value: `| Semana: ${selectedWeeks?.map(week => week?.label ?? "")?.join(", ")}`,
      };

      if (selectedWeeks?.length) {
        selectedByYear.push(item);
      }
    });

    return selectedByYear;
  }, [weeksByYear]);

  return (
    <FormItem
      title={t("assignation-edit.form.repeat-section.title")}
      type="intervals"
      isDisabled={disabled}
      onShowReadOnlyContent={setIsReadOnly}
    >
      {isLoadingThreshold && <Loading />}

      {isReadOnly ? (
        <ReadableValuesList className={css.readOnlyValues} items={readableSelectedWeeksByYear} />
      ) : (
        <>
          {hasThreshold && (
            <div className={css.repeatActions}>
              <Button
                className={css.repeatActions_actionButton}
                variant="secondary"
                onClick={handleLoadBlockingThreshold}
                disabled={isLoadingThreshold}
              >
                {t("assignation-edit.form.repeat-section.unmark-weeks")}
              </Button>

              <Text type="sm">
                {t("assignation-edit.form.repeat-section.unmark-weeks-description")}
              </Text>
            </div>
          )}

          <section className={css.repeat}>
            <WeeksMultiSelect
              disabled={someDeletedSessionIsSelected || disabled}
              applySelectionOnContext={applySelectionOnContext}
              highlightSelectionOnContext={highlightSelectionOnContext}
              onAllWeeksSelected={() => setWeeksHighlight(true)}
              weeks={weeks}
              termPartsByCategory={termPartsByCategory}
            />
            <section className={css.repeat_content}>
              <div
                className={cx(
                  css.weeklyContainer,
                  weeksByYear?.length <= 1 && css.weeklyContainer__singleWeekly,
                )}
              >
                {weeksByYear?.map(({ year, weeklyItems }) => (
                  <div key={year} className={css.weeklyContainer_weekly}>
                    <Heading className={css.weeklyLabel} type="h3">
                      {year}
                    </Heading>
                    <Weekly
                      className={cx(css.weeklyWeeks, themeMode === "dark" && css.weeklyWeeks__dark)}
                      weeklyItems={weeklyItems}
                    />
                  </div>
                ))}
              </div>

              {errors?.map((error, index) =>
                error.type === "Intervals" ? (
                  <CardNotification
                    key={index}
                    className={css.weeklyWarning}
                    state="error"
                    title={t("assignation-edit.form.notifications.weeks-repeat-validations.title")}
                    outlined
                  >
                    {error?.message}
                  </CardNotification>
                ) : null,
              )}
            </section>
          </section>
        </>
      )}

      {warnings.map((warning, index) => (
        <CardNotification
          key={index}
          className={css.weeklyWarning}
          state="warning"
          title={t("assignation-edit.form.notifications.weeks-repeat-validations.title")}
          outlined
        >
          {t(warning)}
        </CardNotification>
      ))}
    </FormItem>
  );
};

export default Repeat;
