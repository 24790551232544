/**
 * ScenariosSelect component provides a workspace-dependent scenario selection dropdown
 * with GraphQL integration for data fetching.
 *
 * Key features:
 * - Dynamic scenario fetching based on selected workspace
 * - Real-time data updates with network-only fetch policy
 * - Consistent styling with workspace selector
 * - Loading state handling with skeleton loader
 * - Error state fallback
 */

import React, { FC } from "react";
import { useQuery } from "react-apollo";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { GET_SCENARIOS } from "../../graphql/context.queries";

/**
 * Props interface for ScenariosSelect component
 * @property {any} workspaceValue - Selected workspace to filter scenarios
 * @property {any} scenarioValue - Currently selected scenario
 * @property {function} onChange - Callback function triggered when selection changes
 */
interface SelectProps {
  workspaceValue: any;
  scenarioValue: any;
  onChange: (value: any) => void;
}

/**
 * Custom styles for react-select component
 * Maintains consistent styling with other select components
 * using the application's design system tokens
 */
const customStyles = {
  control: (values: any) => ({
    ...values,
    fontSize: "0.875rem",
    border: "1px solid #e5e8ec",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#575d62",
    fontSize: "0.875rem",
  }),
};

/**
 * Renders a scenario selection dropdown filtered by the selected workspace
 * Handles loading and error states appropriately
 *
 * @param {SelectProps} props Component props
 * @param {any} props.workspaceValue Selected workspace for filtering scenarios
 * @param {function} props.onChange Callback for selection changes
 * @param {any} props.scenarioValue Currently selected scenario
 * @returns {JSX.Element} Styled select component or loading/error state
 */
const ScenariosSelect: FC<SelectProps> = ({ workspaceValue, onChange, scenarioValue }) => {
  // Fetch scenarios filtered by workspace, ensuring fresh data with network-only policy
  const { loading, error, data } = useQuery<any, any>(GET_SCENARIOS, {
    variables: {
      scenarioFilter: {
        workspaceId: workspaceValue?.value,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Skeleton height={"100%"} />
      </div>
    );
  if (error) return <Select options={[]} placeholder="Scenario" />;

  // Transform scenario data into select options format
  const options = data.base.scenarios.map((value: any) => {
    return { label: value.name, value: value.id };
  });

  return (
    <div style={{ padding: "10px 0" }}>
      {/* Customized select component with theme overrides matching workspace selector */}
      <Select
        styles={customStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#e5e8ec",
            primary: "#6993c9",
            neutral80: "#575d62",
            neutral90: "#575d62",
          },
        })}
        maxMenuHeight={100}
        options={options}
        placeholder="Seleccionar"
        value={scenarioValue}
        onChange={(value: any): void => onChange(value)}
      />
    </div>
  );
};

export default ScenariosSelect;
