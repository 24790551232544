/**
 * UserMenu Component
 *
 * A functional component that renders a user menu with the following features:
 * - Language selection (via MenuLanguage component)
 * - Theme switching functionality
 * - Optional help link (currently hidden)
 * - Logout button
 *
 * The component uses the Avocado Suite UI components and supports i18n translations.
 * On logout, it clears the localStorage.
 */

import React, { FC } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Text, Divider, Button } from "@foris/avocado-suite";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import SwitchTheme from "@common/components/switch-theme";
import MenuLanguage from "@common/layout/components/menu-language";
import scss from "./userMenu.module.scss";

const UserMenu: FC = () => {
  const { t } = useTranslation();
  const { getDarwinUrl } = useNavigationUrl();

  const logout = () => {
    localStorage.clear();

    const darwinUrl = getDarwinUrl();
    darwinUrl ? window.location.replace(darwinUrl) : window.location.reload();
  };

  return (
    <section className={scss.menuUser}>
      <ul className={scss.menuUser_options}>
        <MenuLanguage />
        <li className={scss.changeTheme}>
          <Text className={scss.changeTheme_label}>{t("user-menu.change-theme")}</Text>
          <SwitchTheme />
        </li>
        <li className={cx(scss.item, scss.item__link)}>
          <a
            className={scss.item_label}
            href="https://forisai.atlassian.net/servicedesk/customer/user/login?destination=portals"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("user-menu.help-link")}
          </a>
        </li>
      </ul>
      <footer className={scss.menuUser_logout}>
        <Divider className={scss.divider} />
        <Button variant="ghost" className={scss.btn} onClick={logout} leftIcon="logout">
          {t("user-menu.logout")}
        </Button>
      </footer>
    </section>
  );
};

export default UserMenu;
