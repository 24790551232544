import React from "react";
import { Box, Heading, Text } from "@foris/avocado-suite";
import { Icon, IconTypes } from "@foris/avocado-icons";
import styles from "./sectionBox.module.scss";

interface SectionBoxProps {
  title: string;
  titleExtraContent?: React.ReactNode;
  description?: string;
  icon: IconTypes;
  customContent?: React.ReactNode;
  buttons?: React.ReactNode;
}

const SectionBox = ({
  title,
  titleExtraContent,
  description,
  icon,
  customContent,
  buttons,
}: SectionBoxProps) => {
  return (
    <Box className={styles.sectionBox}>
      <Icon className={styles.sectionBox_icon} name={icon} size="lg" />

      <article className={styles.sectionBox_content}>
        <div className={styles.contentTitle}>
          <Heading className={styles.contentTitle_text} type="h2">
            {title}
          </Heading>

          {titleExtraContent}
        </div>

        {!!description && <Text className={styles.contentDescription}>{description}</Text>}

        {customContent}

        {!!buttons && <div className={styles.contentButtonGroup}>{buttons}</div>}
      </article>
    </Box>
  );
};

export default SectionBox;
