export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AcademicUnit = {
  __typename?: "AcademicUnit";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  academicUnitType?: Maybe<AcademicUnitType>;
};

export type AcademicUnitFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<AcademicUnitFilterInput>;
  campus?: Maybe<IntFilterInput>;
  school?: Maybe<IntFilterInput>;
  subject?: Maybe<IntFilterInput>;
};

export type AcademicUnitType = {
  __typename?: "AcademicUnitType";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type AccessCode = {
  __typename?: "AccessCode";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  isGlobal?: Maybe<Scalars["Boolean"]>;
  isSystem?: Maybe<Scalars["Boolean"]>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type Area = {
  __typename?: "Area";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type AssignmentFilterInput = {
  hasBlocks?: Maybe<Scalars["Boolean"]>;
  hasInstructors?: Maybe<Scalars["Boolean"]>;
  hasClassrooms?: Maybe<Scalars["Boolean"]>;
};

export type AvailabilityFilterInput = {
  dayId: Array<Maybe<Scalars["ID"]>>;
  blockRange?: Maybe<TimeRangeInputType>;
  intervalRange?: Maybe<IdRangeInput>;
  intervals?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type BaseMutation = {
  __typename?: "BaseMutation";
  createScenario?: Maybe<Scenario>;
};

export type BaseMutationCreateScenarioArgs = {
  input?: Maybe<CreateScenarioInput>;
};

export type BaseQuery = {
  __typename?: "BaseQuery";
  scenarios?: Maybe<Array<Maybe<Scenario>>>;
  workspaces?: Maybe<Array<Maybe<Workspace>>>;
  user?: Maybe<User>;
  users?: Maybe<UserPage>;
  settings?: Maybe<SettingPage>;
  classroomBookingTypes?: Maybe<ClassroomBookingTypePage>;
  modelExtensions?: Maybe<ModelExtensionPage>;
  tags?: Maybe<TagPage>;
  modules?: Maybe<Array<Maybe<Module>>>;
  customFields?: Maybe<Array<Maybe<Tag>>>;
  onOff?: Maybe<OnOffFeatures>;
  customUrls?: Maybe<Array<Maybe<CustomUrls>>>;
};

export type BaseQueryScenariosArgs = {
  filter?: Maybe<ScenariosInput>;
};

export type BaseQueryUserArgs = {
  id: Scalars["ID"];
};

export type BaseQueryUsersArgs = {
  filter?: Maybe<UserFilterInput>;
};

export type BaseQuerySettingsArgs = {
  filter?: Maybe<SettingFilterInput>;
};

export type BaseQueryClassroomBookingTypesArgs = {
  filter?: Maybe<ClassroomBookingTypeFilterInput>;
};

export type BaseQueryModelExtensionsArgs = {
  filter?: Maybe<ModelExtensionFilterInput>;
};

export type BaseQueryTagsArgs = {
  filter?: Maybe<TagFilterInput>;
};

export type BaseQueryCustomFieldsArgs = {
  resourceType: ResourceType;
};

export type Block = {
  __typename?: "Block";
  id: Scalars["ID"];
  day: Day;
  startingTime?: Maybe<Scalars["String"]>;
  endingTime?: Maybe<Scalars["String"]>;
  usable?: Maybe<Scalars["Boolean"]>;
  isSystem?: Maybe<Scalars["Boolean"]>;
};

export type BlockCustom = {
  __typename?: "BlockCustom";
  start?: Maybe<Array<Maybe<Scalars["String"]>>>;
  end?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type BlockFilterFieldsInput = {
  dayId?: Maybe<IntFilterInput>;
  groupBy?: Maybe<Array<Maybe<Scalars["String"]>>>;
  isSystem?: Maybe<Scalars["Boolean"]>;
  or?: Maybe<Array<BlockFilterFieldsInput>>;
  and?: Maybe<Array<BlockFilterFieldsInput>>;
};

export type BlockFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<BlockFilterFieldsInput>;
};

export type BlockPage = {
  __typename?: "BlockPage";
  items: Array<Block>;
  pageInfo?: Maybe<PageInfo>;
};

export type BlockRange = {
  __typename?: "BlockRange";
  start?: Maybe<Block>;
  end?: Maybe<Block>;
};

export type BlockingThreshold = {
  __typename?: "BlockingThreshold";
  period?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["String"]>;
  when?: Maybe<Scalars["String"]>;
  current_datetime?: Maybe<Scalars["String"]>;
};

export type BlocksAssignmentNotAllowed = {
  __typename?: "BlocksAssignmentNotAllowed";
  allowed?: Maybe<Scalars["Boolean"]>;
};

export type BookingClassroomClash = EditorClash & {
  __typename?: "BookingClassroomClash";
  block?: Maybe<Block>;
  interval?: Maybe<Interval>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  classroom?: Maybe<Classroom>;
};

export type BooleanFilterInput = {
  nil?: Maybe<Scalars["Boolean"]>;
  is?: Maybe<Scalars["Boolean"]>;
};

export type Building = {
  __typename?: "Building";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  campus: Campus;
  custom?: Maybe<Scalars["String"]>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
};

export type BuildingFilterFieldsInput = {
  id?: Maybe<IdFilterInput>;
  campusId?: Maybe<IntFilterInput>;
  and?: Maybe<BuildingFilterFieldsInput>;
  or?: Maybe<BuildingFilterFieldsInput>;
};

export type BuildingFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<BuildingFilterFieldsInput>;
};

export type BuildingPage = {
  __typename?: "BuildingPage";
  items: Array<Building>;
  pageInfo?: Maybe<PageInfo>;
};

export type Bundle = {
  __typename?: "Bundle";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  capacity?: Maybe<Scalars["Int"]>;
  links?: Maybe<Array<Maybe<Link>>>;
  sections?: Maybe<Array<Maybe<Section>>>;
  term?: Maybe<Term>;
};

export type BundleChangesetInput = {
  changeVacancies?: Maybe<Array<Maybe<ChangeBundleVacanciesInput>>>;
};

export type BundlePage = {
  __typename?: "BundlePage";
  items: Array<Bundle>;
  pageInfo?: Maybe<PageInfo>;
};

export type BundleValidationError = InvalidLinkChanges;

export enum CalendarPerspectiveEnum {
  AnnualView = "ANNUAL_VIEW",
  AllWeeksView = "ALL_WEEKS_VIEW",
  WeeksByTermView = "WEEKS_BY_TERM_VIEW",
}

export type CalendarRange = {
  __typename?: "CalendarRange";
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
};

export type Campus = {
  __typename?: "Campus";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  isGlobal?: Maybe<Scalars["Boolean"]>;
};

export type CampusFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<CampusSearchFieldsInput>;
};

export type CampusPage = {
  __typename?: "CampusPage";
  items: Array<Campus>;
  pageInfo?: Maybe<PageInfo>;
};

export type CampusSearchFieldsInput = {
  searchTerm?: Maybe<Scalars["String"]>;
  and?: Maybe<CampusSearchFieldsInput>;
  or?: Maybe<CampusSearchFieldsInput>;
  subject?: Maybe<IntFilterInput>;
};

export type CanDeletePackages = {
  __typename?: "CanDeletePackages";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type CanEditPackages = {
  __typename?: "CanEditPackages";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type CapacityFilterInput = {
  value?: Maybe<IntFilterInput>;
  allowedOverflow?: Maybe<Scalars["Boolean"]>;
};

export type ChangeBlocksInputType = {
  op: EditorOperation;
  day?: Maybe<Day>;
  days?: Maybe<Array<Maybe<Day>>>;
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
};

export type ChangeBundleVacanciesInput = {
  linkId: Scalars["ID"];
  changeGroupVacancies?: Maybe<Array<Maybe<ChangeGroupVacanciesInput>>>;
};

export type ChangeClassroomInputType = {
  op: EditorOperation;
  /** deprecated due to multiple allocation, must be a list of classrooms */
  classroomId?: Maybe<Scalars["ID"]>;
  classroomIds?: Maybe<Array<Scalars["ID"]>>;
};

export type ChangeCourseComponentInputType = {
  op: EditorOperation;
  courseComponentId: Scalars["ID"];
};

export type ChangeGroupVacanciesInput = {
  groupId: Scalars["ID"];
  vacancies: Scalars["Int"];
};

export type ChangeInstructorInputType = {
  op: EditorOperation;
  /** deprecated due to multiple allocation, must be a list of classrooms */
  instructorId?: Maybe<Scalars["ID"]>;
  instructorIds?: Maybe<Array<Scalars["ID"]>>;
};

export type ChangeIntervalsInputType = {
  op: EditorOperation;
  intervalIds?: Maybe<Array<Scalars["ID"]>>;
  uniqueInterval?: Maybe<Scalars["Boolean"]>;
  forceFork?: Maybe<Scalars["Boolean"]>;
};

export type ChangeLog = {
  __typename?: "ChangeLog";
  id: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  foreignKey: Scalars["String"];
  propertyUpdated: Scalars["String"];
  current: Scalars["String"];
  expected: Scalars["String"];
  status: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  contentType?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
};

export type ChangeLogFilterFieldsInput = {
  contentType?: Maybe<StringFilterInput>;
  foreignKey?: Maybe<IntFilterInput>;
  and?: Maybe<Array<Maybe<ChangeLogFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<ChangeLogFilterFieldsInput>>>;
};

export type ChangeLogFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ChangeLogFilterFieldsInput>;
};

export type ChangeLogPage = {
  __typename?: "ChangeLogPage";
  items: Array<ChangeLog>;
  pageInfo?: Maybe<PageInfo>;
};

export type ChangeVacanciesInputType = {
  op: EditorOperation;
  vacancies?: Maybe<Scalars["Int"]>;
};

export type ClashesBetweenPackages = {
  __typename?: "ClashesBetweenPackages";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type ClashesFilterInput = {
  package?: Maybe<Scalars["Boolean"]>;
  section?: Maybe<Scalars["Boolean"]>;
  classroom?: Maybe<Scalars["Boolean"]>;
  instructor?: Maybe<Scalars["Boolean"]>;
};

export type Classroom = {
  __typename?: "Classroom";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  classroomType?: Maybe<ClassroomType>;
  building: Building;
  capacity?: Maybe<Scalars["Int"]>;
  allowedOverflow?: Maybe<Scalars["Int"]>;
  floor?: Maybe<Scalars["Int"]>;
  usable?: Maybe<Scalars["Boolean"]>;
  usableInBookings?: Maybe<Scalars["Boolean"]>;
  assignment?: Maybe<ClassroomAssignment>;
};

export type ClassroomAssignment = {
  __typename?: "ClassroomAssignment";
  usagePercentage?: Maybe<Scalars["Float"]>;
  chairsOccupationPercentage?: Maybe<Scalars["Float"]>;
};

export type ClassroomBooking = {
  __typename?: "ClassroomBooking";
  id: Scalars["ID"];
  classroom?: Maybe<Classroom>;
  origin?: Maybe<Origin>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  relatedProgram?: Maybe<Program>;
  program?: Maybe<Scalars["String"]>;
  status?: Maybe<ClassroomBookingStatusEnum>;
  capacity?: Maybe<Scalars["Int"]>;
  responsible?: Maybe<User>;
  isRecurrent?: Maybe<Scalars["Boolean"]>;
  sessions: Array<Session>;
  type: ClassroomBookingType;
  createdAt?: Maybe<Scalars["String"]>;
};

export type ClassroomBookingChangeStatusInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  skipValidations?: Maybe<Scalars["Boolean"]>;
  bookingIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  status?: Maybe<ClassroomBookingStatusEnum>;
  comment?: Maybe<Scalars["String"]>;
};

export type ClassroomBookingCrudChangesetInput = {
  creates?: Maybe<Array<Maybe<CreateClassroomBookingInput>>>;
  updates?: Maybe<Array<Maybe<UpdateClassroomBookingInput>>>;
  deletes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type ClassroomBookingCrudInput = {
  /** a rehearsal of a performance or procedure before the real one */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** update booking details only or all data */
  onlyDetails?: Maybe<Scalars["Boolean"]>;
  /** the changes we want to perform in this operation */
  changesets?: Maybe<ClassroomBookingCrudChangesetInput>;
};

export type ClassroomBookingCrudPayload = {
  __typename?: "ClassroomBookingCrudPayload";
  creates?: Maybe<Array<Maybe<ClassroomBookingPayload>>>;
  updates?: Maybe<Array<Maybe<ClassroomBookingPayload>>>;
  deletes?: Maybe<Array<Maybe<ClassroomBookingPayload>>>;
};

export type ClassroomBookingCrudResult = {
  __typename?: "ClassroomBookingCrudResult";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  payload?: Maybe<ClassroomBookingCrudPayload>;
  onlyDetails?: Maybe<Scalars["Boolean"]>;
  /** If user can skip some of the validations because has not assigned an ability */
  userCanSkipValidations?: Maybe<Scalars["Boolean"]>;
};

export type ClassroomBookingFilterFieldsInput = {
  title?: Maybe<StringFilterInput>;
  date?: Maybe<StringFilterInput>;
  classroomId?: Maybe<IntFilterInput>;
  classroom?: Maybe<ClassroomFilterFieldsInput>;
  responsible?: Maybe<UserFilterFieldsInput>;
  status?: Maybe<StringFilterInput>;
  /** To search by a set of fields related to bookings  */
  fullText?: Maybe<Scalars["String"]>;
  or?: Maybe<Array<ClassroomBookingFilterFieldsInput>>;
  and?: Maybe<Array<ClassroomBookingFilterFieldsInput>>;
};

export type ClassroomBookingFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ClassroomBookingFilterFieldsInput>;
  orderBy?: Maybe<Array<Maybe<ClassroomBookingOrderByInput>>>;
};

export enum ClassroomBookingOrderByFields {
  Building = "building",
  Classroom = "classroom",
  CreatedAt = "createdAt",
  Date = "date",
  Responsible = "responsible",
  Status = "status",
  Title = "title",
}

export type ClassroomBookingOrderByInput = {
  field: ClassroomBookingOrderByFields;
  direction?: Maybe<OrderByDirection>;
};

export type ClassroomBookingPage = {
  __typename?: "ClassroomBookingPage";
  items: Array<ClassroomBooking>;
  pendingCount?: Maybe<Scalars["Int"]>;
  pageInfo?: Maybe<PageInfo>;
};

export type ClassroomBookingPayload = {
  __typename?: "ClassroomBookingPayload";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  booking?: Maybe<ClassroomBooking>;
  validationErrors?: Maybe<Array<Maybe<ClassroomBookingValidationErrorUnion>>>;
};

export enum ClassroomBookingStatusEnum {
  Pending = "pending",
  Rejected = "rejected",
  Confirmed = "confirmed",
  Cancelled = "cancelled",
  Expired = "expired",
}

export type ClassroomBookingStatusPayload = {
  __typename?: "ClassroomBookingStatusPayload";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  /**
   * # Whatever the operation was actually commited to the database.
   * # Cases:
   * # - true: when dryRun=false and no errors
   * # - true: when dryRun=false and skippedValidations=true
   * # - false: otherwise
   */
  commited?: Maybe<Scalars["Boolean"]>;
  bookings?: Maybe<Array<Maybe<ClassroomBooking>>>;
};

export type ClassroomBookingType = {
  __typename?: "ClassroomBookingType";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ClassroomBookingTypeFilterFieldsInput = {
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<SettingFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<SettingFilterFieldsInput>>>;
};

export type ClassroomBookingTypeFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ClassroomBookingTypeFilterFieldsInput>;
};

export type ClassroomBookingTypePage = {
  __typename?: "ClassroomBookingTypePage";
  items: Array<ClassroomBookingType>;
  pageInfo?: Maybe<PageInfo>;
};

export type ClassroomBookingValidationErrorUnion =
  | InvalidInput
  | InvalidDate
  | ClassroomClash
  | InvalidSessionInHoliday;

export type ClassroomClash = EditorClash & {
  __typename?: "ClassroomClash";
  block?: Maybe<Block>;
  interval?: Maybe<Interval>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  classroom?: Maybe<Classroom>;
};

export type ClassroomFilter = {
  __typename?: "ClassroomFilter";
  id: Scalars["ID"];
  classrooms?: Maybe<ClassroomPage>;
};

export type ClassroomFilterClassroomsArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type ClassroomFilterFieldsInput = {
  id?: Maybe<IntFilterInput>;
  buildingId?: Maybe<IntFilterInput>;
  building?: Maybe<BuildingFilterFieldsInput>;
  classroomTypeId?: Maybe<IntFilterInput>;
  availability?: Maybe<AvailabilityFilterInput>;
  dateAvailability?: Maybe<Array<Maybe<DateAvailabilityFilterInput>>>;
  availabilities?: Maybe<Array<Maybe<AvailabilityFilterInput>>>;
  capacity?: Maybe<CapacityFilterInput>;
  usableInBookings?: Maybe<BooleanFilterInput>;
  /** To search by a set of fields related to classrooms (classroom.name, building.code)  */
  fullText?: Maybe<Scalars["String"]>;
  hasEvents?: Maybe<Scalars["Boolean"]>;
  or?: Maybe<Array<ClassroomFilterFieldsInput>>;
  and?: Maybe<Array<ClassroomFilterFieldsInput>>;
};

export type ClassroomFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ClassroomFilterFieldsInput>;
  orderBy?: Maybe<Array<Maybe<ClassroomOrderByInput>>>;
};

export type ClassroomInfo = {
  __typename?: "ClassroomInfo";
  classroom?: Maybe<Classroom>;
};

export enum ClassroomOrderByFields {
  Id = "id",
  Name = "name",
  ClientCode = "client_code",
  Capacity = "capacity",
  Building = "building",
}

export type ClassroomOrderByInput = {
  field: ClassroomOrderByFields;
  direction?: Maybe<OrderByDirection>;
};

export type ClassroomPage = {
  __typename?: "ClassroomPage";
  items: Array<Classroom>;
  pageInfo?: Maybe<PageInfo>;
};

export type ClassroomPriority = {
  __typename?: "ClassroomPriority";
  id: Scalars["ID"];
  classroomType: ClassroomType;
};

export type ClassroomSearchInput = {
  page?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
  sectionId: Scalars["ID"];
  dayId?: Maybe<Scalars["Int"]>;
  startingTime?: Maybe<Scalars["String"]>;
  endingTime?: Maybe<Scalars["String"]>;
  intervalIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  blockIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  respectRequiredBuildings?: Maybe<Scalars["Boolean"]>;
  respectRequireCapacity?: Maybe<Scalars["Boolean"]>;
  respectRequiredClassroomType?: Maybe<Scalars["Boolean"]>;
  useNonUsableClassrooms?: Maybe<Scalars["Boolean"]>;
  useOverload?: Maybe<Scalars["Boolean"]>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type ClassroomType = {
  __typename?: "ClassroomType";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ClassroomTypeFilterFieldsInput = {
  buildingId?: Maybe<IntFilterInput>;
  and?: Maybe<Array<Maybe<ClassroomTypeFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<ClassroomTypeFilterFieldsInput>>>;
};

export type ClassroomTypeFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ClassroomTypeFilterFieldsInput>;
};

export type ClassroomTypePage = {
  __typename?: "ClassroomTypePage";
  items: Array<ClassroomType>;
  pageInfo?: Maybe<PageInfo>;
};

export type ClassroomsAssignmentNotAllowed = {
  __typename?: "ClassroomsAssignmentNotAllowed";
  allowed?: Maybe<Scalars["Boolean"]>;
};

export type Component = {
  __typename?: "Component";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ComponentFilterFieldsInput = {
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  courseComponent?: Maybe<CourseComponentFilterFieldsInput>;
  or?: Maybe<Array<ComponentFilterFieldsInput>>;
  and?: Maybe<Array<ComponentFilterFieldsInput>>;
};

export type ComponentFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ComponentFilterFieldsInput>;
};

export type ComponentPage = {
  __typename?: "ComponentPage";
  items: Array<Component>;
  pageInfo?: Maybe<PageInfo>;
};

export type Course = {
  __typename?: "Course";
  id: Scalars["ID"];
  curriculum: Curriculum;
  code: Scalars["String"];
  name: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  credits?: Maybe<Scalars["Float"]>;
  prerequisites?: Maybe<Array<Maybe<Scalars["String"]>>>;
  level?: Maybe<Scalars["Int"]>;
  electiveCode?: Maybe<Scalars["String"]>;
  chooseElectiveRule?: Maybe<Scalars["String"]>;
  choosePackageRule?: Maybe<Scalars["String"]>;
  widespread?: Maybe<Scalars["Boolean"]>;
  generatesPackages?: Maybe<Scalars["Boolean"]>;
  requireSameInstructorsAcrossFormats?: Maybe<Scalars["Boolean"]>;
  mirrorCode?: Maybe<Scalars["String"]>;
  requisitesExpression?: Maybe<Scalars["String"]>;
  meta?: Maybe<Scalars["String"]>;
  legacy?: Maybe<Scalars["Boolean"]>;
  usable?: Maybe<Scalars["Boolean"]>;
  active?: Maybe<Scalars["Boolean"]>;
  equivalences?: Maybe<Array<Maybe<Scalars["String"]>>>;
  tags?: Maybe<Scalars["String"]>;
  annotations?: Maybe<Scalars["String"]>;
  access?: Maybe<Scalars["String"]>;
  courseTypes?: Maybe<Array<Scalars["ID"]>>;
  courseComponents?: Maybe<Array<Maybe<CourseComponent>>>;
  buildings: Array<Building>;
  demand?: Maybe<Demand>;
  sectionsCount?: Maybe<Scalars["Int"]>;
  crosslistDemands?: Maybe<Array<Maybe<Demand>>>;
  isRule?: Maybe<Scalars["Boolean"]>;
  ruleCode?: Maybe<Array<Maybe<Scalars["String"]>>>;
  canEdit?: Maybe<Scalars["Boolean"]>;
  edhCanEdit?: Maybe<Scalars["Boolean"]>;
  tag?: Maybe<Scalars["String"]>;
  customFields?: Maybe<Array<Maybe<CustomField>>>;
};

export type CourseTagArgs = {
  id: Scalars["ID"];
};

export type CourseComponent = {
  __typename?: "CourseComponent";
  id: Scalars["ID"];
  type?: Maybe<Scalars["String"]>;
  course: Course;
  component: Component;
  academicStandard?: Maybe<Scalars["Int"]>;
  eventsPerInterval?: Maybe<Scalars["Int"]>;
  sessionsPerInterval?: Maybe<Scalars["Int"]>;
  minSectionSize?: Maybe<Scalars["Int"]>;
  optSectionSize?: Maybe<Scalars["Int"]>;
  maxSectionSize?: Maybe<Scalars["Int"]>;
  attendanceType?: Maybe<Scalars["String"]>;
  modulation?: Maybe<Scalars["String"]>;
  online?: Maybe<Scalars["Boolean"]>;
  usable?: Maybe<Scalars["Boolean"]>;
  inline?: Maybe<Scalars["Boolean"]>;
  legacy?: Maybe<Scalars["Boolean"]>;
  requiresClassroom?: Maybe<Scalars["Boolean"]>;
  requiresInstructor?: Maybe<Scalars["Boolean"]>;
  requiresTimeslot?: Maybe<Scalars["Boolean"]>;
  impartationIntervals?: Maybe<Array<Maybe<Interval>>>;
  classroomPriorities?: Maybe<Array<Maybe<ClassroomPriority>>>;
  stripePriorities?: Maybe<Array<Maybe<StripePriority>>>;
  maxBlocksPerSession?: Maybe<Scalars["Int"]>;
};

export type CourseComponentFilterFieldsInput = {
  course?: Maybe<CourseFilterFieldsInput>;
  courseId?: Maybe<IntFilterInput>;
  componentId?: Maybe<IntFilterInput>;
  or?: Maybe<Array<CourseComponentFilterFieldsInput>>;
  and?: Maybe<Array<CourseComponentFilterFieldsInput>>;
};

export type CourseComponentFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<CourseComponentFilterFieldsInput>;
};

export type CourseComponentPage = {
  __typename?: "CourseComponentPage";
  items: Array<CourseComponent>;
  pageInfo?: Maybe<PageInfo>;
};

export type CourseFilter = {
  __typename?: "CourseFilter";
  id: Scalars["ID"];
  courses?: Maybe<CoursePage>;
};

export type CourseFilterCoursesArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type CourseFilterFieldsInput = {
  id?: Maybe<IdFilterInput>;
  curriculum?: Maybe<CurriculumFilterFieldsInput>;
  curriculumId?: Maybe<IdFilterInput>;
  program?: Maybe<ProgramFilterFieldsInput>;
  componentId?: Maybe<IntFilterInput>;
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  level?: Maybe<IntFilterInput>;
  hasEvents?: Maybe<Scalars["Boolean"]>;
  filterAccessCode?: Maybe<Scalars["Boolean"]>;
  or?: Maybe<Array<CourseFilterFieldsInput>>;
  and?: Maybe<Array<CourseFilterFieldsInput>>;
  terms?: Maybe<Array<Maybe<Scalars["String"]>>>;
  active?: Maybe<Scalars["Boolean"]>;
};

export type CourseFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<CourseFilterFieldsInput>;
};

export type CourseInfo = {
  __typename?: "CourseInfo";
  course?: Maybe<Course>;
};

export type CoursePage = {
  __typename?: "CoursePage";
  items: Array<Course>;
  pageInfo?: Maybe<PageInfo>;
};

export type CreateClassroomBookingInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  skipValidations?: Maybe<Scalars["Boolean"]>;
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  capacity?: Maybe<Scalars["Int"]>;
  programId?: Maybe<Scalars["ID"]>;
  classroomBookingTypeId: Scalars["ID"];
  classroomId: Scalars["ID"];
  responsibleId: Scalars["ID"];
  sessions: Array<Maybe<DateTimeRangeInput>>;
  isRecurrent?: Maybe<Scalars["Boolean"]>;
};

export type CreateGroupInput = {
  linkId?: Maybe<Scalars["ID"]>;
  termId?: Maybe<Scalars["ID"]>;
  programId?: Maybe<Scalars["ID"]>;
  courseId?: Maybe<Scalars["ID"]>;
  campusId?: Maybe<Scalars["ID"]>;
  code?: Maybe<Scalars["String"]>;
  clientCode?: Maybe<Scalars["String"]>;
  capacity?: Maybe<Scalars["Int"]>;
  sharedPackComponent?: Maybe<Array<Maybe<SharedPackComponent>>>;
  /** a rehearsal of a performance or procedure before the real one. (for @nicolás) */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  language?: Maybe<Language>;
};

export type CreateLinkInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  skipValidations?: Maybe<Scalars["Boolean"]>;
  termId: Scalars["ID"];
  courseId: Scalars["ID"];
  code: Scalars["String"];
  assignSections?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  createSections?: Maybe<Array<Maybe<CreateSectionInput>>>;
  createGroup?: Maybe<CreateGroupInput>;
};

export type CreateLinkPayload = {
  __typename?: "CreateLinkPayload";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  validationErrors?: Maybe<Array<Maybe<LinkValidationErrorUnion>>>;
  link?: Maybe<Link>;
};

export type CreatePackageInput = {
  /** for multiedit */
  linkId?: Maybe<Scalars["ID"]>;
  /** for multiedit */
  termId?: Maybe<Scalars["ID"]>;
  /** for multiedit */
  packageId?: Maybe<Scalars["ID"]>;
  /** for multiedit */
  populationId?: Maybe<Scalars["ID"]>;
  /** for multiedit */
  populationCode?: Maybe<Scalars["String"]>;
  /** for multiedit */
  packageName?: Maybe<Scalars["String"]>;
};

export type CreateScenarioInput = {
  enviromentId: Scalars["ID"];
  fronScenarioId?: Maybe<Scalars["ID"]>;
  deep?: Maybe<Scalars["Boolean"]>;
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
};

export type CreateSectionInput = {
  code?: Maybe<Scalars["String"]>;
  courseComponentId: Scalars["ID"];
};

export type CubeContext = {
  __typename?: "CubeContext";
  workspaceId?: Maybe<Scalars["ID"]>;
  scenarioId?: Maybe<Scalars["ID"]>;
  originId?: Maybe<Scalars["ID"]>;
};

export type CubeMutation = {
  __typename?: "CubeMutation";
  editSession: EditSessionPayload;
  createSession: EditSessionPayload;
  editBundle: EditBundlePayload;
  disableSessions: DisableSessionPayload;
  createGroup: GroupPayload;
  disableGroup: GroupPayload;
  editSection: EditSectionPayload;
  inferLinkCode: Scalars["String"];
  createLink: CreateLinkPayload;
  classroomBookingChangeStatus?: Maybe<ClassroomBookingStatusPayload>;
  disableSection?: Maybe<EditSectionPayload>;
  createClassroomBooking?: Maybe<ClassroomBookingPayload>;
  instructorResponsibilityUpsert?: Maybe<InstructorResponsibilityPayload>;
  editPackageLabels?: Maybe<EditPackageLabelsPayload>;
  editGroupLabels?: Maybe<EditGroupLabelsPayload>;
  editGroupTexts?: Maybe<EditGroupTextsPayload>;
  sessionMultiEdit?: Maybe<SessionsMultiEditPayload>;
  updateGroupVisible: GroupPayload;
  updateGroupIsActive: GroupPayload;
  sessionsCrud?: Maybe<SessionCrudResult>;
  packagesCrud?: Maybe<PackageCrudResult>;
  classroomBookingsCrud?: Maybe<ClassroomBookingCrudResult>;
  packageLinkAssignment?: Maybe<PackageLinkAssignmentResult>;
  groupsManagerCrud?: Maybe<GroupsManagerCrudResult>;
  linksCrud?: Maybe<LinkCrudResult>;
  migrateGroup: GroupPayload;
  sessionsByHoliday?: Maybe<SessionsByHolidaysPayload>;
};

export type CubeMutationEditSessionArgs = {
  input?: Maybe<EditSessionInput>;
};

export type CubeMutationCreateSessionArgs = {
  input?: Maybe<EditSessionInput>;
};

export type CubeMutationEditBundleArgs = {
  input?: Maybe<EditBundleInput>;
};

export type CubeMutationDisableSessionsArgs = {
  sessionIds: Array<Maybe<Scalars["ID"]>>;
  intervalIds: Array<Maybe<Scalars["ID"]>>;
};

export type CubeMutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type CubeMutationDisableGroupArgs = {
  groupId: Scalars["ID"];
};

export type CubeMutationEditSectionArgs = {
  input?: Maybe<EditSectionInput>;
};

export type CubeMutationInferLinkCodeArgs = {
  courseId: Scalars["ID"];
  index: Scalars["Int"];
};

export type CubeMutationCreateLinkArgs = {
  input?: Maybe<CreateLinkInput>;
};

export type CubeMutationClassroomBookingChangeStatusArgs = {
  input?: Maybe<ClassroomBookingChangeStatusInput>;
};

export type CubeMutationDisableSectionArgs = {
  input?: Maybe<EditSectionInput>;
};

export type CubeMutationCreateClassroomBookingArgs = {
  input?: Maybe<CreateClassroomBookingInput>;
};

export type CubeMutationInstructorResponsibilityUpsertArgs = {
  input?: Maybe<InstructorResponsibilityInput>;
};

export type CubeMutationEditPackageLabelsArgs = {
  input?: Maybe<EditPackageLabelsInput>;
};

export type CubeMutationEditGroupLabelsArgs = {
  input?: Maybe<EditGroupLabelsInput>;
};

export type CubeMutationEditGroupTextsArgs = {
  input?: Maybe<EditGroupTextsInput>;
};

export type CubeMutationSessionMultiEditArgs = {
  input: SessionsMultiEditInput;
};

export type CubeMutationUpdateGroupVisibleArgs = {
  groupId: Scalars["ID"];
  visible: Scalars["Boolean"];
};

export type CubeMutationUpdateGroupIsActiveArgs = {
  groupId: Scalars["ID"];
  isActive: Scalars["Boolean"];
};

export type CubeMutationSessionsCrudArgs = {
  input: SessionCrudInput;
};

export type CubeMutationPackagesCrudArgs = {
  input: PackageCrudInput;
};

export type CubeMutationClassroomBookingsCrudArgs = {
  input: ClassroomBookingCrudInput;
};

export type CubeMutationPackageLinkAssignmentArgs = {
  input: PackageLinkAssignmentInput;
};

export type CubeMutationGroupsManagerCrudArgs = {
  input: GroupsManagerCrudInput;
};

export type CubeMutationLinksCrudArgs = {
  input: LinkCrudInput;
};

export type CubeMutationMigrateGroupArgs = {
  input: GroupMigrationInput;
};

export type CubeMutationSessionsByHolidayArgs = {
  input?: Maybe<DeleteSessionsByHolidaysInput>;
};

export type CubeQuery = {
  __typename?: "CubeQuery";
  event?: Maybe<Event>;
  events: EventPage;
  session?: Maybe<Session>;
  sessions: SessionPage;
  section?: Maybe<Section>;
  sections: SectionPage;
  link?: Maybe<Link>;
  links: LinkPage;
  population?: Maybe<Population>;
  populations: PopulationPage;
  complementedPopulations: PopulationPage;
  package?: Maybe<Package>;
  packages: PackagePage;
  packagesLegacy: PackagePage;
  classrooms: ClassroomPage;
  instructors: InstructorPage;
  coursesWithSections: CoursePage;
  editorView?: Maybe<EditorView>;
  editorClassroom: ClassroomPage;
  editorInstructors: InstructorPage;
  editorWeeks?: Maybe<Array<Maybe<Interval>>>;
  editorRestrictions?: Maybe<Array<Maybe<EditorRestriction>>>;
  editorResources?: Maybe<Array<Maybe<EditorResouceView>>>;
  groups: GroupPage;
  group?: Maybe<Group>;
  groupEnrollments: GroupEnrollmentPage;
  bundles: BundlePage;
  bundle?: Maybe<Bundle>;
  sessionRecommendations?: Maybe<SessionRecommendation>;
  sectionsSearch: SectionPage;
  demands?: Maybe<DemandPage>;
  forecastCourses?: Maybe<CoursePage>;
  classroomsAvailabilities: ClassroomPage;
  classroomBooking?: Maybe<ClassroomBooking>;
  classroomBookings?: Maybe<ClassroomBookingPage>;
  forecastCurriculums?: Maybe<CurriculumPage>;
  instructorsAdvancedSearch?: Maybe<InstructorPage>;
  groupsManagerGroupChanges: GroupChangePage;
  groupsManagerSearch: GroupPage;
};

export type CubeQueryEventArgs = {
  id: Scalars["ID"];
};

export type CubeQueryEventsArgs = {
  filter?: Maybe<PaginationInput>;
};

export type CubeQuerySessionArgs = {
  id: Scalars["ID"];
};

export type CubeQuerySessionsArgs = {
  filter?: Maybe<PaginationInput>;
};

export type CubeQuerySectionArgs = {
  id: Scalars["ID"];
};

export type CubeQuerySectionsArgs = {
  filter?: Maybe<PaginationInput>;
};

export type CubeQueryLinkArgs = {
  id: Scalars["ID"];
};

export type CubeQueryLinksArgs = {
  filter?: Maybe<LinkFilterInput>;
};

export type CubeQueryPopulationArgs = {
  id: Scalars["ID"];
};

export type CubeQueryPopulationsArgs = {
  filter?: Maybe<PaginationInput>;
};

export type CubeQueryComplementedPopulationsArgs = {
  filter?: Maybe<PopulationFilterInput>;
  termId: Scalars["ID"];
};

export type CubeQueryPackageArgs = {
  id: Scalars["ID"];
};

export type CubeQueryPackagesArgs = {
  filter?: Maybe<PackageFilterInput>;
  campusId?: Maybe<Scalars["ID"]>;
};

export type CubeQueryPackagesLegacyArgs = {
  filter?: Maybe<PaginationInput>;
  campusId?: Maybe<Scalars["ID"]>;
};

export type CubeQueryClassroomsArgs = {
  filter?: Maybe<PaginationInput>;
};

export type CubeQueryInstructorsArgs = {
  filter?: Maybe<PaginationInput>;
};

export type CubeQueryCoursesWithSectionsArgs = {
  filter?: Maybe<PaginationInput>;
};

export type CubeQueryEditorViewArgs = {
  resourceType?: Maybe<ResourceType>;
  resourceId?: Maybe<Scalars["ID"]>;
};

export type CubeQueryEditorClassroomArgs = {
  filter?: Maybe<ClassroomSearchInput>;
};

export type CubeQueryEditorInstructorsArgs = {
  filter?: Maybe<InstructorSearchInput>;
};

export type CubeQueryEditorWeeksArgs = {
  resourceType?: Maybe<ResourceType>;
  resourceId?: Maybe<Scalars["ID"]>;
};

export type CubeQueryEditorRestrictionsArgs = {
  resourceType?: Maybe<ResourceType>;
  resourceId?: Maybe<Scalars["ID"]>;
};

export type CubeQueryEditorResourcesArgs = {
  resourceType?: Maybe<ResourceType>;
  resourceId?: Maybe<Scalars["ID"]>;
};

export type CubeQueryGroupsArgs = {
  filter?: Maybe<PaginationInput>;
};

export type CubeQueryGroupArgs = {
  id: Scalars["ID"];
};

export type CubeQueryGroupEnrollmentsArgs = {
  groupId?: Maybe<Scalars["ID"]>;
  groupCode?: Maybe<Scalars["String"]>;
  filter?: Maybe<GroupsManagerEnrollmentsFilterInputType>;
};

export type CubeQueryBundlesArgs = {
  filter?: Maybe<PaginationInput>;
};

export type CubeQueryBundleArgs = {
  id: Scalars["ID"];
};

export type CubeQuerySessionRecommendationsArgs = {
  sessionId?: Maybe<Scalars["ID"]>;
  sessionIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type CubeQuerySectionsSearchArgs = {
  filter?: Maybe<SectionFilterInput>;
};

export type CubeQueryDemandsArgs = {
  filter?: Maybe<DemandFilterInput>;
};

export type CubeQueryForecastCoursesArgs = {
  filter?: Maybe<CourseFilterInput>;
};

export type CubeQueryClassroomsAvailabilitiesArgs = {
  classroomBookingId?: Maybe<Scalars["ID"]>;
  filter?: Maybe<ClassroomFilterInput>;
};

export type CubeQueryClassroomBookingArgs = {
  id: Scalars["ID"];
};

export type CubeQueryClassroomBookingsArgs = {
  filter?: Maybe<ClassroomBookingFilterInput>;
};

export type CubeQueryForecastCurriculumsArgs = {
  filter?: Maybe<CurriculumFilterInput>;
};

export type CubeQueryInstructorsAdvancedSearchArgs = {
  filter?: Maybe<InstructorFilterInput>;
};

export type CubeQueryGroupsManagerGroupChangesArgs = {
  filter?: Maybe<GroupsManagerGroupChangesFilterInput>;
};

export type CubeQueryGroupsManagerSearchArgs = {
  filter?: Maybe<GroupsManagerSearchFilterInput>;
};

export type Curriculum = {
  __typename?: "Curriculum";
  id: Scalars["ID"];
  program: Program;
  code: Scalars["String"];
  name: Scalars["String"];
  maxCreditsPerTerm?: Maybe<Scalars["Int"]>;
  maxCreditsPerYear?: Maybe<Scalars["Int"]>;
  maxSubjectsPerTerm?: Maybe<Scalars["Int"]>;
  maxSubjectsPerYear?: Maybe<Scalars["Int"]>;
  access?: Maybe<Scalars["String"]>;
};

export type CurriculumFilterFieldsInput = {
  id?: Maybe<IntFilterInput>;
  program_id?: Maybe<IntFilterInput>;
  program?: Maybe<ProgramFilterFieldsInput>;
  code?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<CurriculumFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<CurriculumFilterFieldsInput>>>;
};

export type CurriculumFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<CurriculumFilterFieldsInput>;
  groupByCode?: Maybe<Scalars["Boolean"]>;
};

export type CurriculumPage = {
  __typename?: "CurriculumPage";
  items: Array<Curriculum>;
  pageInfo?: Maybe<PageInfo>;
};

export type CustomField = {
  __typename?: "CustomField";
  id?: Maybe<Scalars["ID"]>;
  value?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
};

export type CustomUrls = {
  __typename?: "CustomUrls";
  code: Scalars["String"];
  url: Scalars["String"];
};

export type CustomValidation = {
  __typename?: "CustomValidation";
  code?: Maybe<Scalars["String"]>;
  info?: Maybe<EditorInfoUnion>;
  items?: Maybe<Array<Maybe<CustomValidationItem>>>;
};

export type CustomValidationItem = {
  __typename?: "CustomValidationItem";
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type DataQuery = {
  __typename?: "DataQuery";
  allBlocks: Array<Maybe<Block>>;
  origins?: Maybe<Array<Maybe<Origin>>>;
  institutionCalendarRange: CalendarRange;
  blocks: BlockPage;
  buildings: BuildingPage;
  departments: DepartmentPage;
  programs: ProgramPage;
  courses: CoursePage;
  labels: LabelPage;
  labelCategories: LabelCategoryPage;
  termParts: TermPartPage;
  courseSearch: CoursePage;
  intervals?: Maybe<Array<Maybe<Interval>>>;
  campuses?: Maybe<CampusPage>;
  buildingsByCampus?: Maybe<Array<Maybe<Building>>>;
  classroomTypes?: Maybe<ClassroomTypePage>;
  modalities?: Maybe<ModalityPage>;
  shifts?: Maybe<ShiftPage>;
  changeLogs?: Maybe<ChangeLogPage>;
  curriculums?: Maybe<CurriculumPage>;
  components?: Maybe<ComponentPage>;
  sharedPackComponents?: Maybe<Array<Maybe<SharedPackComponents>>>;
  instructorContractTypes?: Maybe<InstructorContractTypePage>;
  levels?: Maybe<Array<Maybe<Level>>>;
  terms?: Maybe<TermPage>;
  courseComponents?: Maybe<CourseComponentPage>;
  origin?: Maybe<Origin>;
  classroom?: Maybe<Classroom>;
  customBlocks?: Maybe<BlockCustom>;
  groupsManagerCampuses?: Maybe<CampusPage>;
  groupsManagerDepartments?: Maybe<GroupsManagerDepartmentPage>;
  groupsManagerSchools?: Maybe<GroupsManagerSchoolPage>;
  groupsManagerCourseTypes?: Maybe<GroupsManagerCourseTypePage>;
  groupsManagerSubjects?: Maybe<SubjectPage>;
  calendarPerspectives?: Maybe<Array<Maybe<CalendarPerspectiveEnum>>>;
  holidays?: Maybe<Array<Maybe<Holiday>>>;
  blockingThreshold?: Maybe<BlockingThreshold>;
  linkLogEntry?: Maybe<LinkLogEntryPage>;
};

export type DataQueryOriginsArgs = {
  filter?: Maybe<OriginInput>;
};

export type DataQueryBlocksArgs = {
  filter?: Maybe<BlockFilterInput>;
};

export type DataQueryBuildingsArgs = {
  filter?: Maybe<BuildingFilterInput>;
};

export type DataQueryDepartmentsArgs = {
  filter?: Maybe<DepartmentFilterInput>;
};

export type DataQueryProgramsArgs = {
  filter?: Maybe<ProgramFilterInput>;
};

export type DataQueryCoursesArgs = {
  filter?: Maybe<CourseFilterInput>;
};

export type DataQueryLabelsArgs = {
  filter?: Maybe<LabelFilterInput>;
};

export type DataQueryLabelCategoriesArgs = {
  filter?: Maybe<LabelCategoryFilterInput>;
};

export type DataQueryTermPartsArgs = {
  filter?: Maybe<TermPartFilterInput>;
};

export type DataQueryCourseSearchArgs = {
  filter?: Maybe<CourseFilterInput>;
};

export type DataQueryCampusesArgs = {
  filter?: Maybe<CampusFilterInput>;
};

export type DataQueryBuildingsByCampusArgs = {
  campusId: Scalars["ID"];
};

export type DataQueryClassroomTypesArgs = {
  filter?: Maybe<ClassroomTypeFilterInput>;
};

export type DataQueryModalitiesArgs = {
  filter?: Maybe<ModalityFilterInput>;
};

export type DataQueryShiftsArgs = {
  filter?: Maybe<ShiftFilterInput>;
};

export type DataQueryChangeLogsArgs = {
  filter?: Maybe<ChangeLogFilterInput>;
};

export type DataQueryCurriculumsArgs = {
  filter?: Maybe<CurriculumFilterInput>;
};

export type DataQueryComponentsArgs = {
  filter?: Maybe<ComponentFilterInput>;
};

export type DataQuerySharedPackComponentsArgs = {
  input?: Maybe<SharedPackComponentsInput>;
};

export type DataQueryInstructorContractTypesArgs = {
  filter?: Maybe<InstructorContractTypeFilterInput>;
};

export type DataQueryTermsArgs = {
  filter?: Maybe<TermFilterInput>;
};

export type DataQueryCourseComponentsArgs = {
  filter?: Maybe<CourseComponentFilterInput>;
};

export type DataQueryOriginArgs = {
  id: Scalars["ID"];
};

export type DataQueryClassroomArgs = {
  id: Scalars["ID"];
};

export type DataQueryGroupsManagerCampusesArgs = {
  filter?: Maybe<CampusFilterInput>;
};

export type DataQueryGroupsManagerDepartmentsArgs = {
  filter?: Maybe<AcademicUnitFilterInput>;
};

export type DataQueryGroupsManagerSchoolsArgs = {
  filter?: Maybe<AcademicUnitFilterInput>;
};

export type DataQueryGroupsManagerCourseTypesArgs = {
  filter?: Maybe<AcademicUnitFilterInput>;
};

export type DataQueryGroupsManagerSubjectsArgs = {
  filter?: Maybe<SubjectFilterInput>;
};

export type DataQueryLinkLogEntryArgs = {
  filter?: Maybe<LinkLogEntryFilterInput>;
};

export type DateAvailabilityFilterInput = {
  start: Scalars["String"];
  end: Scalars["String"];
};

export type DateTimeRangeInput = {
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
};

export enum Day {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export type DayLegacy = {
  __typename?: "DayLegacy";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type DayTimeRange = {
  __typename?: "DayTimeRange";
  day?: Maybe<Day>;
  range?: Maybe<TimeRange>;
};

export type DeletePackageInput = {
  /** for multiedit */
  linkId?: Maybe<Scalars["ID"]>;
  /** for multiedit */
  packageId?: Maybe<Scalars["ID"]>;
};

export type DeleteSessionsByHolidaysInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  skipValidations?: Maybe<Scalars["Boolean"]>;
  minOccurrencesToDelete: Scalars["Int"];
};

export type Demand = {
  __typename?: "Demand";
  id: Scalars["ID"];
  vacancies?: Maybe<Scalars["Int"]>;
  crosslist?: Maybe<Scalars["String"]>;
  course: Course;
  origin: Origin;
};

export type DemandFilterInput = {
  pagination?: Maybe<PaginationInput>;
};

export type DemandPage = {
  __typename?: "DemandPage";
  items: Array<Demand>;
  pageInfo?: Maybe<PageInfo>;
};

export type Department = {
  __typename?: "Department";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  area?: Maybe<Area>;
};

export type DepartmentFilterFieldsInput = {
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<DepartmentFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<DepartmentFilterFieldsInput>>>;
};

export type DepartmentFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<DepartmentFilterFieldsInput>;
};

export type DepartmentPage = {
  __typename?: "DepartmentPage";
  items: Array<Department>;
  pageInfo?: Maybe<PageInfo>;
};

export type DisableSessionPayload = {
  __typename?: "DisableSessionPayload";
  /** This mirrors the input field `skipValidations` */
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  /**
   * # Whatever the operation was actually commited to the database.
   * # Cases:
   * # - true: when dryRun=false and no errors
   * # - true: when dryRun=false and skippedValidations=true
   * # - false: otherwise
   */
  commited?: Maybe<Scalars["Boolean"]>;
  session?: Maybe<Session>;
  /** List of validation errors found while executing the operation */
  validationErrors?: Maybe<Array<Maybe<EditorValidationError>>>;
  customValidations?: Maybe<Array<Maybe<CustomValidation>>>;
  clientMutationId?: Maybe<Scalars["String"]>;
  intervalValidationErrors?: Maybe<Array<Maybe<IntervalValidationErrors>>>;
  /** If user can skip some of the validations because has not assigned an ability */
  userCanSkipValidations?: Maybe<Scalars["Boolean"]>;
};

export type EditBundleInput = {
  /** used for session creation */
  bundleId?: Maybe<Scalars["ID"]>;
  /** a rehearsal of a performance or procedure before the real one. (for @nicolás) */
  dryRun: Scalars["Boolean"];
  /** kip any validations that could cancel the operation */
  skipValidations: Scalars["Boolean"];
  /** the changes we want to perform in this operation */
  changeset?: Maybe<BundleChangesetInput>;
};

export type EditBundlePayload = {
  __typename?: "EditBundlePayload";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  bundle?: Maybe<Bundle>;
  validationErrors?: Maybe<Array<Maybe<BundleValidationError>>>;
};

export type EditGroupInput = {
  groupId: Scalars["Int"];
  visibility?: Maybe<Scalars["Boolean"]>;
  capacity?: Maybe<Scalars["Int"]>;
};

export type EditGroupLabelsInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  groupId: Scalars["ID"];
  labelIds: Array<Maybe<Scalars["ID"]>>;
};

export type EditGroupLabelsPayload = {
  __typename?: "EditGroupLabelsPayload";
  commited?: Maybe<Scalars["Boolean"]>;
  group?: Maybe<Group>;
};

export type EditGroupTextsInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  groupId: Scalars["ID"];
  texts: Array<Maybe<Scalars["String"]>>;
};

export type EditGroupTextsPayload = {
  __typename?: "EditGroupTextsPayload";
  commited?: Maybe<Scalars["Boolean"]>;
  group?: Maybe<Group>;
};

export type EditPackageInput = {
  packageId: Scalars["Int"];
  visibility?: Maybe<Scalars["Boolean"]>;
  published?: Maybe<Scalars["Boolean"]>;
  replicated?: Maybe<Scalars["Boolean"]>;
};

export type EditPackageLabelsInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  packageId: Scalars["ID"];
  labelIds: Array<Maybe<Scalars["ID"]>>;
};

export type EditPackageLabelsPayload = {
  __typename?: "EditPackageLabelsPayload";
  commited?: Maybe<Scalars["Boolean"]>;
  package?: Maybe<Package>;
};

export type EditSectionInput = {
  sectionId?: Maybe<Scalars["ID"]>;
  /** a rehearsal of a performance or procedure before the real one. (for @nicolás) */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** the changes we want to perform in this operation */
  changeset?: Maybe<SectionChangesetInput>;
};

export type EditSectionPayload = {
  __typename?: "EditSectionPayload";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  /**
   * # Whatever the operation was actually commited to the database.
   * # Cases:
   * # - true: when dryRun=false and no errors
   * # - true: when dryRun=false and skippedValidations=true
   * # - false: otherwise
   */
  commited?: Maybe<Scalars["Boolean"]>;
  section?: Maybe<Section>;
  validationErrors?: Maybe<Array<Maybe<SectionValidationErrorUnion>>>;
};

export type EditSessionInput = {
  /** used for session creation */
  sectionId?: Maybe<Scalars["ID"]>;
  /** the session ID, used for session updates */
  sessionId?: Maybe<Scalars["ID"]>;
  /** a rehearsal of a performance or procedure before the real one. (for @nicolás) */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** the changes we want to perform in this operation */
  changeset?: Maybe<EditorChangesetInput>;
  /** the changes we want to perform in this operation */
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type EditSessionPayload = {
  __typename?: "EditSessionPayload";
  /** This mirrors the input field `skipValidations` */
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  /**
   * # Whatever the operation was actually commited to the database.
   * # Cases:
   * # - true: when dryRun=false and no errors
   * # - true: when dryRun=false and skippedValidations=true
   * # - false: otherwise
   */
  commited?: Maybe<Scalars["Boolean"]>;
  session?: Maybe<Session>;
  /** List of validation errors found while executing the operation */
  validationErrors?: Maybe<Array<Maybe<EditorValidationError>>>;
  customValidations?: Maybe<Array<Maybe<CustomValidation>>>;
  clientMutationId?: Maybe<Scalars["String"]>;
  intervalValidationErrors?: Maybe<Array<Maybe<IntervalValidationErrors>>>;
  /** If user can skip some of the validations because has not assigned an ability */
  userCanSkipValidations?: Maybe<Scalars["Boolean"]>;
};

export type EditorChangesetInput = {
  /** for multiedit */
  sessionId?: Maybe<Scalars["ID"]>;
  changeBlocks?: Maybe<ChangeBlocksInputType>;
  /** for creation */
  sectionId?: Maybe<Scalars["ID"]>;
  changeClassroom?: Maybe<ChangeClassroomInputType>;
  changeInstructor?: Maybe<ChangeInstructorInputType>;
  changeVacancies?: Maybe<ChangeVacanciesInputType>;
  changeIntervals?: Maybe<ChangeIntervalsInputType>;
  /** Indicates that doesnt have block assigment but needs to create an specified number of events, if is set changeBlocks is ignored */
  noScheduleEventsCount?: Maybe<Scalars["Int"]>;
};

export type EditorClash = {
  block?: Maybe<Block>;
  interval?: Maybe<Interval>;
  sessions?: Maybe<Array<Maybe<Session>>>;
};

export type EditorInfoUnion =
  | SectionInfo
  | InstructorInfo
  | ClassroomInfo
  | LinkInfo
  | PopulationInfo
  | CourseInfo
  | PackageInfo
  | GroupInfo;

export enum EditorOperation {
  Change = "CHANGE",
  Remove = "REMOVE",
  Keep = "KEEP",
}

export type EditorResouceView = {
  __typename?: "EditorResouceView";
  sections?: Maybe<Array<Maybe<Section>>>;
};

export type EditorRestriction = {
  __typename?: "EditorRestriction";
  type?: Maybe<RestrictionType>;
  scheduleEvent?: Maybe<Array<Maybe<ScheduleRestrictionEvent>>>;
};

export type EditorValidationError =
  | CanEditPackages
  | ClassroomClash
  | BookingClassroomClash
  | InstructorClash
  | LinkClash
  | SectionClash
  | PackageClash
  | InvalidInstructorTravel
  | InvalidPackageTravel
  | InvalidBuildingForCourse
  | InvalidBlock
  | InvalidInterval
  | InvalidBlockForInstructor
  | InvalidClassroomsCapacityAssignment
  | StudentClash
  | InstructorExceededWeeklyBlocks
  | InstructorAssignmentNotAllowed
  | BlocksAssignmentNotAllowed
  | IntervalsAssignmentNotAllowed
  | ClassroomsAssignmentNotAllowed
  | ExternalGroupValidation
  | InvalidLinksCourse
  | InvalidLinksPackage
  | GroupsManagerValidation
  | ClashesBetweenPackages
  | PackageNotPublished
  | InvalidPastSessionEdition
  | InvalidSessionInHoliday
  | GenericInvalidValidation
  | InvalidateSectionDeletion
  | CanDeletePackages
  | InstructorAssignmentRequired
  | InvalidSessionInHolidayDeletion
  | InvalidLabelLink
  | InvalidGroupCodeValidation
  | InvalidFieldValidation
  | InvalidSectionChanges
  | InvalidLinkChanges
  | InvalidComponentSize;

export type EditorView = {
  __typename?: "EditorView";
  info?: Maybe<EditorInfoUnion>;
  sections?: Maybe<Array<Maybe<Section>>>;
  bookings?: Maybe<Array<Maybe<ClassroomBooking>>>;
};

export type Event = {
  __typename?: "Event";
  id: Scalars["ID"];
  origin: Origin;
  interval: Interval;
  block?: Maybe<Block>;
  vacancies?: Maybe<Scalars["Int"]>;
  session: Session;
  courseComponent?: Maybe<CourseComponent>;
};

export type EventPage = {
  __typename?: "EventPage";
  items: Array<Event>;
  pageInfo?: Maybe<PageInfo>;
};

export type ExternalGroupValidation = {
  __typename?: "ExternalGroupValidation";
  allowed?: Maybe<Scalars["Boolean"]>;
  rejected_groups?: Maybe<Scalars["String"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type FilterMutation = {
  __typename?: "FilterMutation";
  createClassroomFilter?: Maybe<ClassroomFilter>;
  createSectionFilter?: Maybe<SectionFilter>;
  createCourseFilter?: Maybe<CourseFilter>;
  createInstructorFilter?: Maybe<InstructorFilter>;
  createPopulationFilter?: Maybe<PopulationFilter>;
  createPackageFilter?: Maybe<PackageFilter>;
  createLinkFilter?: Maybe<LinkFilter>;
  createGroupFilter?: Maybe<GroupFilter>;
};

export type FilterMutationCreateClassroomFilterArgs = {
  filter?: Maybe<ClassroomFilterInput>;
};

export type FilterMutationCreateSectionFilterArgs = {
  filter?: Maybe<SectionFilterInput>;
};

export type FilterMutationCreateCourseFilterArgs = {
  filter?: Maybe<CourseFilterInput>;
};

export type FilterMutationCreateInstructorFilterArgs = {
  filter?: Maybe<InstructorFilterInput>;
};

export type FilterMutationCreatePopulationFilterArgs = {
  filter?: Maybe<PopulationFilterInput>;
};

export type FilterMutationCreatePackageFilterArgs = {
  filter?: Maybe<PackageFilterInput>;
};

export type FilterMutationCreateLinkFilterArgs = {
  filter?: Maybe<LinkFilterInput>;
};

export type FilterMutationCreateGroupFilterArgs = {
  filter?: Maybe<GroupFilterInput>;
};

export type FilterQuery = {
  __typename?: "FilterQuery";
  classrooms?: Maybe<ClassroomPage>;
  sections?: Maybe<SectionPage>;
  courses?: Maybe<CoursePage>;
  instructors?: Maybe<InstructorPage>;
  populations?: Maybe<PopulationPage>;
  packages?: Maybe<PackagePage>;
  links?: Maybe<LinkPage>;
  groups?: Maybe<GroupPage>;
};

export type FilterQueryClassroomsArgs = {
  filterId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationInput>;
};

export type FilterQuerySectionsArgs = {
  filterId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationInput>;
};

export type FilterQueryCoursesArgs = {
  filterId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationInput>;
};

export type FilterQueryInstructorsArgs = {
  filterId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationInput>;
};

export type FilterQueryPopulationsArgs = {
  filterId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationInput>;
};

export type FilterQueryPackagesArgs = {
  filterId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationInput>;
};

export type FilterQueryLinksArgs = {
  filterId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationInput>;
};

export type FilterQueryGroupsArgs = {
  filterId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationInput>;
};

export type GenericInvalidValidation = {
  __typename?: "GenericInvalidValidation";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type Group = {
  __typename?: "Group";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  capacity?: Maybe<Scalars["Int"]>;
  links?: Maybe<Array<Maybe<Link>>>;
  enrollmentStats?: Maybe<GroupEnrollmentStats>;
  curriculum?: Maybe<Curriculum>;
  course?: Maybe<Course>;
  type?: Maybe<Scalars["String"]>;
  customFields?: Maybe<Array<Maybe<CustomField>>>;
  labels?: Maybe<Array<Maybe<Label>>>;
  visibleForEnrollment?: Maybe<Scalars["Boolean"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  clientCode?: Maybe<Scalars["String"]>;
  totalBlocks?: Maybe<Scalars["Int"]>;
  totalBlocksWithInstructors?: Maybe<Scalars["Int"]>;
  text?: Maybe<Array<Maybe<Scalars["String"]>>>;
  campus?: Maybe<Campus>;
  term?: Maybe<Term>;
  primaryInstructor?: Maybe<Instructor>;
  utilization?: Maybe<Scalars["Float"]>;
  subject?: Maybe<Subject>;
  blocks?: Maybe<Array<Maybe<Block>>>;
  scheduleSummary?: Maybe<Array<Maybe<GroupScheduleSummary>>>;
  groupCapacity?: Maybe<GroupCapacity>;
  enrollmentsCount?: Maybe<Scalars["Int"]>;
  groupCapacityChangeDetails?: Maybe<Array<Maybe<GroupCapacityChangeDetails>>>;
  isEditable?: Maybe<GroupIsEditable>;
  externalStats?: Maybe<GroupExternalStats>;
  isReferent?: Maybe<Scalars["Boolean"]>;
  referentType?: Maybe<Scalars["String"]>;
  groupSections?: Maybe<Array<Maybe<GroupSection>>>;
};

export type GroupCapacity = {
  __typename?: "GroupCapacity";
  id: Scalars["ID"];
  group?: Maybe<Group>;
  calculatedCapacity?: Maybe<Scalars["Int"]>;
  calculatedCapacityReason?: Maybe<Scalars["String"]>;
  calculatedConfigMinCapacity?: Maybe<Scalars["Int"]>;
  calculatedConfigMaxCapacity?: Maybe<Scalars["Int"]>;
  configMinCapacity?: Maybe<Scalars["Int"]>;
  configMaxCapacity?: Maybe<Scalars["Int"]>;
  effectiveMinCapacity?: Maybe<Scalars["Int"]>;
  effectiveMaxCapacity?: Maybe<Scalars["Int"]>;
  defaultCapacityPercentage?: Maybe<Scalars["Float"]>;
};

export type GroupCapacityChangeDetails = {
  __typename?: "GroupCapacityChangeDetails";
  id?: Maybe<Scalars["ID"]>;
  originId?: Maybe<Scalars["ID"]>;
  group?: Maybe<Group>;
  groupCapacityChangeRequest?: Maybe<GroupCapacityChangeRequest>;
  currentCapacity?: Maybe<Scalars["Int"]>;
  expectedCapacity?: Maybe<Scalars["Int"]>;
};

export type GroupCapacityChangeRequest = {
  __typename?: "GroupCapacityChangeRequest";
  id?: Maybe<Scalars["ID"]>;
  requestCode?: Maybe<Scalars["String"]>;
  changeCode?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  status?: Maybe<Scalars["String"]>;
  meta?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["DateTime"]>;
  modified?: Maybe<Scalars["DateTime"]>;
};

export type GroupChange = {
  __typename?: "GroupChange";
  id: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
  group?: Maybe<Group>;
  propertyUpdated: Scalars["String"];
  current: Scalars["String"];
  expected: Scalars["String"];
  status: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type GroupChangePage = {
  __typename?: "GroupChangePage";
  items: Array<GroupChange>;
  pageInfo?: Maybe<PageInfo>;
};

export type GroupEnrollment = {
  __typename?: "GroupEnrollment";
  group?: Maybe<Group>;
  student?: Maybe<Student>;
  term?: Maybe<Term>;
  campus?: Maybe<Campus>;
  program?: Maybe<Program>;
  enrollmentDate?: Maybe<Scalars["String"]>;
  isWaitingList?: Maybe<Scalars["Boolean"]>;
};

export type GroupEnrollmentPage = {
  __typename?: "GroupEnrollmentPage";
  items: Array<GroupEnrollment>;
  pageInfo?: Maybe<PageInfo>;
};

export type GroupEnrollmentStats = {
  __typename?: "GroupEnrollmentStats";
  id: Scalars["ID"];
  group?: Maybe<Group>;
  capacity?: Maybe<Scalars["Int"]>;
  reservedCapacity?: Maybe<Scalars["Int"]>;
  optCapacity?: Maybe<Scalars["Int"]>;
  maxCapacity?: Maybe<Scalars["Int"]>;
  minCapacity?: Maybe<Scalars["Int"]>;
  usedCapacity?: Maybe<Scalars["Int"]>;
  enrollmentsCount?: Maybe<Scalars["Int"]>;
  waitingListsCount?: Maybe<Scalars["Int"]>;
  bookingsCount?: Maybe<Scalars["Int"]>;
  instentsCount?: Maybe<Scalars["Int"]>;
  viewsCount?: Maybe<Scalars["Int"]>;
};

export type GroupExternalStats = {
  __typename?: "GroupExternalStats";
  capacity?: Maybe<Scalars["Int"]>;
  bookingCount?: Maybe<Scalars["Int"]>;
  enrollmentCount?: Maybe<Scalars["Int"]>;
  usedCapacity?: Maybe<Scalars["Int"]>;
  waitingListCount?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type GroupFilter = {
  __typename?: "GroupFilter";
  id: Scalars["ID"];
  groups?: Maybe<GroupPage>;
};

export type GroupFilterGroupsArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type GroupFilterFieldsInput = {
  course?: Maybe<CourseFilterFieldsInput>;
  curriculum?: Maybe<CurriculumFilterFieldsInput>;
  label?: Maybe<LabelFilterFieldsInput>;
  courseId?: Maybe<IntFilterInput>;
  campusId?: Maybe<IntFilterInput>;
  departmentId?: Maybe<IntFilterInput>;
  modalityId?: Maybe<IntFilterInput>;
  shiftId?: Maybe<IntFilterInput>;
  programId?: Maybe<IntFilterInput>;
  curriculumId?: Maybe<IntFilterInput>;
  clientCode?: Maybe<StringFilterInput>;
  level?: Maybe<IntFilterInput>;
  or?: Maybe<Array<GroupFilterFieldsInput>>;
  and?: Maybe<Array<GroupFilterFieldsInput>>;
};

export type GroupFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<GroupFilterFieldsInput>;
};

export type GroupInfo = {
  __typename?: "GroupInfo";
  group?: Maybe<Group>;
};

export type GroupIsEditable = {
  __typename?: "GroupIsEditable";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type GroupMigrationInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** Link destination to migrate the group */
  linkId: Scalars["ID"];
  /** Group to migrate to another link */
  groupId: Scalars["ID"];
};

export type GroupPage = {
  __typename?: "GroupPage";
  items: Array<Group>;
  pageInfo?: Maybe<PageInfo>;
};

export type GroupPayload = {
  __typename?: "GroupPayload";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  group?: Maybe<Group>;
  link?: Maybe<Link>;
  validationErrors?: Maybe<Array<Maybe<GroupValidationErrorUnion>>>;
};

export type GroupScheduleSummary = {
  __typename?: "GroupScheduleSummary";
  dateRange?: Maybe<Scalars["String"]>;
  days?: Maybe<Array<Maybe<DayLegacy>>>;
  schedule?: Maybe<Scalars["String"]>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  instructors?: Maybe<Array<Maybe<Instructor>>>;
};

export type GroupSection = {
  __typename?: "GroupSection";
  id: Scalars["ID"];
  group?: Maybe<Group>;
  section?: Maybe<Section>;
  code?: Maybe<Scalars["String"]>;
  meta?: Maybe<Scalars["String"]>;
};

export type GroupValidationErrorUnion =
  | InvalidGroupEnrollment
  | InvalidLinkChanges
  | InvalidFieldValidation
  | ExternalGroupValidation
  | InvalidGroupCodeValidation;

export type GroupsManagerCourseTypePage = {
  __typename?: "GroupsManagerCourseTypePage";
  items: Array<AcademicUnit>;
  pageInfo?: Maybe<PageInfo>;
};

export type GroupsManagerCrudChangesetInput = {
  updates?: Maybe<Array<Maybe<EditGroupInput>>>;
  creates?: Maybe<Array<Maybe<CreateGroupInput>>>;
  deletes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type GroupsManagerCrudInput = {
  /** a rehearsal of a performance or procedure before the real one */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** the changes we want to perform in this operation */
  changesets?: Maybe<GroupsManagerCrudChangesetInput>;
};

export type GroupsManagerCrudPayload = {
  __typename?: "GroupsManagerCrudPayload";
  creates?: Maybe<Array<Maybe<GroupsManagerPayload>>>;
  updates?: Maybe<Array<Maybe<GroupsManagerPayload>>>;
};

export type GroupsManagerCrudResult = {
  __typename?: "GroupsManagerCrudResult";
  payload?: Maybe<GroupsManagerCrudPayload>;
  /** If user can skip some of the validations because has not assigned an ability */
  userCanSkipValidations?: Maybe<Scalars["Boolean"]>;
};

export type GroupsManagerDepartmentPage = {
  __typename?: "GroupsManagerDepartmentPage";
  items: Array<AcademicUnit>;
  pageInfo?: Maybe<PageInfo>;
};

export type GroupsManagerEnrollmentsFilterInputType = {
  orderBy?: Maybe<GroupsManagerEnrollmentsOrderByInput>;
  searchBy?: Maybe<GroupsManagerEnrollmentsTextToSearchByInputType>;
  pagination?: Maybe<PaginationInput>;
};

export enum GroupsManagerEnrollmentsOrderByFieldsEnum {
  Student = "student",
  Program = "program",
  Campus = "campus",
  Date = "date",
  State = "state",
  Group = "group",
}

export type GroupsManagerEnrollmentsOrderByInput = {
  field: GroupsManagerEnrollmentsOrderByFieldsEnum;
  direction: OrderByDirection;
};

export enum GroupsManagerEnrollmentsSearchableFieldsEnum {
  Student = "student",
  Program = "program",
  Campus = "campus",
  State = "state",
  Date = "date",
  Group = "group",
}

export type GroupsManagerEnrollmentsTextToSearchByInputType = {
  fields?: Maybe<Array<Maybe<GroupsManagerEnrollmentsSearchableFieldsEnum>>>;
  text?: Maybe<Scalars["String"]>;
};

export type GroupsManagerGroupChangesFilterFieldsInput = {
  groupId?: Maybe<IntFilterInput>;
  campus?: Maybe<IntFilterInput>;
  subject?: Maybe<IntFilterInput>;
  clientCode?: Maybe<StringFilterInput>;
  isActive?: Maybe<BooleanFilterInput>;
  visibleForEnrollment?: Maybe<BooleanFilterInput>;
  hasVacancies?: Maybe<BooleanFilterInput>;
  hasEnrollments?: Maybe<BooleanFilterInput>;
  isOwner?: Maybe<BooleanFilterInput>;
  dateRange?: Maybe<DateTimeRangeInput>;
  department?: Maybe<IntFilterInput>;
  school?: Maybe<IntFilterInput>;
  courseTypes?: Maybe<Array<Maybe<IntFilterInput>>>;
  isGroupedByClientCode?: Maybe<BooleanFilterInput>;
};

export type GroupsManagerGroupChangesFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<GroupsManagerGroupChangesFilterFieldsInput>;
  orderBy?: Maybe<GroupsManagerGroupChangesOrderByInput>;
  searchBy?: Maybe<GroupsManagerGroupChangesTextToSearchByInputType>;
};

export enum GroupsManagerGroupChangesOrderByFieldsEnum {
  Date = "date",
  Status = "status",
  GroupCode = "groupCode",
  Campus = "campus",
  CourseName = "courseName",
  ChangeType = "changeType",
  Detail = "detail",
  Responsible = "responsible",
}

export type GroupsManagerGroupChangesOrderByInput = {
  field: GroupsManagerGroupChangesOrderByFieldsEnum;
  direction: OrderByDirection;
};

export enum GroupsManagerGroupChangesSearchableFieldsEnum {
  Date = "date",
  Status = "status",
  GroupCode = "groupCode",
  Campus = "campus",
  CourseName = "courseName",
  ChangeType = "changeType",
  Responsible = "responsible",
}

export type GroupsManagerGroupChangesTextToSearchByInputType = {
  fields?: Maybe<Array<Maybe<GroupsManagerGroupChangesSearchableFieldsEnum>>>;
  text?: Maybe<Scalars["String"]>;
};

export type GroupsManagerPayload = {
  __typename?: "GroupsManagerPayload";
  group?: Maybe<Group>;
  commited?: Maybe<Scalars["Boolean"]>;
  validationErrors?: Maybe<Array<Maybe<EditorValidationError>>>;
};

export type GroupsManagerSchoolPage = {
  __typename?: "GroupsManagerSchoolPage";
  items: Array<AcademicUnit>;
  pageInfo?: Maybe<PageInfo>;
};

export type GroupsManagerSearchFilterFieldsInput = {
  id?: Maybe<IntFilterInput>;
  clientCode?: Maybe<StringFilterInput>;
  campus?: Maybe<IntFilterInput>;
  subject?: Maybe<IntFilterInput>;
  isActive?: Maybe<BooleanFilterInput>;
  visibleForEnrollment?: Maybe<BooleanFilterInput>;
  hasVacancies?: Maybe<BooleanFilterInput>;
  hasEnrollments?: Maybe<BooleanFilterInput>;
  isOwner?: Maybe<BooleanFilterInput>;
  department?: Maybe<IntFilterInput>;
  school?: Maybe<IntFilterInput>;
  courseTypes?: Maybe<Array<Maybe<IntFilterInput>>>;
  isGroupedByClientCode?: Maybe<BooleanFilterInput>;
  package?: Maybe<IntFilterInput>;
  sumCapacities?: Maybe<BooleanFilterInput>;
};

export type GroupsManagerSearchFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<GroupsManagerSearchFilterFieldsInput>;
  orderBy?: Maybe<GroupsManagerSearchOrderByInputType>;
  searchBy?: Maybe<GroupsManagerSearchTextToSearchByInputType>;
};

export enum GroupsManagerSearchOrderByFields {
  GroupCode = "groupCode",
  GroupOrCampus = "groupOrCampus",
  CourseName = "courseName",
  Subterm = "subterm",
  MainInstructor = "mainInstructor",
  State = "state",
  Capacity = "capacity",
  CalculatedCapacity = "calculatedCapacity",
  MinMax = "minMax",
  Campus = "campus",
  GroupType = "groupType",
}

export type GroupsManagerSearchOrderByInputType = {
  field: GroupsManagerSearchOrderByFields;
  direction: OrderByDirection;
};

export enum GroupsManagerSearchSearchableFieldsEnum {
  GroupCode = "groupCode",
  GroupOrCampus = "groupOrCampus",
  CourseName = "courseName",
  Subterm = "subterm",
  MainInstructor = "mainInstructor",
  Campus = "campus",
  Capacity = "capacity",
  GroupType = "groupType",
}

export type GroupsManagerSearchTextToSearchByInputType = {
  fields?: Maybe<Array<Maybe<GroupsManagerSearchSearchableFieldsEnum>>>;
  text?: Maybe<Scalars["String"]>;
};

export type GroupsManagerValidation = {
  __typename?: "GroupsManagerValidation";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type Holiday = {
  __typename?: "Holiday";
  id: Scalars["ID"];
  code: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
};

export type IdFilterInput = {
  eq?: Maybe<Scalars["ID"]>;
  in?: Maybe<Array<Scalars["ID"]>>;
};

export type IdRangeInput = {
  start: Scalars["ID"];
  end: Scalars["ID"];
};

export type Institution = {
  __typename?: "Institution";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
  users?: Maybe<Array<Maybe<Role>>>;
  workspace?: Maybe<Array<Maybe<Workspace>>>;
};

export type Instructor = {
  __typename?: "Instructor";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  contract: Scalars["String"];
  minBlocks?: Maybe<Scalars["Int"]>;
  maxBlocks?: Maybe<Scalars["Int"]>;
  priority?: Maybe<Scalars["Int"]>;
  blocks?: Maybe<Array<Maybe<Block>>>;
  teachings?: Maybe<Array<Maybe<Teaching>>>;
  maxSections?: Maybe<Scalars["Int"]>;
  maxCourseComponents?: Maybe<Scalars["Int"]>;
  minCourses?: Maybe<Scalars["Int"]>;
  contractType?: Maybe<InstructorContractType>;
  assignment?: Maybe<InstructorAssignment>;
  /** @deprecated renamed. Use link field */
  linkResponsibility?: Maybe<InstructorLink>;
  customFields?: Maybe<Array<Maybe<CustomField>>>;
  link?: Maybe<InstructorLink>;
  usable?: Maybe<Scalars["Boolean"]>;
};

export type InstructorLinkResponsibilityArgs = {
  linkId: Scalars["ID"];
};

export type InstructorLinkArgs = {
  linkId: Scalars["ID"];
};

export type InstructorAssignment = {
  __typename?: "InstructorAssignment";
  blocks?: Maybe<Scalars["Int"]>;
  averagePerIntervalBlocks?: Maybe<Scalars["Float"]>;
  usagePercentage?: Maybe<Scalars["Float"]>;
  currentYearBlocks?: Maybe<Scalars["Int"]>;
  nextYearBlocks?: Maybe<Scalars["Int"]>;
  totalAssignedBlocks?: Maybe<Scalars["Int"]>;
  totalAssignedBlocksWithBTR?: Maybe<Scalars["Int"]>;
  totalRemainingBlocks?: Maybe<Scalars["Int"]>;
  totalRemainingBlocksCurrentYear?: Maybe<Scalars["Int"]>;
  totalRemainingBlocksNextYear?: Maybe<Scalars["Int"]>;
};

export type InstructorAssignmentNotAllowed = {
  __typename?: "InstructorAssignmentNotAllowed";
  allowed?: Maybe<Scalars["Boolean"]>;
};

export type InstructorAssignmentRequired = {
  __typename?: "InstructorAssignmentRequired";
  allowed?: Maybe<Scalars["Boolean"]>;
};

export type InstructorClash = EditorClash & {
  __typename?: "InstructorClash";
  block?: Maybe<Block>;
  interval?: Maybe<Interval>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  instructor?: Maybe<Instructor>;
};

export type InstructorContractType = {
  __typename?: "InstructorContractType";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type InstructorContractTypeFilterFieldsInput = {
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<ShiftFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<ShiftFilterFieldsInput>>>;
};

export type InstructorContractTypeFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<InstructorContractTypeFilterFieldsInput>;
};

export type InstructorContractTypePage = {
  __typename?: "InstructorContractTypePage";
  items: Array<InstructorContractType>;
  pageInfo?: Maybe<PageInfo>;
};

export type InstructorExceededWeeklyBlocks = {
  __typename?: "InstructorExceededWeeklyBlocks";
  instructor?: Maybe<Instructor>;
  assignableBlocks?: Maybe<Scalars["Int"]>;
  exceededBlocks?: Maybe<Scalars["Int"]>;
};

export type InstructorFilter = {
  __typename?: "InstructorFilter";
  id: Scalars["ID"];
  instructors?: Maybe<InstructorPage>;
};

export type InstructorFilterInstructorsArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type InstructorFilterFieldsInput = {
  id?: Maybe<IntFilterInput>;
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  contractTypeId?: Maybe<IntFilterInput>;
  courseId?: Maybe<IntFilterInput>;
  courseLevel?: Maybe<IntFilterInput>;
  assignedBlocks?: Maybe<IntFilterInput>;
  dummy?: Maybe<BooleanFilterInput>;
  program?: Maybe<ProgramFilterFieldsInput>;
  availability?: Maybe<AvailabilityFilterInput>;
  availabilities?: Maybe<Array<Maybe<AvailabilityFilterInput>>>;
  hasEvents?: Maybe<Scalars["Boolean"]>;
  filterAccessCode?: Maybe<Scalars["Boolean"]>;
  and?: Maybe<Array<Maybe<InstructorFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<InstructorFilterFieldsInput>>>;
};

export type InstructorFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<InstructorFilterFieldsInput>;
};

export type InstructorInfo = {
  __typename?: "InstructorInfo";
  instructor?: Maybe<Instructor>;
  assignedBlocks?: Maybe<Scalars["Int"]>;
  assignedSections?: Maybe<Scalars["Int"]>;
  assignedCourses?: Maybe<Scalars["Int"]>;
};

export type InstructorLink = {
  __typename?: "InstructorLink";
  id: Scalars["ID"];
  link: Link;
  instructor: Instructor;
  totalBlocks: Scalars["Int"];
  blocksPercentage: Scalars["Int"];
  components?: Maybe<Array<Maybe<Scalars["String"]>>>;
  value?: Maybe<Scalars["Int"]>;
  teachingHoursPercentage?: Maybe<Scalars["Float"]>;
  isPrimary?: Maybe<Scalars["Boolean"]>;
  paymentPercentage?: Maybe<Scalars["Float"]>;
};

export type InstructorLinkValidationErrorUnion = InvalidInput;

export type InstructorPage = {
  __typename?: "InstructorPage";
  items: Array<Instructor>;
  pageInfo?: Maybe<PageInfo>;
};

export type InstructorResponsibilityChangesetInput = {
  linkId: Scalars["ID"];
  instructorId: Scalars["ID"];
  value?: Maybe<Scalars["Int"]>;
  teachingHoursPercentage?: Maybe<Scalars["Float"]>;
  isPrimary?: Maybe<Scalars["Boolean"]>;
  paymentPercentage?: Maybe<Scalars["Float"]>;
};

export type InstructorResponsibilityInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  skipValidations?: Maybe<Scalars["Boolean"]>;
  changeset: Array<Maybe<InstructorResponsibilityChangesetInput>>;
};

export type InstructorResponsibilityPayload = {
  __typename?: "InstructorResponsibilityPayload";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  validations?: Maybe<Array<Maybe<InstructorLinkValidationErrorUnion>>>;
};

export type InstructorSearchInput = {
  page?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
  sectionId: Scalars["ID"];
  dayId?: Maybe<Scalars["Int"]>;
  startingTime?: Maybe<Scalars["String"]>;
  endingTime?: Maybe<Scalars["String"]>;
  intervalIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  blockIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  respectInstructorAvailability?: Maybe<Scalars["Boolean"]>;
  respectInstructorHours?: Maybe<Scalars["Boolean"]>;
  respectRequiredInstructor?: Maybe<Scalars["Boolean"]>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type IntFilterInput = {
  nil?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["Int"]>;
  ne?: Maybe<Scalars["Int"]>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  nin?: Maybe<Array<Scalars["Int"]>>;
};

export type Interval = {
  __typename?: "Interval";
  id: Scalars["ID"];
  name: Scalars["String"];
  startingDate?: Maybe<Scalars["String"]>;
  endingDate?: Maybe<Scalars["String"]>;
  eventCount?: Maybe<Scalars["Int"]>;
  termParts?: Maybe<Array<Maybe<TermPart>>>;
  isInTerm?: Maybe<Scalars["Boolean"]>;
  usableInBookings?: Maybe<Scalars["Boolean"]>;
};

export type IntervalRange = {
  __typename?: "IntervalRange";
  start?: Maybe<Interval>;
  end?: Maybe<Interval>;
};

export type IntervalValidationErrors = {
  __typename?: "IntervalValidationErrors";
  interval?: Maybe<Interval>;
  validationErrors?: Maybe<Array<Maybe<EditorValidationError>>>;
};

export type IntervalsAssignmentNotAllowed = {
  __typename?: "IntervalsAssignmentNotAllowed";
  allowed?: Maybe<Scalars["Boolean"]>;
};

export type InvalidBlock = {
  __typename?: "InvalidBlock";
  isValidStartTime?: Maybe<Scalars["Boolean"]>;
  isValidEndTime?: Maybe<Scalars["Boolean"]>;
};

export type InvalidBlockForInstructor = {
  __typename?: "InvalidBlockForInstructor";
  instructor?: Maybe<Instructor>;
  blocks?: Maybe<Array<Maybe<Block>>>;
};

export type InvalidBuildingForCourse = {
  __typename?: "InvalidBuildingForCourse";
  building?: Maybe<Building>;
  allowedBuildings?: Maybe<Array<Maybe<Building>>>;
};

export type InvalidClassroomCapacity = {
  __typename?: "InvalidClassroomCapacity";
  classroom: Classroom;
  reason?: Maybe<ValidationErrorReason>;
  capacity?: Maybe<Scalars["Int"]>;
};

export type InvalidClassroomsCapacityAssignment = {
  __typename?: "InvalidClassroomsCapacityAssignment";
  allowed?: Maybe<Scalars["Boolean"]>;
};

export type InvalidComponentSize = {
  __typename?: "InvalidComponentSize";
  component: CourseComponent;
  reason?: Maybe<ValidationErrorReason>;
  capacity?: Maybe<Scalars["Int"]>;
};

export type InvalidDate = {
  __typename?: "InvalidDate";
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
};

export type InvalidFieldValidation = {
  __typename?: "InvalidFieldValidation";
  value: Scalars["String"];
  reason?: Maybe<ValidationErrorReason>;
  meta?: Maybe<Scalars["String"]>;
};

export type InvalidGroupChanges = {
  __typename?: "InvalidGroupChanges";
  group: Group;
  validationErrors?: Maybe<Array<Maybe<GroupValidationErrorUnion>>>;
};

export type InvalidGroupCodeValidation = {
  __typename?: "InvalidGroupCodeValidation";
  allowed?: Maybe<Scalars["Boolean"]>;
  rejected_groups?: Maybe<Array<Maybe<Group>>>;
  reason?: Maybe<ValidationErrorReason>;
};

export type InvalidGroupEnrollment = {
  __typename?: "InvalidGroupEnrollment";
  reason?: Maybe<ValidationErrorReason>;
  value?: Maybe<Scalars["String"]>;
};

export type InvalidInput = {
  __typename?: "InvalidInput";
  code?: Maybe<ValidationErrorReason>;
  meta?: Maybe<Scalars["String"]>;
};

export type InvalidInstructorTravel = InvalidTravel & {
  __typename?: "InvalidInstructorTravel";
  instructor?: Maybe<Instructor>;
  withPrevious?: Maybe<TravelClash>;
  withFollowing?: Maybe<TravelClash>;
};

export type InvalidInterval = {
  __typename?: "InvalidInterval";
  intervals?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type InvalidLabelLink = {
  __typename?: "InvalidLabelLink";
  allowed?: Maybe<Scalars["Boolean"]>;
  link?: Maybe<Link>;
  reason?: Maybe<ValidationErrorReason>;
};

export type InvalidLinkChanges = {
  __typename?: "InvalidLinkChanges";
  link: Link;
  sectionChanges?: Maybe<Array<Maybe<InvalidSectionChanges>>>;
  groupChanges?: Maybe<Array<Maybe<InvalidGroupChanges>>>;
  sessionChanges?: Maybe<Array<Maybe<InvalidSessionChanges>>>;
};

export type InvalidLinksCourse = {
  __typename?: "InvalidLinksCourse";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type InvalidLinksPackage = {
  __typename?: "InvalidLinksPackage";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type InvalidPackageTravel = InvalidTravel & {
  __typename?: "InvalidPackageTravel";
  package?: Maybe<Package>;
  withPrevious?: Maybe<TravelClash>;
  withFollowing?: Maybe<TravelClash>;
};

export type InvalidPastSessionEdition = {
  __typename?: "InvalidPastSessionEdition";
  allowed?: Maybe<Scalars["Boolean"]>;
  intervals?: Maybe<Array<Maybe<Interval>>>;
};

export type InvalidSectionChanges = {
  __typename?: "InvalidSectionChanges";
  section: Section;
  validationErrors?: Maybe<Array<Maybe<SectionValidationErrorUnion>>>;
};

export type InvalidSessionChanges = {
  __typename?: "InvalidSessionChanges";
  session: Session;
  validationErrors?: Maybe<Array<Maybe<SessionValidationErrorUnion>>>;
};

export type InvalidSessionInHoliday = {
  __typename?: "InvalidSessionInHoliday";
  allowed?: Maybe<Scalars["Boolean"]>;
  holiday?: Maybe<Array<Maybe<Holiday>>>;
};

export type InvalidSessionInHolidayDeletion = {
  __typename?: "InvalidSessionInHolidayDeletion";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<Scalars["String"]>;
};

export type InvalidTravel = {
  withPrevious?: Maybe<TravelClash>;
  withFollowing?: Maybe<TravelClash>;
};

export type InvalidateSectionDeletion = {
  __typename?: "InvalidateSectionDeletion";
  code?: Maybe<ValidationErrorReason>;
};

export type Label = {
  __typename?: "Label";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
  category: LabelCategory;
};

export type LabelCategory = {
  __typename?: "LabelCategory";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
  labelSource: LabelSource;
};

export type LabelCategoryFilterFieldsInput = {
  id?: Maybe<IdFilterInput>;
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  contentType?: Maybe<StringFilterInput>;
  labelSourceId?: Maybe<IdFilterInput>;
  or?: Maybe<Array<LabelCategoryFilterFieldsInput>>;
  and?: Maybe<Array<LabelCategoryFilterFieldsInput>>;
};

export type LabelCategoryFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<LabelCategoryFilterFieldsInput>;
};

export type LabelCategoryPage = {
  __typename?: "LabelCategoryPage";
  items: Array<LabelCategory>;
  pageInfo?: Maybe<PageInfo>;
};

export type LabelFilterFieldsInput = {
  id?: Maybe<IdFilterInput>;
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  contentType?: Maybe<StringFilterInput>;
  labelCategoryId?: Maybe<IdFilterInput>;
  labelSourceCode?: Maybe<StringFilterInput>;
  or?: Maybe<Array<LabelFilterFieldsInput>>;
  and?: Maybe<Array<LabelFilterFieldsInput>>;
};

export type LabelFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<LabelFilterFieldsInput>;
};

export type LabelPage = {
  __typename?: "LabelPage";
  items: Array<Label>;
  pageInfo?: Maybe<PageInfo>;
};

export type LabelSource = {
  __typename?: "LabelSource";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
  sourceType: LabelSourceType;
};

export enum LabelSourceType {
  User = "USER",
  System = "SYSTEM",
}

export enum Language {
  Es = "ES",
  En = "EN",
}

export type Level = {
  __typename?: "Level";
  id: Scalars["ID"];
  curriculum: Curriculum;
  level: Scalars["Int"];
  maxCreditsPerTerm?: Maybe<Scalars["Int"]>;
  maxCreditsPerYear?: Maybe<Scalars["Int"]>;
  maxSubjectsPerTerm?: Maybe<Scalars["Int"]>;
  maxSubjectsPerYear?: Maybe<Scalars["Int"]>;
};

export type Link = {
  __typename?: "Link";
  id: Scalars["ID"];
  origin: Origin;
  vacancies?: Maybe<Scalars["Int"]>;
  sections: Array<Section>;
  packages: Array<Package>;
  roleInPackages: Array<PackageSectionRole>;
  label?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  schedule?: Maybe<Schedule>;
  course?: Maybe<Course>;
  sectionsCount?: Maybe<Scalars["Int"]>;
  groups?: Maybe<Array<Maybe<Group>>>;
  bundle?: Maybe<Bundle>;
  /** @deprecated renamed. Use link field */
  instructorResponsability?: Maybe<Array<Maybe<InstructorLink>>>;
  totalBlocks?: Maybe<Scalars["Int"]>;
  blocksWithoutInstructorAssigment?: Maybe<Scalars["Int"]>;
  instructors?: Maybe<Array<Maybe<InstructorLink>>>;
  enabled?: Maybe<Scalars["Boolean"]>;
};

export type LinkClash = EditorClash & {
  __typename?: "LinkClash";
  block?: Maybe<Block>;
  interval?: Maybe<Interval>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  link?: Maybe<Link>;
};

export type LinkCrudChangesetInput = {
  creates?: Maybe<Array<Maybe<CreateLinkInput>>>;
  updates?: Maybe<Array<Maybe<UpdateLinkInput>>>;
  deletes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type LinkCrudInput = {
  /** a rehearsal of a performance or procedure before the real one */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** the changes we want to perform in this operation */
  changesets?: Maybe<LinkCrudChangesetInput>;
};

export type LinkCrudPayload = {
  __typename?: "LinkCrudPayload";
  creates?: Maybe<Array<Maybe<LinkPayload>>>;
  updates?: Maybe<Array<Maybe<LinkPayload>>>;
  deletes?: Maybe<Array<Maybe<LinkPayload>>>;
};

export type LinkCrudResult = {
  __typename?: "LinkCrudResult";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  payload?: Maybe<LinkCrudPayload>;
  /** If user can skip some of the validations because has not assigned an ability */
  userCanSkipValidations?: Maybe<Scalars["Boolean"]>;
};

export type LinkFilter = {
  __typename?: "LinkFilter";
  id: Scalars["ID"];
  links?: Maybe<LinkPage>;
};

export type LinkFilterLinksArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type LinkFilterFieldsInput = {
  courseId?: Maybe<IntFilterInput>;
  course?: Maybe<CourseFilterFieldsInput>;
  campusId?: Maybe<IntFilterInput>;
  getUnrestrictedLinks?: Maybe<BooleanFilterInput>;
  or?: Maybe<Array<LinkFilterFieldsInput>>;
  and?: Maybe<Array<LinkFilterFieldsInput>>;
};

export type LinkFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<LinkFilterFieldsInput>;
};

export type LinkInfo = {
  __typename?: "LinkInfo";
  link?: Maybe<Link>;
};

export type LinkLogEntry = {
  __typename?: "LinkLogEntry";
  id: Scalars["ID"];
  date?: Maybe<Scalars["String"]>;
  link?: Maybe<Link>;
  objectId?: Maybe<Scalars["ID"]>;
  changeType?: Maybe<Array<Maybe<Scalars["String"]>>>;
  change?: Maybe<Array<Maybe<Scalars["String"]>>>;
  user?: Maybe<User>;
  resource?: Maybe<Scalars["String"]>;
};

export type LinkLogEntryFilterFieldsInput = {
  date?: Maybe<StringFilterInput>;
  linkId?: Maybe<IntFilterInput>;
  sessionId?: Maybe<IntFilterInput>;
  changeType?: Maybe<StringFilterInput>;
  change?: Maybe<StringFilterInput>;
  userId?: Maybe<IntFilterInput>;
  isOwner?: Maybe<BooleanFilterInput>;
  dateRange?: Maybe<DateAvailabilityFilterInput>;
  containsChanges?: Maybe<BooleanFilterInput>;
  or?: Maybe<Array<LinkLogEntryFilterFieldsInput>>;
  and?: Maybe<Array<LinkLogEntryFilterFieldsInput>>;
};

export type LinkLogEntryFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<LinkLogEntryFilterFieldsInput>;
  orderBy?: Maybe<LinkLogEntryOrderByInput>;
  searchBy?: Maybe<LinkLogEntrySearchTextToSearchByInputType>;
};

export enum LinkLogEntryOrderByFieldsEnum {
  Id = "id",
  Date = "date",
  LinkId = "link_id",
  LinkCode = "link_code",
  SessionId = "session_id",
  UserId = "user_id",
  ResourceChanges = "resource_changes",
  ObjectId = "object_id",
}

export type LinkLogEntryOrderByInput = {
  field: LinkLogEntryOrderByFieldsEnum;
  direction: OrderByDirection;
};

export type LinkLogEntryPage = {
  __typename?: "LinkLogEntryPage";
  items: Array<LinkLogEntry>;
  pageInfo?: Maybe<PageInfo>;
};

export enum LinkLogEntrySearchSearchableFieldsEnum {
  Id = "id",
  Date = "date",
  LinkId = "link_id",
  LinkCode = "link_code",
  SessionId = "session_id",
  UserId = "user_id",
}

export type LinkLogEntrySearchTextToSearchByInputType = {
  fields?: Maybe<Array<Maybe<LinkLogEntrySearchSearchableFieldsEnum>>>;
  text?: Maybe<Scalars["String"]>;
};

export type LinkPage = {
  __typename?: "LinkPage";
  items: Array<Link>;
  pageInfo?: Maybe<PageInfo>;
};

export type LinkPayload = {
  __typename?: "LinkPayload";
  link?: Maybe<Link>;
  sections?: Maybe<Array<Maybe<Section>>>;
  validationErrors?: Maybe<Array<Maybe<EditorValidationError>>>;
};

export type LinkValidationErrorUnion = InvalidSectionChanges | InvalidInput;

export type Modality = {
  __typename?: "Modality";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ModalityFilterFieldsInput = {
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<ModalityFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<ModalityFilterFieldsInput>>>;
};

export type ModalityFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ModalityFilterFieldsInput>;
};

export type ModalityPage = {
  __typename?: "ModalityPage";
  items: Array<Modality>;
  pageInfo?: Maybe<PageInfo>;
};

export type ModelExtension = {
  __typename?: "ModelExtension";
  id: Scalars["ID"];
  contentType?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Tag>;
};

export type ModelExtensionFilterFieldsInput = {
  contentType?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<ModelExtensionFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<ModelExtensionFilterFieldsInput>>>;
};

export type ModelExtensionFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ModelExtensionFilterFieldsInput>;
};

export type ModelExtensionPage = {
  __typename?: "ModelExtensionPage";
  items: Array<ModelExtension>;
  pageInfo?: Maybe<PageInfo>;
};

export type Module = {
  __typename?: "Module";
  code: Scalars["String"];
  url?: Maybe<Scalars["String"]>;
  accesible?: Maybe<Scalars["Boolean"]>;
};

export type ModuleAccesibleArgs = {
  userId?: Maybe<Scalars["ID"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  base?: Maybe<BaseMutation>;
  cube?: Maybe<CubeMutation>;
  filter?: Maybe<FilterMutation>;
  login?: Maybe<User>;
  auth?: Maybe<User>;
};

export type MutationCubeArgs = {
  scenarioId: Scalars["ID"];
  originId: Scalars["ID"];
};

export type MutationFilterArgs = {
  scenarioId: Scalars["ID"];
  originId: Scalars["ID"];
};

export type MutationLoginArgs = {
  username: Scalars["String"];
  password: Scalars["String"];
};

export type MutationAuthArgs = {
  token: Scalars["String"];
};

export type OnOffFeatures = {
  __typename?: "OnOffFeatures";
  groupText?: Maybe<Scalars["Boolean"]>;
};

export enum OrderByDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type Origin = {
  __typename?: "Origin";
  id: Scalars["ID"];
  label?: Maybe<Scalars["String"]>;
  demandCount?: Maybe<Scalars["Int"]>;
  term?: Maybe<Term>;
};

export type OriginInput = {
  type?: Maybe<OriginType>;
  processTypeId?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  hasDemands?: Maybe<Scalars["Boolean"]>;
  isProductive?: Maybe<Scalars["Boolean"]>;
  isVisible?: Maybe<Scalars["Boolean"]>;
};

export enum OriginType {
  Record = "RECORD",
  Enrollment = "ENROLLMENT",
  Assignation = "ASSIGNATION",
  Sections = "SECTIONS",
  DataManager = "DATA_MANAGER",
  DemandReEnrollment = "DEMAND_RE_ENROLLMENT",
  DemandFreshmen = "DEMAND_FRESHMEN",
  DemandForecast = "DEMAND_FORECAST",
  DemandPlanning = "DEMAND_PLANNING",
}

export type Package = {
  __typename?: "Package";
  id: Scalars["ID"];
  origin: Origin;
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  index: Scalars["Int"];
  vacancies: Scalars["Int"];
  vacanciesSource?: Maybe<Scalars["String"]>;
  population: Population;
  links: Array<Link>;
  completingLinks: Array<Link>;
  complete?: Maybe<Scalars["Boolean"]>;
  hasPackageRules?: Maybe<Scalars["Boolean"]>;
  schedule?: Maybe<Schedule>;
  type?: Maybe<Scalars["String"]>;
  labels?: Maybe<Array<Maybe<Label>>>;
  isVisible?: Maybe<Scalars["Boolean"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  isReplicated?: Maybe<Scalars["Boolean"]>;
  sourcePopulationCode?: Maybe<Scalars["String"]>;
  packageTotalCredits?: Maybe<Scalars["Float"]>;
  sourcePackage?: Maybe<Package>;
};

export type PackageClash = EditorClash & {
  __typename?: "PackageClash";
  block?: Maybe<Block>;
  interval?: Maybe<Interval>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  package?: Maybe<Package>;
};

export type PackageCrudChangesetInput = {
  creates?: Maybe<Array<Maybe<CreatePackageInput>>>;
  updates?: Maybe<Array<Maybe<EditPackageInput>>>;
  deletes?: Maybe<Array<Maybe<DeletePackageInput>>>;
};

export type PackageCrudInput = {
  /** a rehearsal of a performance or procedure before the real one */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** the changes we want to perform in this operation */
  changesets?: Maybe<PackageCrudChangesetInput>;
};

export type PackageCrudPayload = {
  __typename?: "PackageCrudPayload";
  creates?: Maybe<Array<Maybe<PackagePayload>>>;
  updates?: Maybe<Array<Maybe<PackagePayload>>>;
  deletes?: Maybe<Array<Maybe<PackagePayload>>>;
};

export type PackageCrudResult = {
  __typename?: "PackageCrudResult";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  payload?: Maybe<PackageCrudPayload>;
  /** If user can skip some of the validations because has not assigned an ability */
  userCanSkipValidations?: Maybe<Scalars["Boolean"]>;
};

export type PackageFilter = {
  __typename?: "PackageFilter";
  id: Scalars["ID"];
  packages?: Maybe<PackagePage>;
};

export type PackageFilterPackagesArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type PackageFilterFieldsInput = {
  population?: Maybe<PopulationFilterFieldsInput>;
  label?: Maybe<LabelFilterFieldsInput>;
  hasEvents?: Maybe<Scalars["Boolean"]>;
  isPublished?: Maybe<BooleanFilterInput>;
  or?: Maybe<Array<PackageFilterFieldsInput>>;
  and?: Maybe<Array<PackageFilterFieldsInput>>;
};

export type PackageFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<PackageFilterFieldsInput>;
};

export type PackageInfo = {
  __typename?: "PackageInfo";
  package?: Maybe<Package>;
};

export type PackageLinkAssignmentInput = {
  /** a rehearsal of a performance or procedure before the real one */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** the changes we want to perform in this operation */
  changesets?: Maybe<PackageCrudChangesetInput>;
};

export type PackageLinkAssignmentResult = {
  __typename?: "PackageLinkAssignmentResult";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  payload?: Maybe<PackageCrudPayload>;
  /** If user can skip some of the validations because has not assigned an ability */
  userCanSkipValidations?: Maybe<Scalars["Boolean"]>;
};

export type PackageNotPublished = {
  __typename?: "PackageNotPublished";
  allowed?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<ValidationErrorReason>;
};

export type PackagePage = {
  __typename?: "PackagePage";
  items: Array<Package>;
  pageInfo?: Maybe<PageInfo>;
};

export type PackagePayload = {
  __typename?: "PackagePayload";
  package?: Maybe<Package>;
  link?: Maybe<Link>;
  replicatedPackages?: Maybe<Array<Maybe<Package>>>;
  validationErrors?: Maybe<Array<Maybe<EditorValidationError>>>;
};

export type PackageSectionRole = {
  __typename?: "PackageSectionRole";
  id: Scalars["ID"];
  link: Link;
  package: Package;
  isCompleting: Scalars["Boolean"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type PaginationInput = {
  page?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type Population = {
  __typename?: "Population";
  id?: Maybe<Scalars["ID"]>;
  origin?: Maybe<Origin>;
  code?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  level?: Maybe<Scalars["Int"]>;
  courses?: Maybe<Array<Maybe<Course>>>;
  program: Program;
  curriculum?: Maybe<Scalars["String"]>;
  campus: Campus;
  modality: Modality;
  shift: Shift;
  term: Term;
  packageCount?: Maybe<Scalars["Int"]>;
  packageIndex?: Maybe<Scalars["Int"]>;
  labels?: Maybe<Array<Maybe<Label>>>;
};

export type PopulationFilter = {
  __typename?: "PopulationFilter";
  id: Scalars["ID"];
  populations?: Maybe<PopulationPage>;
};

export type PopulationFilterPopulationsArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type PopulationFilterFieldsInput = {
  campusId?: Maybe<IdFilterInput>;
  departmentId?: Maybe<IdFilterInput>;
  shiftId?: Maybe<IdFilterInput>;
  modalityId?: Maybe<IdFilterInput>;
  programId?: Maybe<IdFilterInput>;
  program?: Maybe<ProgramFilterFieldsInput>;
  curriculumId?: Maybe<IdFilterInput>;
  curriculum?: Maybe<StringFilterInput>;
  level?: Maybe<IntFilterInput>;
  hasEvents?: Maybe<Scalars["Boolean"]>;
  or?: Maybe<Array<PopulationFilterFieldsInput>>;
  and?: Maybe<Array<PopulationFilterFieldsInput>>;
};

export type PopulationFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<PopulationFilterFieldsInput>;
};

export type PopulationInfo = {
  __typename?: "PopulationInfo";
  population?: Maybe<Population>;
};

export type PopulationPage = {
  __typename?: "PopulationPage";
  items: Array<Population>;
  pageInfo?: Maybe<PageInfo>;
};

export type Program = {
  __typename?: "Program";
  id: Scalars["ID"];
  campus?: Maybe<Campus>;
  shift?: Maybe<Shift>;
  modality?: Maybe<Modality>;
  department?: Maybe<Department>;
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  curriculums?: Maybe<Array<Maybe<Curriculum>>>;
};

export type ProgramFilterFieldsInput = {
  id?: Maybe<IdFilterInput>;
  curriculums?: Maybe<CurriculumFilterFieldsInput>;
  department?: Maybe<DepartmentFilterFieldsInput>;
  campusId?: Maybe<IdFilterInput>;
  departmentId?: Maybe<IdFilterInput>;
  modalityId?: Maybe<IdFilterInput>;
  shiftId?: Maybe<IdFilterInput>;
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<ProgramFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<ProgramFilterFieldsInput>>>;
};

export type ProgramFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ProgramFilterFieldsInput>;
  orderByCodeAndName?: Maybe<Scalars["Boolean"]>;
  groupByCode?: Maybe<Scalars["Boolean"]>;
};

export type ProgramPage = {
  __typename?: "ProgramPage";
  items: Array<Program>;
  pageInfo?: Maybe<PageInfo>;
};

export type Query = {
  __typename?: "Query";
  base?: Maybe<BaseQuery>;
  data?: Maybe<DataQuery>;
  cube?: Maybe<CubeQuery>;
  filter?: Maybe<FilterQuery>;
};

export type QueryDataArgs = {
  scenarioId: Scalars["ID"];
  originId?: Maybe<Scalars["ID"]>;
};

export type QueryCubeArgs = {
  scenarioId: Scalars["ID"];
  originId: Scalars["ID"];
};

export type QueryFilterArgs = {
  scenarioId: Scalars["ID"];
};

export type Recommendation = {
  __typename?: "Recommendation";
  process_id: Scalars["ID"];
  session?: Maybe<Session>;
  alternatives?: Maybe<Array<Maybe<RecommendationAlternatives>>>;
};

export type RecommendationAlternatives = {
  __typename?: "RecommendationAlternatives";
  start_time?: Maybe<Scalars["String"]>;
  instructors?: Maybe<Array<Maybe<Instructor>>>;
  assignedInstructors?: Maybe<Array<Maybe<Instructor>>>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  days?: Maybe<DayLegacy>;
  current_asignment_mark?: Maybe<Scalars["Boolean"]>;
};

export type ResourceAssigment = {
  __typename?: "ResourceAssigment";
  schedule?: Maybe<Scalars["Boolean"]>;
  classroom?: Maybe<Scalars["Boolean"]>;
  instructor?: Maybe<Scalars["Boolean"]>;
};

export enum ResourceType {
  Instructor = "INSTRUCTOR",
  Classroom = "CLASSROOM",
  Section = "SECTION",
  Link = "LINK",
  Population = "POPULATION",
  Course = "COURSE",
  Package = "PACKAGE",
  Group = "GROUP",
}

export enum RestrictionType {
  Strip = "STRIP",
  InstructorAvailability = "INSTRUCTOR_AVAILABILITY",
}

export type Role = {
  __typename?: "Role";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Scenario = {
  __typename?: "Scenario";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  workspace?: Maybe<Workspace>;
  status?: Maybe<ScenarioStatus>;
  category?: Maybe<ScenarioCategory>;
  origins?: Maybe<Array<Maybe<Origin>>>;
  productiveOrigin?: Maybe<Origin>;
};

export type ScenarioOriginsArgs = {
  isProductive?: Maybe<Scalars["Boolean"]>;
};

export type ScenarioCategory = {
  __typename?: "ScenarioCategory";
  id: Scalars["ID"];
  value: Scalars["String"];
};

export type ScenarioStatus = {
  __typename?: "ScenarioStatus";
  id: Scalars["ID"];
  value: Scalars["String"];
};

export enum ScenarioStatusEnum {
  Productive = "PRODUCTIVE",
  Locked = "LOCKED",
  Dislocked = "DISLOCKED",
}

export type ScenariosInput = {
  workspaceId?: Maybe<Scalars["ID"]>;
  status?: Maybe<ScenarioStatusEnum>;
};

export type Schedule = {
  __typename?: "Schedule";
  sessions?: Maybe<Array<Maybe<Session>>>;
  summaryByDay?: Maybe<Array<Maybe<DayTimeRange>>>;
  resourceAssigment?: Maybe<ResourceAssigment>;
};

export type ScheduleEvent = {
  __typename?: "ScheduleEvent";
  id: Scalars["ID"];
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  colorIndex?: Maybe<Scalars["Int"]>;
};

export type ScheduleRestrictionEvent = {
  __typename?: "ScheduleRestrictionEvent";
  id: Scalars["ID"];
  dayId?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
};

export type Section = {
  __typename?: "Section";
  id: Scalars["ID"];
  origin: Origin;
  vacancies?: Maybe<Scalars["Int"]>;
  course?: Maybe<Course>;
  component?: Maybe<Component>;
  buildings: Array<Building>;
  courseComponent?: Maybe<CourseComponent>;
  sessions: Array<Session>;
  links?: Maybe<Array<Maybe<Link>>>;
  code?: Maybe<Scalars["String"]>;
  schedule?: Maybe<Schedule>;
  unasignedSessions?: Maybe<Array<Maybe<Session>>>;
  groups?: Maybe<Array<Maybe<Group>>>;
  linksByGroup?: Maybe<Array<Maybe<Link>>>;
  linksByBundles?: Maybe<Array<Maybe<Link>>>;
  bundle?: Maybe<Bundle>;
  usedCapacity?: Maybe<Scalars["Int"]>;
  waitingListsCount?: Maybe<Scalars["Int"]>;
  totalBlocks?: Maybe<Scalars["Int"]>;
  labels?: Maybe<Array<Maybe<Label>>>;
};

export type SectionLinksByGroupArgs = {
  groupId: Scalars["ID"];
};

export type SectionLinksByBundlesArgs = {
  bundleId: Scalars["ID"];
};

export type SectionChangesetInput = {
  changeCourseComponent?: Maybe<ChangeCourseComponentInputType>;
};

export type SectionClash = EditorClash & {
  __typename?: "SectionClash";
  block?: Maybe<Block>;
  interval?: Maybe<Interval>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  section?: Maybe<Section>;
};

export type SectionFilter = {
  __typename?: "SectionFilter";
  id: Scalars["ID"];
  sections?: Maybe<SectionPage>;
};

export type SectionFilterSectionsArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type SectionFilterFieldsInput = {
  course?: Maybe<CourseFilterFieldsInput>;
  courseId?: Maybe<IntFilterInput>;
  courseComponentId?: Maybe<IntFilterInput>;
  availability?: Maybe<AvailabilityFilterInput>;
  assignment?: Maybe<AssignmentFilterInput>;
  clashes?: Maybe<ClashesFilterInput>;
  program?: Maybe<ProgramFilterFieldsInput>;
  curriculumId?: Maybe<IdFilterInput>;
  level?: Maybe<IntFilterInput>;
  filterAccessCode?: Maybe<Scalars["Boolean"]>;
  or?: Maybe<Array<SectionFilterFieldsInput>>;
  and?: Maybe<Array<SectionFilterFieldsInput>>;
};

export type SectionFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<SectionFilterFieldsInput>;
};

export type SectionInfo = {
  __typename?: "SectionInfo";
  campus?: Maybe<Campus>;
  department?: Maybe<Department>;
  modality?: Maybe<Modality>;
  shift?: Maybe<Shift>;
  program?: Maybe<Program>;
  curriculum?: Maybe<Curriculum>;
  course?: Maybe<Course>;
  courseComponent?: Maybe<CourseComponent>;
  section?: Maybe<Section>;
  packages?: Maybe<Array<Maybe<Package>>>;
};

export type SectionPage = {
  __typename?: "SectionPage";
  items: Array<Section>;
  pageInfo?: Maybe<PageInfo>;
};

export type SectionValidationErrorUnion =
  | InvalidComponentSize
  | InvalidInput
  | InvalidGroupEnrollment
  | ExternalGroupValidation
  | InvalidateSectionDeletion;

export type Session = {
  __typename?: "Session";
  id: Scalars["ID"];
  origin: Origin;
  vacancies?: Maybe<Scalars["Int"]>;
  events: Array<Event>;
  section?: Maybe<Section>;
  links?: Maybe<Array<Maybe<Link>>>;
  assignment?: Maybe<SessionAssignment>;
  schedule?: Maybe<Schedule>;
  scheduleEvent?: Maybe<Array<Maybe<ScheduleEvent>>>;
  hasValidationErrors?: Maybe<Scalars["Boolean"]>;
  /** List of validation errors  */
  validationErrors?: Maybe<Array<Maybe<EditorValidationError>>>;
  blocksCount?: Maybe<Scalars["Int"]>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  instructors?: Maybe<Array<Maybe<Instructor>>>;
  labels?: Maybe<Array<Maybe<Label>>>;
};

export type SessionAssignment = {
  __typename?: "SessionAssignment";
  day?: Maybe<Day>;
  days?: Maybe<Array<Maybe<Day>>>;
  blocks?: Maybe<Array<Maybe<Block>>>;
  intervals?: Maybe<Array<Maybe<Interval>>>;
  blockRange?: Maybe<BlockRange>;
  intervalRange?: Maybe<IntervalRange>;
  classroom?: Maybe<Classroom>;
  instructor?: Maybe<Instructor>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  instructors?: Maybe<Array<Maybe<Instructor>>>;
};

export type SessionCrudChangesetInput = {
  updates?: Maybe<Array<Maybe<EditorChangesetInput>>>;
  creates?: Maybe<Array<Maybe<EditorChangesetInput>>>;
  deletes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type SessionCrudInput = {
  /** a rehearsal of a performance or procedure before the real one */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** the changes we want to perform in this operation */
  changesets?: Maybe<SessionCrudChangesetInput>;
  linkId?: Maybe<Scalars["ID"]>;
};

export type SessionCrudPayload = {
  __typename?: "SessionCrudPayload";
  updates?: Maybe<Array<Maybe<SessionPayload>>>;
  creates?: Maybe<Array<Maybe<SessionPayload>>>;
  deletes?: Maybe<Array<Maybe<SessionPayload>>>;
};

export type SessionCrudResult = {
  __typename?: "SessionCrudResult";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  payload?: Maybe<SessionCrudPayload>;
  /** If user can skip some of the validations because has not assigned an ability */
  userCanSkipValidations?: Maybe<Scalars["Boolean"]>;
};

export type SessionPage = {
  __typename?: "SessionPage";
  items: Array<Session>;
  pageInfo?: Maybe<PageInfo>;
};

export type SessionPayload = {
  __typename?: "SessionPayload";
  sessionId?: Maybe<Scalars["ID"]>;
  sessionUuid?: Maybe<Scalars["String"]>;
  validationErrors?: Maybe<Array<Maybe<EditorValidationError>>>;
  intervalValidationErrors?: Maybe<Array<Maybe<IntervalValidationErrors>>>;
  customValidations?: Maybe<Array<Maybe<CustomValidation>>>;
};

export type SessionRecommendation = {
  __typename?: "SessionRecommendation";
  html?: Maybe<Scalars["String"]>;
  recommendation?: Maybe<Array<Maybe<Recommendation>>>;
};

export type SessionValidationErrorUnion = InvalidClassroomCapacity;

export type SessionsByHolidaysPayload = {
  __typename?: "SessionsByHolidaysPayload";
  commited?: Maybe<Scalars["Boolean"]>;
  link?: Maybe<Array<Maybe<Link>>>;
  validationErrors?: Maybe<Array<Maybe<EditorValidationError>>>;
};

export type SessionsMultiEditInput = {
  /** a rehearsal of a performance or procedure before the real one. (for @nicolás) */
  dryRun?: Maybe<Scalars["Boolean"]>;
  /** kip any validations that could cancel the operation */
  skipValidations?: Maybe<Scalars["Boolean"]>;
  /** the changes we want to perform in this operation */
  changesets?: Maybe<Array<Maybe<EditorChangesetInput>>>;
};

export type SessionsMultiEditPayload = {
  __typename?: "SessionsMultiEditPayload";
  skippedValidations?: Maybe<Scalars["Boolean"]>;
  commited?: Maybe<Scalars["Boolean"]>;
  sessionsPayload?: Maybe<Array<Maybe<SessionPayload>>>;
  /** If user can skip some of the validations because has not assigned an ability */
  userCanSkipValidations?: Maybe<Scalars["Boolean"]>;
};

export type Setting = {
  __typename?: "Setting";
  id: Scalars["ID"];
  config?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  valueType: Scalars["String"];
};

export type SettingFilterFieldsInput = {
  config?: Maybe<StringFilterInput>;
  value?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<SettingFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<SettingFilterFieldsInput>>>;
};

export type SettingFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<SettingFilterFieldsInput>;
};

export type SettingPage = {
  __typename?: "SettingPage";
  items: Array<Setting>;
  pageInfo?: Maybe<PageInfo>;
};

export type SharedPackComponent = {
  component?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

export type SharedPackComponents = {
  __typename?: "SharedPackComponents";
  component?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  prefix?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  existingLabels?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SharedPackComponentsInput = {
  bundleId: Scalars["ID"];
  linkId: Scalars["ID"];
  courseId: Scalars["ID"];
};

export type Shift = {
  __typename?: "Shift";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ShiftFilterFieldsInput = {
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<ShiftFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<ShiftFilterFieldsInput>>>;
};

export type ShiftFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<ShiftFilterFieldsInput>;
};

export type ShiftPage = {
  __typename?: "ShiftPage";
  items: Array<Shift>;
  pageInfo?: Maybe<PageInfo>;
};

export type StringFilterInput = {
  nil?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["String"]>;
  ne?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  nin?: Maybe<Array<Scalars["String"]>>;
  regex?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  notContains?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  notStartsWith?: Maybe<Scalars["String"]>;
  notEndsWith?: Maybe<Scalars["String"]>;
};

export type Stripe = {
  __typename?: "Stripe";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  blocks?: Maybe<Array<Maybe<Block>>>;
};

export type StripePriority = {
  __typename?: "StripePriority";
  id: Scalars["ID"];
  stripe: Stripe;
  name: Scalars["Int"];
};

export type Student = {
  __typename?: "Student";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  meta?: Maybe<Scalars["String"]>;
};

export type StudentClash = {
  __typename?: "StudentClash";
  count?: Maybe<Scalars["Int"]>;
  students?: Maybe<Array<Maybe<Student>>>;
};

export type Subject = {
  __typename?: "Subject";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  credits?: Maybe<Scalars["Int"]>;
  academicUnits?: Maybe<Array<Maybe<AcademicUnit>>>;
};

export type SubjectFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<SubjectSearchFieldsInput>;
};

export type SubjectPage = {
  __typename?: "SubjectPage";
  items: Array<Subject>;
  pageInfo?: Maybe<PageInfo>;
};

export type SubjectSearchFieldsInput = {
  searchTerm?: Maybe<Scalars["String"]>;
  and?: Maybe<SubjectSearchFieldsInput>;
  or?: Maybe<SubjectSearchFieldsInput>;
  campus?: Maybe<IntFilterInput>;
  school?: Maybe<IntFilterInput>;
  department?: Maybe<IntFilterInput>;
};

export type Tag = {
  __typename?: "Tag";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  valueType?: Maybe<Scalars["String"]>;
  modelExtension?: Maybe<ModelExtension>;
};

export type TagFilterFieldsInput = {
  code?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<TagFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<TagFilterFieldsInput>>>;
};

export type TagFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<TagFilterFieldsInput>;
};

export type TagPage = {
  __typename?: "TagPage";
  items: Array<Tag>;
  pageInfo?: Maybe<PageInfo>;
};

export type Teaching = {
  __typename?: "Teaching";
  id: Scalars["ID"];
  courseComponent?: Maybe<CourseComponent>;
  instructor: Instructor;
  maxHours?: Maybe<Scalars["Int"]>;
  priority: Scalars["Int"];
};

export type Term = {
  __typename?: "Term";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
  cycle?: Maybe<Scalars["Int"]>;
  regular?: Maybe<Scalars["Boolean"]>;
  active?: Maybe<Scalars["Boolean"]>;
  legacy?: Maybe<Scalars["Boolean"]>;
  intervals?: Maybe<Array<Maybe<Interval>>>;
  start_date?: Maybe<Scalars["String"]>;
  end_date?: Maybe<Scalars["String"]>;
};

export type TermFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<TermSearchFieldsInput>;
  hasWeeks?: Maybe<Scalars["Boolean"]>;
};

export type TermPage = {
  __typename?: "TermPage";
  items: Array<Term>;
  pageInfo?: Maybe<PageInfo>;
};

export type TermPart = {
  __typename?: "TermPart";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
  category: TermPartCategory;
};

export type TermPartCategory = {
  __typename?: "TermPartCategory";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
};

export type TermPartFilterFieldsInput = {
  id?: Maybe<IdFilterInput>;
  code?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  weeksByLinkId?: Maybe<IntFilterInput>;
  or?: Maybe<Array<TermPartFilterFieldsInput>>;
  and?: Maybe<Array<TermPartFilterFieldsInput>>;
};

export type TermPartFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<TermPartFilterFieldsInput>;
};

export type TermPartPage = {
  __typename?: "TermPartPage";
  items: Array<TermPart>;
  pageInfo?: Maybe<PageInfo>;
};

export type TermSearchFieldsInput = {
  searchTerm?: Maybe<Scalars["String"]>;
  and?: Maybe<TermSearchFieldsInput>;
  or?: Maybe<TermSearchFieldsInput>;
};

export type TimeRange = {
  __typename?: "TimeRange";
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
};

export type TimeRangeInputType = {
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
};

export type TravelClash = {
  __typename?: "TravelClash";
  from?: Maybe<Session>;
  to?: Maybe<Session>;
  fromBuilding?: Maybe<Building>;
  toBuilding?: Maybe<Building>;
  blocksBetween?: Maybe<Scalars["Int"]>;
  blocksRequired?: Maybe<Scalars["Int"]>;
};

export type UpdateClassroomBookingInput = {
  classroomBookingId: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  capacity?: Maybe<Scalars["Int"]>;
  programId?: Maybe<Scalars["ID"]>;
  classroomBookingTypeId?: Maybe<Scalars["ID"]>;
  classroomId: Scalars["ID"];
  responsibleId: Scalars["ID"];
  sessions: Array<Maybe<DateTimeRangeInput>>;
  isRecurrent?: Maybe<Scalars["Boolean"]>;
};

export type UpdateLinkInput = {
  dryRun?: Maybe<Scalars["Boolean"]>;
  skipValidations?: Maybe<Scalars["Boolean"]>;
  linkId: Scalars["ID"];
  label: Scalars["String"];
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  username: Scalars["String"];
  permissions?: Maybe<UserPermissions>;
  abilities?: Maybe<UserAbilities>;
  accessCodes?: Maybe<Array<Maybe<AccessCode>>>;
  context?: Maybe<CubeContext>;
  darwinToken?: Maybe<Scalars["String"]>;
};

export type UserAbilities = {
  __typename?: "UserAbilities";
  can_assign_blocks?: Maybe<Scalars["Boolean"]>;
  can_assign_classrooms?: Maybe<Scalars["Boolean"]>;
  can_assign_instructors?: Maybe<Scalars["Boolean"]>;
  can_assign_intervals?: Maybe<Scalars["Boolean"]>;
  can_edit_assignment?: Maybe<Scalars["Boolean"]>;
  can_edit_links_and_sections?: Maybe<Scalars["Boolean"]>;
  can_skip_program_selection?: Maybe<Scalars["Boolean"]>;
  instructor_scheduled_hours?: Maybe<Scalars["Boolean"]>;
  can_access_group_manager?: Maybe<Scalars["Boolean"]>;
  can_edit_or_delete_sessions?: Maybe<Scalars["Boolean"]>;
  can_edit_packages?: Maybe<Scalars["Boolean"]>;
  can_delete_packages?: Maybe<Scalars["Boolean"]>;
  can_delete_sections?: Maybe<Scalars["Boolean"]>;
  can_assign_instructors_from_other_courses?: Maybe<Scalars["Boolean"]>;
  can_create_groups_in_bundle_view?: Maybe<Scalars["Boolean"]>;
  can_delete_groups_in_bundle_view?: Maybe<Scalars["Boolean"]>;
  can_edit_groups_in_bundle_view?: Maybe<Scalars["Boolean"]>;
  can_migrate_groups_in_bundle_view?: Maybe<Scalars["Boolean"]>;
  can_create_sub_groups_in_group_manager?: Maybe<Scalars["Boolean"]>;
  can_edit_groups_in_group_manager?: Maybe<Scalars["Boolean"]>;
  can_delete_sessions_in_holidays?: Maybe<Scalars["Boolean"]>;
  can_edit_past_sessions?: Maybe<Scalars["Boolean"]>;
  can_unassign_intervals?: Maybe<Scalars["Boolean"]>;
};

export type UserFilterFieldsInput = {
  id?: Maybe<IntFilterInput>;
  name?: Maybe<StringFilterInput>;
  lastName?: Maybe<StringFilterInput>;
  username?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<UserFilterFieldsInput>>>;
  or?: Maybe<Array<Maybe<UserFilterFieldsInput>>>;
};

export type UserFilterInput = {
  pagination?: Maybe<PaginationInput>;
  fields?: Maybe<UserFilterFieldsInput>;
};

export type UserPage = {
  __typename?: "UserPage";
  items: Array<User>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserPermissions = {
  __typename?: "UserPermissions";
  read?: Maybe<Scalars["Boolean"]>;
  update?: Maybe<Scalars["Boolean"]>;
  create?: Maybe<Scalars["Boolean"]>;
  delete?: Maybe<Scalars["Boolean"]>;
};

export enum ValidationErrorReason {
  ClassroomCapacityExceeded = "CLASSROOM_CAPACITY_EXCEEDED",
  DatabaseError = "DATABASE_ERROR",
  EnrollmentsNotCovered = "ENROLLMENTS_NOT_COVERED",
  ExeedsMax = "EXEEDS_MAX",
  GroupCodeAlreadyTaken = "GROUP_CODE_ALREADY_TAKEN",
  GroupHasEnrollments = "GROUP_HAS_ENROLLMENTS",
  IntervalNotFound = "INTERVAL_NOT_FOUND",
  InvalidClassroom = "INVALID_CLASSROOM",
  InvalidCourse = "INVALID_COURSE",
  InvalidCourseComponent = "INVALID_COURSE_COMPONENT",
  InvalidGroup = "INVALID_GROUP",
  InvalidLink = "INVALID_LINK",
  InvalidLinkCourse = "INVALID_LINK_COURSE",
  InvalidLinkPackage = "INVALID_LINK_PACKAGE",
  InvalidPaymentPercentage = "INVALID_PAYMENT_PERCENTAGE",
  InvalidSection = "INVALID_SECTION",
  InvalidTeachingHoursPercentage = "INVALID_TEACHING_HOURS_PERCENTAGE",
  LinkCodeAlreadyInUse = "LINK_CODE_ALREADY_IN_USE",
  LowerThanMin = "LOWER_THAN_MIN",
  MoreThanOnePrimaryInstructor = "MORE_THAN_ONE_PRIMARY_INSTRUCTOR",
  MustSetPrimaryInstructor = "MUST_SET_PRIMARY_INSTRUCTOR",
  RepeatedComponentForLink = "REPEATED_COMPONENT_FOR_LINK",
  SectionCapacityExceedsCourseComponentMaxSize = "SECTION_CAPACITY_EXCEEDS_COURSE_COMPONENT_MAX_SIZE",
  SessionNotFound = "SESSION_NOT_FOUND",
  SubjectNotFound = "SUBJECT_NOT_FOUND",
  SharedPackComponentNotFound = "SHARED_PACK_COMPONENT_NOT_FOUND",
  WaitingListNotCovered = "WAITING_LIST_NOT_COVERED",
  GroupVacanciesExternalValidation = "GROUP_VACANCIES_EXTERNAL_VALIDATION",
  NumericGroupCodeValidation = "NUMERIC_GROUP_CODE_VALIDATION",
  InvalidAccessCode = "INVALID_ACCESS_CODE",
  PendingChangeRequest = "PENDING_CHANGE_REQUEST",
  IsNotTheOwnerOfTheGroup = "IS_NOT_THE_OWNER_OF_THE_GROUP",
  GroupCapacityChangeError = "GROUP_CAPACITY_CHANGE_ERROR",
  GroupVisibilityChangeError = "GROUP_VISIBILITY_CHANGE_ERROR",
  CouldNotCreateGroup = "COULD_NOT_CREATE_GROUP",
  AccessDeniedToGroupManager = "ACCESS_DENIED_TO_GROUP_MANAGER",
  GroupNotYetMigrated = "GROUP_NOT_YET_MIGRATED",
  InvalidClassroomBookingDelete = "INVALID_CLASSROOM_BOOKING_DELETE",
  SharedLinkSections = "SHARED_LINK_SECTIONS",
  CanEditPackages = "CAN_EDIT_PACKAGES",
  UserCannotDeletePackages = "USER_CANNOT_DELETE_PACKAGES",
  ClashesBetweenPackages = "CLASHES_BETWEEN_PACKAGES",
  UserCannotDeleteSectionWithSession = "USER_CANNOT_DELETE_SECTION_WITH_SESSION",
  UserCannotDeleteSection = "USER_CANNOT_DELETE_SECTION",
  LinkNotFound = "LINK_NOT_FOUND",
  UserCannotEditLink = "USER_CANNOT_EDIT_LINK",
  LinkLabelAlreadyInUse = "LINK_LABEL_ALREADY_IN_USE",
  LinkHasLabelAssigned = "LINK_HAS_LABEL_ASSIGNED",
}

export type Workspace = {
  __typename?: "Workspace";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
  institution?: Maybe<Institution>;
};
