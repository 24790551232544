/**
 * Home component
 *
 * Renders the main home page layout, including a header with the user's name,
 * breadcrumb navigation, and a subtitle. Below the header, it displays the
 * main action cards section.
 *
 * Context is used to retrieve user data for personalization.
 */

import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@foris/avocado-suite";
import ActionCards from "@modules/home/components/action-cards";
import Layout from "@common/layout/Layout";
import { Header } from "@common/components";
import { ContextApp } from "@config/Context/contextApp";
import css from "./home.module.scss";

const Home: FC = () => {
  // Hooks for translation and global user context
  const { t } = useTranslation();
  const { user } = useContext(ContextApp);

  return (
    <Layout contextSearch={false}>
      <Header
        className={css.header}
        title={`${t("home.title")} ${user?.name}`}
        breadcrumbItems={[
          {
            title: t("home.breadcrumb.current"),
          },
        ]}
        subTitle={<Text darkMode>{t("home.subtitle")}</Text>}
      />
      <main className={css.home}>
        <ActionCards />
      </main>
    </Layout>
  );
};

export default Home;
