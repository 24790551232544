import React, { useContext, useEffect, useState } from "react";
import * as R from "ramda";
import cx from "classnames";
import { AppContext } from "../../context/EditSessionsContext";
import { EditedSession, TypeAssignment, Types } from "../../context/formData.reducer";
import { Session } from "@models/ISchema";
import { Heading, Tooltip, Text, CardNotification, Button } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import css from "./formItem.module.scss";
import { useTranslation } from "react-i18next";
interface FormItemProps {
  title?: string;
  infoMessage?: string;
  type?: TypeAssignment;
  children: React.ReactNode;
  isDisabled?: boolean;
  onShowReadOnlyContent?: (value: boolean) => void;
}

const FormItem: React.FC<FormItemProps> = (props: FormItemProps) => {
  const { children, title, type, infoMessage, isDisabled, onShowReadOnlyContent } = props;
  const { state, dispatch } = useContext(AppContext);
  const {
    assignmentSame: assignment,
    assignmentEdited: edited,
    selectedSessions,
    selectedCreateSession,
    savedSessions,
    isEditionEnabled,
  } = state?.form;
  const [switchIsDisabled, setSwitchIsDisabled] = useState(false);
  const [someDeletedSessionIsSelected, setSomeDeletedSessionIsSelected] = useState(false);
  const [displayContent, setDisplayContent] = useState(edited ? edited[type] : false);

  const assignmentSame = assignment ? assignment[type] : null;
  const count = selectedSessions?.length ?? 0;
  const showCheck = count === 1 || selectedCreateSession ? false : true;
  const showReadOnlyContent = assignmentSame && !selectedCreateSession && !isEditionEnabled?.[type];

  const { t } = useTranslation();

  useEffect(() => {
    if (count === 1) {
      onShowReadOnlyContent?.(showReadOnlyContent);
    }

    if (count !== 1) {
      onShowReadOnlyContent?.(showReadOnlyContent && (!displayContent || isDisabled));
    }
  }, [showReadOnlyContent, count, displayContent]);

  const setDisplay = (value: boolean) => {
    const displayEdit = { [type]: !someDeletedSessionIsSelected && value };
    dispatch({ type: Types.AssignmentEdited, payload: displayEdit });
    setDisplayContent(!someDeletedSessionIsSelected && value);
  };

  useEffect(() => {
    if (!selectedSessions || (!assignmentSame && displayContent)) setDisplay(false);
    if (assignmentSame && !displayContent) setDisplay(true);
  }, [assignmentSame]);

  useEffect(() => {
    if (!selectedSessions?.length) return;
    setSomeDeletedSessionIsSelected(
      R.pipe(
        R.map(R.propOr("-", "id")),
        R.any(R.flip(R.has)(state?.form?.sessionsToDelete)),
      )(selectedSessions),
    );
  }, [selectedSessions]);

  useEffect(() => {
    if (someDeletedSessionIsSelected) {
      dispatch({ type: Types.AssignmentEdited, payload: { [type]: false } });
      setDisplayContent(false);
    } else {
      setDisplayContent(edited ? edited[type] : false);
    }
  }, [someDeletedSessionIsSelected]);

  /**
   * Disable the `blocks` switch if there are selected sessions with schedules
   * and without schedules at the same time.
   */
  useEffect(() => {
    if (!selectedSessions.length) return;

    const allBlockTypesAreEq = (selectedSessions: (Session | EditedSession)[]) => {
      const blockType = (session: Session | EditedSession) => {
        if ("blocks" in session) {
          return session.blocks?.selected || "";
        } else {
          const blockRange = (session as Session).assignment?.blockRange || {};
          return Object.keys(blockRange).length === 0 ? "blocks" : "hours";
        }
      };

      const savedSessionsById = savedSessions.reduce(
        (sessionsById, session) => ({ ...sessionsById, [session.id]: session }),
        {},
      );

      return selectedSessions
        .map((session: Session | EditedSession) =>
          session.id in savedSessionsById ? savedSessionsById[session.id] : session,
        )
        .map(blockType)
        .reduce(
          (acc, curr, index, array) => acc && (index === 0 || array[index - 1] === curr),
          true,
        );
    };

    setSwitchIsDisabled(type === "blocks" && !allBlockTypesAreEq(selectedSessions));
  }, [selectedSessions, type]);

  return (
    <section className={css.formItem}>
      <header className={cx(css.header, "container-row", "row--between", "row_align--center")}>
        <Heading className={css.header_title} type="h3">
          {title}
          {!!infoMessage && (
            <Tooltip label={infoMessage}>
              <Icon name="circle-info" size="sm" filled />
            </Tooltip>
          )}
        </Heading>

        {showReadOnlyContent && count === 1 && !isDisabled && (
          <Button
            leftIcon="edit"
            variant="ghost"
            size="sm"
            disabled={someDeletedSessionIsSelected || switchIsDisabled}
            onClick={() =>
              dispatch({
                type: Types.SetIsEditionEnabled,
                payload: {
                  [type]: !isEditionEnabled[type],
                },
              })
            }
          >
            {t("assignation-edit.form.content-wrapper.edit")}
          </Button>
        )}

        {showCheck && !isDisabled && (
          <Button
            leftIcon={displayContent ? "close" : "edit"}
            variant="ghost"
            size="sm"
            disabled={someDeletedSessionIsSelected || switchIsDisabled}
            onClick={() => setDisplay(!displayContent)}
          >
            {displayContent
              ? t("assignation-edit.form.content-wrapper.cancel-edit")
              : t("assignation-edit.form.content-wrapper.edit")}
          </Button>

          // <div className={css.header_edit}>
          //   <Text type="sm" weight="medium" className={css.editLabel}>
          //     {t("assignation-edit.form.content-wrapper.edit")}
          //   </Text>
          //   <Switch
          //     disabled={someDeletedSessionIsSelected || switchIsDisabled}
          //     checked={displayContent}
          //     onChange={checked => setDisplay(checked)}
          //   />
          // </div>
        )}

        {isDisabled && (
          <Text className={css.header_permissionsMessage} type="sm">
            <Icon name="circle-denied" color="neutral-50" size="sm" />{" "}
            {t("assignation-edit.form.content-wrapper.permissions-error")}
          </Text>
        )}
      </header>

      {(displayContent || (showReadOnlyContent && !displayContent) || !showCheck) && children}

      {showCheck && !displayContent && !assignmentSame && (
        <CardNotification
          className={css.formItem_warning}
          state="warning"
          title="Sin recursos compartidos"
          outlined
        >
          {t("assignation-edit.form.content-wrapper.shared-resources-warning")}
        </CardNotification>
      )}
    </section>
  );
};

export default FormItem;
