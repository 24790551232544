/**
 * OriginsSelect component provides a scenario-dependent origin selection dropdown
 * with GraphQL integration and specific filtering for assignation types.
 *
 * Key features:
 * - Dynamic origin fetching based on selected scenario
 * - Filtered to show only visible assignation-type origins
 * - Formatted display combining ID and label
 * - Loading state handling with skeleton loader
 * - Error state fallback with empty selection
 */

import React, { FC } from "react";
import { useQuery } from "react-apollo";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { GET_ORIGINS } from "../../graphql/context.queries";

/**
 * Props interface for OriginsSelect component
 * @property {any} scenarioValue - Selected scenario to filter origins
 * @property {any} originValue - Currently selected origin
 * @property {function} onChange - Callback function triggered when selection changes
 */
interface SelectProps {
  scenarioValue: any;
  originValue: any;
  onChange: (value: any) => void;
}

/**
 * Custom styles for react-select component
 * Maintains consistent styling with workspace and scenario selectors
 * using the application's design system tokens
 */
const customStyles = {
  control: (values: any) => ({
    ...values,
    fontSize: "0.875rem",
    border: "1px solid #e5e8ec",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#575d62",
    fontSize: "0.875rem",
  }),
};

/**
 * Renders an origin selection dropdown filtered by the selected scenario
 * Only shows visible origins of type ASSIGNATION
 *
 * @param {SelectProps} props Component props
 * @param {any} props.scenarioValue Selected scenario for filtering origins
 * @param {function} props.onChange Callback for selection changes
 * @param {any} props.originValue Currently selected origin
 * @returns {JSX.Element} Styled select component or loading/error state
 */
const OriginsSelect: FC<SelectProps> = ({ scenarioValue, onChange, originValue }) => {
  // Fetch origins filtered by scenario and type, ensuring fresh data with network-only policy
  const { loading, error, data } = useQuery<any, any>(GET_ORIGINS, {
    variables: {
      scenarioId: scenarioValue ? scenarioValue.value : 0,
      originFilter: {
        type: "ASSIGNATION",
        isVisible: true,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Skeleton height={"100%"} />
      </div>
    );
  if (error) return <Select options={[]} placeholder="Origin" />;

  // Transform origin data into select options, combining ID and label for better identification
  const options = data.data.origins.map((value: any) => {
    return { label: `${value.id} - ${value.label}`, value: value.id };
  });

  return (
    <div style={{ padding: "10px 0" }}>
      {/* Customized select component with theme overrides matching other selectors */}
      <Select
        styles={customStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#e5e8ec",
            primary: "#6993c9",
            neutral80: "#575d62",
            neutral90: "#575d62",
          },
        })}
        maxMenuHeight={100}
        options={options}
        placeholder="Seleccionar"
        value={originValue}
        onChange={(value: any): void => onChange(value)}
      />
    </div>
  );
};

export default OriginsSelect;
