import React, { useContext, useEffect, useState } from "react";
import { Loading } from "@foris/avocado-suite";
import { AppContext } from "../context/EditSessionsContext";
import LinkHeader from "@dimensions/link/components/HeaderLink";
import FormEdit from "./FormEdit/FormEdit";
import { useGetLink } from "../hooks/useGetLinks";
import { useGetBlocks } from "../hooks/useGetBlocks";
import ResultEdit from "./ResultEdit/ResultEdit";
import AdvanceSearchInstructor from "./FormEdit/instructor-advanced-search";
import AdvanceSearchClassroom from "../pages/FormEdit/Classroom/AdvanceSearch";
import { useGetSessionsRecommendations } from "../hooks/useGetSessionsRecommendation";
import { Types } from "../context/linkData.reducer";
import css from "./../pages/FormEdit/formEdit.module.scss";

const InitEditSessions: React.FC = () => {
  const { dispatch, state } = useContext(AppContext);
  const [dataLinks, getDataLinks] = useGetLink();
  const [dataBlocks, getDataBlocks] = useGetBlocks();
  const [
    {
      response: recommendations,
      hasErrors: hasRecommendationsErrors,
      isLoading: isLoadingRecommendations,
    },
    getRecomendations,
  ] = useGetSessionsRecommendations();
  const [heightHeader, setHeightHeder] = useState(null);

  useEffect(() => {
    if (dataLinks === null) getDataLinks();
    if (dataBlocks.dataEnd === null) getDataBlocks();
  });

  /**
   * Requets for sessions recommendations after the link info is obtained.
   */
  useEffect(() => {
    const link = state?.link;

    if (!link) return;

    const sessionIds = (link?.sections ?? []).reduce((acc, section) => {
      const sessions = (section?.sessions ?? []).map(session => session.id);
      const unasignedSessions = (section?.unasignedSessions ?? []).map(session => session.id);
      return acc.concat(sessions, unasignedSessions);
    }, []);

    getRecomendations(sessionIds);
  }, [dataLinks]);

  useEffect(() => {
    dispatch({ type: Types.SetRecommendations, payload: recommendations });
  }, [recommendations]);

  useEffect(() => {
    dispatch({ type: Types.SetHasErrorsRecommendations, payload: hasRecommendationsErrors });
  }, [hasRecommendationsErrors]);

  useEffect(() => {
    dispatch({ type: Types.SetIsLoadingRecommendations, payload: isLoadingRecommendations });
  }, [isLoadingRecommendations]);

  if (!dataLinks || !dataBlocks) return <Loading />;

  const isAdvancedSearchVisible =
    state?.page?.active?.advanceSearchClassroom || state?.page?.active?.advanceSearchInstructor;

  return (
    <main className={css.main}>
      {state?.link?.info && <LinkHeader link={state?.link?.info} setHeight={setHeightHeder} />}
      {state?.page?.loading && <Loading />}
      {[state?.page?.active?.form, isAdvancedSearchVisible].includes(true) && (
        <div className={isAdvancedSearchVisible && css.contentHidden}>
          <FormEdit heightHeader={heightHeader} />
        </div>
      )}
      {state?.page?.active?.results && <ResultEdit heightHeader={heightHeader} />}
      {state?.page?.active?.advanceSearchClassroom && <AdvanceSearchClassroom />}
      {state?.page?.active?.advanceSearchInstructor && <AdvanceSearchInstructor />}
    </main>
  );
};

export default InitEditSessions;
