import React, { useState, useContext, useMemo } from "react";
import cx from "classnames";
import { motion } from "motion/react";
import { Icon } from "@foris/avocado-icons";
import { CardNotification, Heading, Tabs, Text, ThemeStore, Tooltip } from "@foris/avocado-suite";
import { Day, RecommendationAlternatives } from "@models/ISchema";
import { AppContext } from "../../../context/EditSessionsContext";
import i18n from "@config/i18n";
import RecommendationsSectionDetails from "../recommendations-section-details";
import RecommendationsSectionSchedule from "../recommendations-section-schedule";
import RecommendationsSectionInstructors from "../recommendations-section-instructors";
import RecommendationsSectionClassrooms from "../recommendations-section-classrooms";
import styles from "./recommendationsSection.module.scss";
import { useTranslation } from "react-i18next";

interface RecommendationsSectionProps {
  isOpen?: boolean;
  isLoading?: boolean;
  hasErrors?: boolean;
  onToggle?: () => void;
}

export interface TabDay {
  enName: Day;
  value: number;
  label: string;
}

const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;
const SUNDAY = 7;

const tabDays = (): TabDay[] => [
  {
    enName: Day.Monday,
    value: MONDAY,
    label: i18n.t("day-list.short-names.monday"),
  },
  {
    enName: Day.Tuesday,
    value: TUESDAY,
    label: i18n.t("day-list.short-names.tuesday"),
  },
  {
    enName: Day.Wednesday,
    value: WEDNESDAY,
    label: i18n.t("day-list.short-names.wednesday"),
  },
  {
    enName: Day.Thursday,
    value: THURSDAY,
    label: i18n.t("day-list.short-names.thursday"),
  },
  {
    enName: Day.Friday,
    value: FRIDAY,
    label: i18n.t("day-list.short-names.friday"),
  },
  {
    enName: Day.Saturday,
    value: SATURDAY,
    label: i18n.t("day-list.short-names.saturday"),
  },
  {
    enName: Day.Sunday,
    value: SUNDAY,
    label: i18n.t("day-list.short-names.sunday"),
  },
];

const RecommendationsSection = ({
  isOpen,
  isLoading,
  hasErrors,
  onToggle,
}: RecommendationsSectionProps) => {
  const { state } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(0);
  const themeMode = ThemeStore.useThemeStore(state => state.theme.mode);

  const { t } = useTranslation();

  const selectedSessionsCount = useMemo(() => state?.form?.selectedSessions?.length ?? 0, [
    state?.form?.selectedSessions,
  ]);

  const sessionRecommendation = useMemo(() => {
    if (selectedSessionsCount !== 1) return null;

    return state?.link?.recommendations?.[state?.form?.selectedSessions?.[0]?.id] ?? null;
  }, [state?.form?.selectedSessions, state?.link?.recommendations]);

  const sessionRecommendationAlternatives = sessionRecommendation?.alternatives ?? [];

  const alternativesByDay = useMemo(() => {
    const daysAlternatives: {
      [dayId: number]: { id: number; alternatives: RecommendationAlternatives[] };
    } = {};

    sessionRecommendationAlternatives.forEach(alt => {
      const dayId = Number(alt.days.id);

      if (dayId in daysAlternatives) {
        daysAlternatives[Number(alt.days.id)].alternatives.push(alt);
      } else {
        daysAlternatives[Number(alt.days.id)] = {
          id: dayId,
          alternatives: [alt],
        };
      }
    });

    return daysAlternatives;
  }, [sessionRecommendationAlternatives]);

  const currentDayAlternatives =
    alternativesByDay?.[tabDays()?.[activeTab]?.value]?.alternatives ?? [];

  const editedSession = state?.form?.editedSessions;

  return (
    <motion.div
      className={cx(
        styles.recommendationsSection,
        themeMode === "dark" && styles.recommendationsSection__dark,
        !isOpen && styles.recommendationsSection__closed,
        isLoading && !hasErrors && styles.recommendationsSection__loading,
        (isLoading || hasErrors) && styles.recommendationsSection__disabled,
      )}
      initial={{
        width: "130px",
        height: "40px",
        overflow: "hidden",
        position: "absolute",
        top: "90px",
        right: "-24px",
      }}
      animate={{
        width: isOpen ? "100%" : "130px",
        height: isOpen ? "auto" : "40px",
        position: isOpen ? "relative" : "absolute",
        right: isOpen ? "0" : "-24px",
        top: isOpen ? "0" : "90px",
      }}
      transition={{
        width: { duration: isOpen ? 0.2 : 0.05 },
        height: { duration: isOpen ? 0.2 : 0.05 },
      }}
    >
      <div
        className={styles.recommendationsSection_header}
        role="button"
        onClick={() => !isLoading && !hasErrors && onToggle?.()}
      >
        <div className={styles.headerLeft}>
          <Icon
            className={styles.headerLeft_icon}
            size="md"
            name={isLoading && !hasErrors ? "loading" : "sparkles"}
            color={isLoading || hasErrors ? "neutral-50" : "primary-50"}
          />
          <Heading className={styles.headerLeft_title} type="h3">
            {t("assignation-edit.recommendation-card.title")}
          </Heading>
        </div>

        {isOpen && (
          <button className={styles.headerToggle}>
            <Icon size="md" name="close" />
          </button>
        )}
      </div>

      <div className={styles.recommendationsSection_content}>
        <RecommendationsSectionDetails
          themeMode={themeMode}
          selectedSessionsCount={selectedSessionsCount}
          selectedSession={state?.form?.selectedSessions?.[0]}
          linkPackages={sessionRecommendation?.session?.links?.[0]?.packages ?? []}
        />

        {selectedSessionsCount === 1 && (
          <div className={styles.recommendationsAlternatives}>
            <Heading className={styles.recommendationsAlternatives_title} type="h4">
              {sessionRecommendationAlternatives?.length
                ? t("assignation-edit.recommendation-card.alternatives.compatible-alternatives", {
                    count: sessionRecommendationAlternatives?.length,
                  })
                : t("assignation-edit.recommendation-card.alternatives.without-alternatives")}

              <Tooltip
                className={styles.titleTooltip}
                label={
                  <div className={styles.titleTooltip_content}>
                    <Icon name="alert-triangle" color="warning-50" />
                    <Text type="sm" weight="medium">
                      : {t("assignation-edit.recommendation-card.alternatives.info-tooltip")}
                    </Text>
                  </div>
                }
              >
                <Icon name="circle-info" color="neutral-70" />
              </Tooltip>
            </Heading>

            {!!sessionRecommendationAlternatives?.length && (
              <div className={styles.recommendationsAlternatives_tabs}>
                <Tabs
                  activeIndex={activeTab}
                  className={cx(
                    styles.alternativeTabs,
                    themeMode === "dark" && styles.alternativeTabs__dark,
                  )}
                  items={tabDays().map(day => ({
                    children: currentDayAlternatives?.length ? (
                      <div className={styles.alternativeTabs_content}>
                        {currentDayAlternatives?.map((alt, index) => (
                          <div key={index} className={styles.contentBox}>
                            {!!alt?.start_time && (
                              <RecommendationsSectionSchedule
                                alternatives={alt}
                                activeDay={day}
                                editedSession={editedSession}
                              />
                            )}

                            {!!alt?.instructors?.length && (
                              <RecommendationsSectionInstructors
                                alternatives={alt}
                                editedSession={editedSession}
                              />
                            )}

                            {!!alt?.classrooms?.length && (
                              <RecommendationsSectionClassrooms
                                alternatives={alt}
                                editedSession={editedSession}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <CardNotification
                        state="info"
                        title={t(
                          "assignation-edit.recommendation-card.alternatives.empty-day-title",
                        )}
                      >
                        {t("assignation-edit.recommendation-card.alternatives.empty-day")}
                      </CardNotification>
                    ),
                    label: `${day.label} (${alternativesByDay?.[day.value]?.alternatives?.length ??
                      0})`,
                  }))}
                  onClick={setActiveTab}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default RecommendationsSection;
