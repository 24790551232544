import * as React from "react";
import { Modal as SuiteModal, Heading, Button, Text } from "@foris/avocado-suite";
import cx from "classnames";
import css from "./modal.module.scss";

export type ModalStateType = "confirm" | "error" | "warning";

const stateColors = {
  confirm: undefined,
  error: "danger",
  warning: "warning",
};

export interface IModalState {
  /** type modal */
  typeState: ModalStateType;
  /** title modal */
  title?: string;
  subtitle?: string;
  /** open modal */
  show: boolean;
  /** overwrite className */
  className?: string;
  /** content modal */
  children?: React.ReactNode | string;
  /** text button principal */
  textButtonPrincipal?: string;
  colorButtonPrincipal?: "primary" | "secondary" | "danger" | "ghost";
  isDisabledButtonPrincipal?: boolean;
  /** action button principal */
  onClickPrincipal?: () => void;
  /** On close button */
  onClose?: () => void;
  /** text button secondary */
  textButtonSecondary?: string;
  colorButtonSecondary?: "primary" | "secondary" | "danger" | "ghost";
  isDisabledButtonSecondary?: boolean;
  /** action button secondary */
  onClickSecondary?: () => void;
  isLoading?: boolean;
}

const Modal: React.FC<IModalState> = props => {
  const {
    typeState,
    title,
    subtitle,
    show,
    className,
    children,
    textButtonPrincipal,
    isDisabledButtonPrincipal,
    onClickPrincipal,
    textButtonSecondary,
    isDisabledButtonSecondary,
    onClickSecondary,
    onClose,
    colorButtonPrincipal = "primary",
    colorButtonSecondary = "secondary",
    isLoading,
  } = props;

  return (
    <SuiteModal
      className={cx(css.modal, className && className)}
      open={show}
      onClose={onClose}
      headerStatusColor={stateColors[typeState]}
      header={
        <div className={css.modal_header}>
          <Heading type="h2" className={css.headerTitle}>
            {title}
          </Heading>
        </div>
      }
      actions={
        <>
          {textButtonSecondary && (
            <Button
              variant={colorButtonSecondary}
              onClick={onClickSecondary}
              disabled={isLoading || isDisabledButtonSecondary}
            >
              {textButtonSecondary}
            </Button>
          )}
          {textButtonPrincipal && (
            <Button
              variant={colorButtonPrincipal}
              loading={isLoading}
              disabled={isDisabledButtonPrincipal}
              onClick={onClickPrincipal}
            >
              {textButtonPrincipal}
            </Button>
          )}
        </>
      }
    >
      <div className={css.modal_content}>
        {!!subtitle && (
          <Text weight="medium" className={css.contentSubtitle}>
            {subtitle}
          </Text>
        )}

        <section className={css.contentChildren}>{children}</section>
      </div>
    </SuiteModal>
  );
};

export default Modal;
