import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { CardNotification, Text } from "@foris/avocado-suite";
import { COMPONENT_LIST } from "../../graphql/components.queries";
import ComponentItem, { IReturnComponent } from "../component-item/ComponentItem";
import Validations from "@common/components/Validations/Validations";
import { DataQuery, CourseComponent } from "@models/ISchema";
import { ErrorTpe, IErrorWarning } from "@models/Errors/ErrorWarning";
import { PathParams } from "@edhtypes/general";
import css from "./createLink.module.scss";

interface AddLinkSectionProps {
  courseId: number;
  externalErrors?: {
    type?: string;
    text: string;
  }[];
  onShowNextStep?: (show: boolean) => void;
  onValidForm?: (valid: boolean) => void;
  onCreateSections?: (sections: IReturnComponent[]) => void;
  setIsLoading?: (isLoading: boolean) => void;
  className?: string;
}

const CreateLink: FC<AddLinkSectionProps> = ({
  courseId,
  externalErrors = [],
  onShowNextStep,
  onValidForm,
  onCreateSections,
  setIsLoading,
  className,
}) => {
  // State for component list
  const [componentList, setComponentList] = useState<CourseComponent[]>(null);
  const [createSections, setCreateSections] = useState<IReturnComponent[]>(null);
  // Hooks for Apollo Client and URL params
  const client = useApolloClient();
  const { scenario }: PathParams = useParams();
  const { t } = useTranslation();

  const handleSetCreateSections = (sections: IReturnComponent[]) => {
    setCreateSections(sections);
    onCreateSections?.(sections);
  };

  /**
   * Validations create section (example)
   */
  const validations = useMemo(() => {
    let countOldSection = 0;
    let countNotSelect = 0;
    let countNewSection = 0;
    const componentCount = componentList ? componentList.length : null;
    let objValidation: IErrorWarning = {
      type: "ERROR",
      message: t("create-group-link.step-2.component.generic-error"),
    };
    const validationErrorWarning: IErrorWarning[] = [];

    if (!createSections) return validationErrorWarning;

    createSections.forEach(value => {
      if (value.typeSection === "OLD_SECTION") {
        countOldSection++;
        if (!value.section) countNotSelect++;
      }
      if (value.typeSection === "NEW_SECTION") countNewSection++;
    });

    if (componentCount && createSections.length === componentCount) {
      // validate one new_section
      if (countNewSection === 0) {
        objValidation = {
          type: "ERROR",
          message: t("create-group-link.step-2.component.new-section.error-message"),
        };
        validationErrorWarning.push(objValidation);
      }

      // validate old_section and select a section
      if (countOldSection > 0 && countNotSelect > 0) {
        objValidation = {
          type: "ERROR",
          message: t("create-group-link.step-2.component.section-existing.error-message"),
        };
        validationErrorWarning.push(objValidation);
      }
    }

    return validationErrorWarning;
  }, [createSections, componentList]);

  const externalValidations = useMemo(() => {
    return externalErrors.map(error => {
      return {
        type: (error?.type ? String(error?.type)?.toUpperCase() : "ERROR") as ErrorTpe,
        message: error?.text,
      };
    });
  }, [externalErrors]);

  /**
   * List of components by courseId
   */
  const getComponentList = useCallback(async () => {
    try {
      setIsLoading(true);
      const variables = {
        scenarioId: scenario,
        filter: {
          fields: {
            courseId: {
              eq: courseId,
            },
          },
        },
      };

      const { data } = await client.query({ query: COMPONENT_LIST, variables });
      const components: DataQuery = data.data;

      setComponentList(components.courseComponents.items);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, [scenario, client, courseId]);

  useEffect(() => {
    if (componentList === null) getComponentList();
  }, [getComponentList, componentList]);

  useEffect(() => {
    if (!createSections && componentList) {
      const sections = [];
      componentList.forEach(value => {
        const component: IReturnComponent = {
          courseComponent: value,
          typeSection: "NEW_SECTION",
          section: null,
        };
        sections.push(component);
      });
      handleSetCreateSections(sections);
    }
  }, [createSections, componentList]);

  useEffect(() => {
    onShowNextStep?.(
      createSections && componentList && componentList.length > 0 && !validations.length,
    );
  }, [validations, createSections, componentList]);

  useEffect(() => {
    onValidForm?.(!validations.length);
  }, [validations]);

  return (
    <section className={cx(css.createLink, className)}>
      {componentList && createSections && (
        <>
          {componentList.length === 0 ? (
            <CardNotification
              className={css.createLink_warnNotification}
              outlined
              title={t("create-group-link.step-2.error-message.title")}
              state="warning"
            >
              {t("create-group-link.step-2.error-message.text")}
            </CardNotification>
          ) : (
            <div className={css.createLink_content}>
              <Text weight="medium">{t("create-group-link.step-2.component.title")}</Text>
              <ul>
                {componentList &&
                  componentList.map(value => {
                    return (
                      <ComponentItem
                        key={value.id}
                        courseComponent={value}
                        courseId={courseId}
                        onCallback={value => {
                          const sections = [...createSections];
                          const findComponent = sections.findIndex(
                            cmp => cmp.courseComponent.id === value.courseComponent.id,
                          );
                          if (findComponent >= 0) sections.splice(findComponent, 1);
                          sections.push(value);
                          handleSetCreateSections(sections);
                        }}
                      />
                    );
                  })}
              </ul>

              {(!!validations?.length || !!externalValidations?.length) && (
                <Validations
                  className={css.createLink_validations}
                  validations={[...validations, ...externalValidations]}
                  onCallback={(valueCheck: boolean) => console.debug(valueCheck)}
                />
              )}
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default CreateLink;
