import React, { ReactNode, useMemo, useState } from "react";
import { Link, Tag, Text } from "@foris/avocado-suite";
import styles from "./recommendationsSectionRow.module.scss";

interface RecommendationsSectionRowProps {
  title: string;
  items: {
    label: ReactNode;
    isActive?: boolean;
    isDisabled?: boolean;
    onClick?: () => void;
  }[];
}

const DEFAULT_MAX_ITEMS = 5;

const RecommendationsSectionRow = ({ title, items = [] }: RecommendationsSectionRowProps) => {
  const [visibleItems, setVisibleItems] = useState(DEFAULT_MAX_ITEMS);

  const filteredItems = useMemo(() => [...items].slice(0, visibleItems), [items, visibleItems]);

  const handleShowMoreItems = () => {
    if (visibleItems + DEFAULT_MAX_ITEMS > items.length) {
      setVisibleItems(items.length);
    } else {
      setVisibleItems(visibleItems + DEFAULT_MAX_ITEMS);
    }
  };

  return (
    <div className={styles.recommendationsSectionRow}>
      <Text className={styles.recommendationsSectionRow_title} type="sm" weight="medium">
        {title}
      </Text>

      <div className={styles.recommendationsSectionRow_items}>
        {filteredItems?.map((item, index) => (
          <Tag
            key={index}
            className={styles.tagItem}
            type="outlined"
            active={item?.isActive}
            disabled={item?.isDisabled}
            onClick={() => item?.onClick?.()}
          >
            {item?.label}
          </Tag>
        ))}

        {filteredItems.length < items.length && (
          <Link
            className={styles.linkItem}
            size="sm"
            iconRight="chevron-down"
            onClick={handleShowMoreItems}
          >
            Más
          </Link>
        )}
      </div>
    </div>
  );
};

export default RecommendationsSectionRow;
