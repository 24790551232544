import {
  Block,
  Interval,
  Link,
  Recommendation,
  RecommendationAlternatives,
  Section,
  TermPart,
  TermPartCategory,
} from "@models/ISchema";
import { ActionMap } from "@models/IContext";
import {
  blockRanges,
  infoLink,
  rawBlocks,
  sectionLink,
  setAssignedWeeksByComponent,
  setHasErrorsRecommendations,
  setIsLoadingRecommendations,
  setRecommendations,
  setSelectedWeek,
  termPartsByCategory,
  weeksLink,
} from "./linkData.actions";

export interface Week extends Interval {
  value?: number;
  checked?: boolean;
  highlight?: boolean;
}

export interface WeeksById {
  [key: string]: Week;
}

export interface Option {
  value: string;
  label: string;
}

export interface BlockRanges {
  start: Option[];
  end: Option[];
}

export interface IWeek {
  id: string;
  value: string;
  status: string;
  title: string;
  endingDate: Date;
  startingDate: Date;
  eventCount: string;
}

export type TermPartsByCategory = {
  category: TermPartCategory;
  termParts: TermPart[];
};

export interface AdaptedRecommendationAlternative
  extends Omit<
    RecommendationAlternatives,
    "start_time" | "current_asignment_mark" | "days" | "classrooms" | "instructors"
  > {
  classrooms?: string[];
  instructors?: string[];
  days?: string[];
  startTime?: string;
  currentAsignmentMark?: boolean;
}

export type RecommendationsBySessionId = {
  [key: string]: Recommendation;
};

export type LinkPageType = {
  info: Link;
  sections: Section[];
  termPartsByCategory: TermPartsByCategory[];
  weeks: Week[];
  blockRanges: BlockRanges;
  rawBlocks: Block[];
  selectedWeek: IWeek;
  assignedWeeksByComponent: any; // { [key: string]: { [key: string]: boolean } };
  recommendations: RecommendationsBySessionId;
  isLoadingRecommendations: boolean;
  hasErrorsRecommendations: boolean;
};

export enum Types {
  BlockRanges = "BLOCK_RANGES_LINK",
  InfoLink = "INFO_LINK",
  RawBlocks = "RAW_BLOCK_LINK",
  SectionLink = "SECTION_LINK",
  SetAssignedWeeksByComponent = "SET_ASSIGNED_WEEKS_BY_COMPONENT",
  SetRecommendations = "SET_RECOMMENDATIONS",
  SetIsLoadingRecommendations = "SET_IS_LOADING_RECOMMENDATIONS",
  SetHasErrorsRecommendations = "SET_HAS_ERRORS_RECOMMENDATIONS",
  SetSelectedWeek = "SetSelectedWeek",
  TermPartsByCategory = "TERM_PARTS_BY_CATEGORY",
  WeeksLink = "WEEKS_LINK",
}

type LinkPayload = {
  [Types.BlockRanges]: BlockRanges;
  [Types.InfoLink]: Link;
  [Types.RawBlocks]: Block[];
  [Types.SectionLink]: Section[];
  [Types.SetAssignedWeeksByComponent]: Link;
  [Types.SetRecommendations]: RecommendationsBySessionId;
  [Types.SetIsLoadingRecommendations]: boolean;
  [Types.SetHasErrorsRecommendations]: boolean;
  [Types.SetSelectedWeek]: IWeek;
  [Types.TermPartsByCategory]: TermPartsByCategory[];
  [Types.WeeksLink]: Week[];
};

export type LinkActions = ActionMap<LinkPayload>[keyof ActionMap<LinkPayload>];

export const linkReducer = (state: LinkPageType, action: LinkActions) => {
  switch (action?.type) {
    case Types.BlockRanges:
      return blockRanges(action?.payload, state);
    case Types.InfoLink:
      return infoLink(action?.payload, state);
    case Types.RawBlocks:
      return rawBlocks(action?.payload, state);
    case Types.SectionLink:
      return sectionLink(action?.payload, state);
    case Types.SetAssignedWeeksByComponent:
      return setAssignedWeeksByComponent(action?.payload, state);
    case Types.SetRecommendations:
      return setRecommendations(action?.payload, state);
    case Types.SetIsLoadingRecommendations:
      return setIsLoadingRecommendations(action?.payload, state);
    case Types.SetHasErrorsRecommendations:
      return setHasErrorsRecommendations(action?.payload, state);
    case Types.SetSelectedWeek:
      return setSelectedWeek(action?.payload, state);
    case Types.TermPartsByCategory:
      return termPartsByCategory(action?.payload, state);
    case Types.WeeksLink:
      return weeksLink(action?.payload, state);
    default:
      return state;
  }
};
