/**
 * RestActions component
 *
 * Displays a set of grouped action cards related to data management,
 * student management, programming tools, and mass actions.
 *
 * Each card contains an icon, title, description, and one or more action buttons.
 * Some buttons perform navigations using React Router and custom navigation hooks.
 */

import React, { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@foris/avocado-suite";
import { Module } from "@models/ISchema";
import { ContextEDH } from "@context/ContextEDH";
import SectionBox from "../section-box";
import styles from "./restActions.module.scss";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import { useHistory } from "react-router-dom";
import useModulesByCode from "@common/hooks/useModulesByCode";

const DATA_MANAGEMENT = {
  catalogs: "home.action-cards.data-management.btn-catalogs",
};

const STUDENT_MANAGEMENT = {
  forecast: "home.action-cards.student-management.btn-projections",
  demand_editor: "home.action-cards.student-management.btn-lawsuit-editor",
};

const SCHEDULE_MANAGEMENT = {
  schedule: {
    title: "home.action-cards.programming-management.btn-analytics",
    order: 1,
  },
  bookings: {
    title: "home.action-cards.programming-management.btn-reservations",
    order: 2,
  },
  assignation: {
    title: "home.action-cards.programming-management.btn-assignments",
    order: 3,
  },
};

const MASS_ACTIONS = {
  suite_mops: "home.action-cards.mass-actions.btn-programming-load",
};

const RestActions: FC = () => {
  const history = useHistory();
  const { state } = useContext(ContextEDH);
  const { t } = useTranslation();
  const { bookingListUrl } = useNavigationUrl();
  const { modulesByCode } = useModulesByCode();

  const userCanCreate = state?.base?.base?.user?.permissions?.create;
  const userCanEdit = state?.base?.base?.user?.permissions?.update;
  const canAccessEDH = useMemo(() => {
    return Object.values(modulesByCode)?.some(
      item => item.code === "scheduler_editor" && item.accesible,
    );
  }, [modulesByCode]);

  const accesibleModulesByCategory = useMemo(() => {
    const categories = {
      dataManagement: [],
      studentManagement: [],
      scheduleManagement: [],
      massActions: [],
    };

    const getItem = (item: Module, title: string) => {
      return {
        ...item,
        title: t(title),
        onClick: () => {
          if (item?.code === "bookings" && canAccessEDH && (userCanCreate || userCanEdit)) {
            history.push(bookingListUrl());
            return;
          }

          window.open(item?.url, "_blank");
        },
      };
    };

    Object.values(modulesByCode).forEach(item => {
      if (!item?.accesible) {
        return;
      }

      item?.code in DATA_MANAGEMENT &&
        categories?.dataManagement.push(getItem(item, DATA_MANAGEMENT[item.code]));

      item?.code in STUDENT_MANAGEMENT &&
        categories?.studentManagement.push(getItem(item, STUDENT_MANAGEMENT[item.code]));

      item?.code in SCHEDULE_MANAGEMENT &&
        categories?.scheduleManagement.push(getItem(item, SCHEDULE_MANAGEMENT[item.code]?.title));

      item?.code in MASS_ACTIONS &&
        categories?.massActions.push(getItem(item, MASS_ACTIONS[item.code]));
    });

    return {
      ...categories,
      scheduleManagement: categories?.scheduleManagement?.sort((a, b) => {
        return (
          (SCHEDULE_MANAGEMENT[a?.code]?.order || 0) - (SCHEDULE_MANAGEMENT[b?.code]?.order || 0)
        );
      }),
    };
  }, [modulesByCode, t, bookingListUrl, canAccessEDH, userCanCreate, userCanEdit]);

  return (
    <div className={styles.restActions}>
      {!!accesibleModulesByCategory?.dataManagement?.length && (
        <SectionBox
          icon="database"
          title={t("home.action-cards.data-management.title")}
          description={t("home.action-cards.data-management.description")}
          buttons={
            <>
              {accesibleModulesByCategory?.dataManagement?.map((option, key) => (
                <Button key={key} variant="secondary" onClick={option?.onClick}>
                  {option?.title}
                </Button>
              ))}
            </>
          }
        />
      )}

      {!!accesibleModulesByCategory?.studentManagement?.length && (
        <SectionBox
          icon="user-check"
          title={t("home.action-cards.student-management.title")}
          description={t("home.action-cards.student-management.description")}
          buttons={
            <>
              {accesibleModulesByCategory?.studentManagement?.map((option, key) => (
                <Button key={key} variant="secondary" onClick={option?.onClick}>
                  {option?.title}
                </Button>
              ))}
            </>
          }
        />
      )}

      {!!accesibleModulesByCategory?.scheduleManagement?.length && (
        <SectionBox
          icon="report"
          title={t("home.action-cards.programming-management.title")}
          description={t("home.action-cards.programming-management.description")}
          buttons={
            <>
              {accesibleModulesByCategory?.scheduleManagement?.map((option, key) => (
                <Button key={key} variant="secondary" onClick={option?.onClick}>
                  {option?.title}
                </Button>
              ))}
            </>
          }
        />
      )}

      {!!accesibleModulesByCategory?.massActions?.length && (
        <SectionBox
          icon="layers"
          title={t("home.action-cards.mass-actions.title")}
          // Disabled until hyperlink is available
          // titleExtraContent={
          //   <Button
          //     className={styles.btnMonitor}
          //     variant="ghost"
          //     rightIcon="external-link"
          //     size="sm"
          //   >
          //     {t("home.action-cards.mass-actions.btn-monitor")}
          //   </Button>
          // }
          description={t("home.action-cards.mass-actions.description")}
          buttons={
            <>
              {accesibleModulesByCategory?.massActions?.map((option, key) => (
                <Button key={key} variant="secondary" onClick={option?.onClick}>
                  {option?.title}
                </Button>
              ))}
            </>
          }
        />
      )}
    </div>
  );
};

export default RestActions;
