import React, { FC, useState } from "react";
import { Box, Heading, RadioButton, Text, Loading } from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";
import { IReturnComponent } from "../component-item/ComponentItem";
import CreateLink from "../create-link/CreateLink";
import css from "./addLinkSection.module.scss";

interface AddLinkSectionProps {
  courseId: number;
  externalErrors?: {
    type?: string;
    text: string;
  }[];
  groupCode?: string;
  groupVacancies?: string;
  onCreateSections?: (sections: IReturnComponent[]) => void;
  onShowNextStep?: (show: boolean) => void;
  onValidForm?: (isValid: boolean) => void;
  programId?: string | number;
  termId: number;
}

const AddLinkSection: FC<AddLinkSectionProps> = props => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box className={css.addLinkSection}>
      <Heading className={css.addLinkSection_title} type="h3">
        {t("create-group-link.step-2.title")}
      </Heading>
      {isLoading ? (
        <Loading inline size="sm" className={css.addLinkSection_loading} />
      ) : (
        <>
          <Text className={css.addLinkSection_description} weight="medium">
            {t("create-group-link.step-2.add-link-or-sections.title")}
          </Text>

          <div className={css.addLinkSection_options}>
            <RadioButton
              className={css.radioButton}
              labelRight={t("create-group-link.step-2.add-link-or-sections.link-new.label")}
              name="newLink"
              checked
            />
            <RadioButton
              className={css.radioButton}
              labelRight={t("create-group-link.step-2.add-link-or-sections.link-existing.label")}
              name="newLink"
              disabled={true}
            />
          </div>
        </>
      )}
      <CreateLink
        className={isLoading ? css.addLinkSection_createLinkLoading : ""}
        setIsLoading={setIsLoading}
        {...props}
      />
    </Box>
  );
};

export default AddLinkSection;
