/**
 * Utility functions for handling dimension-based filtering and query string mapping
 * in the context search component.
 *
 * These utilities provide:
 * - Internationalized dimension filter options
 * - Query string mapping for GraphQL operations
 * - Consistent dimension handling across the application
 */

import { Dimension } from "@models/IDimension";
import i18n from "@config/i18n";

/**
 * Returns an array of available dimension filter options with internationalized labels
 * Used in the context search dropdown for dimension selection
 *
 * Each option contains:
 * @property {string} label - i18n translated dimension title
 * @property {Dimension} value - Corresponding dimension enum value
 *
 * @returns {Array<{label: string, value: Dimension}>} Array of dimension filter options
 */
export const getOptionsFilter = () => {
  return [
    {
      label: i18n.t("dimension.classroom.title"),
      value: Dimension.classroom,
    },
    {
      label: i18n.t("dimension.instructor.title"),
      value: Dimension.instructor,
    },
    {
      label: i18n.t("dimension.course.title"),
      value: Dimension.course,
    },
    {
      label: i18n.t("dimension.link.title"),
      value: Dimension.league,
    },
    {
      label: i18n.t("dimension.section.title"),
      value: Dimension.section,
    },
    {
      label: i18n.t("dimension.population.title"),
      value: Dimension.population,
    },
    {
      label: i18n.t("dimension.package.title"),
      value: Dimension.package,
    },
    {
      label: i18n.t("dimension.group.title"),
      value: Dimension.group,
    },
  ];
};

/**
 * Maps dimension values to their corresponding GraphQL query field names
 * Used to construct the appropriate query based on selected dimension
 *
 * Mapping includes:
 * - instructor -> instructors
 * - classroom -> classrooms
 * - course -> coursesWithSections
 * - section -> sections
 * - population -> populations
 * - league -> links
 * - package -> packages
 * - group -> groups
 *
 * @param {string} value - Dimension value to map
 * @returns {string} Corresponding GraphQL query field name
 */
export const getQueryString = (value: string) => {
  // Default empty string for invalid dimensions
  let queryString = "";
  // Map dimension values to their corresponding GraphQL query fields
  switch (value) {
    case Dimension.instructor:
      queryString = "instructors";
      break;
    case Dimension.classroom:
      queryString = "classrooms";
      break;
    case Dimension.course:
      queryString = "coursesWithSections";
      break;
    case Dimension.section:
      queryString = "sections";
      break;
    case Dimension.population:
      queryString = "populations";
      break;
    case Dimension.league:
      queryString = "links";
      break;
    case Dimension.package:
      queryString = "packages";
      break;
    case Dimension.group:
      queryString = "groups";
      break;
  }
  return queryString;
};
