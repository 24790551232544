/**
 * ContextScenario component manages the workspace, scenario, and origin selection
 * with Apollo GraphQL integration and route-based state management.
 *
 * Key features:
 * - Three-level context hierarchy (workspace → scenario → origin)
 * - Route-synchronized state management
 * - Calendar range caching per scenario
 * - Role-based navigation handling
 * - Mouseflow tracking integration
 * - Modal-based context switching
 */

import React, { useState, useEffect, useContext, FC } from "react";
import cx from "classnames";
import { useApolloClient } from "react-apollo";
import { withRouter, RouteComponentProps, useParams } from "react-router-dom";
import { GET_CONTEXT } from "./graphql/context.scenario.queries";
import * as mouseflow from "@utils/mouseflow";
import ContextModal from "@common/layout/components/context-modal";
import { getCacheRangesByScenario } from "@config/CalendarRanges/calendarRanges";
import { PathParams } from "@edhtypes/general";
import { ContextEDH } from "@context/ContextEDH";
import css from "./contextScenario.module.scss";

/**
 * Props interface for ContextScenario component
 * @extends RouteComponentProps for router integration
 * @property {any} history - Router history for navigation
 * @property {function} onSetCalendarRanges - Optional callback for calendar range updates
 */
interface ContextScenarioProps extends RouteComponentProps<any> {
  history: any;
  onSetCalendarRanges?: any;
}

/**
 * Renders the context selection interface and manages context state
 * Handles context switching, route updates, and calendar range synchronization
 *
 * @param {ContextScenarioProps} props - Component props
 * @returns {JSX.Element} Context selection interface with modal
 */
const ContextScenario: FC<ContextScenarioProps> = props => {
  const client = useApolloClient();
  const { state } = useContext(ContextEDH);
  const { workspace, origin, scenario }: PathParams = useParams();
  const [changeCx, setChangeCx] = useState(origin === "null" || !origin);
  const [getContext, setGetContext] = useState(false);
  const [workspaces, setWorkspaces] = useState(null);
  const [scenarios, setScenarios] = useState(null);
  const [origins, setOrigins] = useState(null);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [currentScenario, setCurrentScenario] = useState(null);
  const [currentOrigin, setCurrentOrigin] = useState(null);

  // Default empty state for select components
  // Used when data is not yet loaded or invalid
  const voidSelect = {
    options: [{ label: "", value: "" }],
    value: {
      value: "",
      label: "",
    },
  };

  /**
   * Return select options
   * @param data ''
   * @param findValue ''
   */
  /**
   * Transforms raw context data into select-compatible options and finds current value
   * @param {Array<any>} data - Raw context data array
   * @param {number} findValue - Current value to find in options
   * @returns {Object} Object containing options array and selected value
   */
  const getContextValues = (data: Array<any>, findValue: number): any => {
    const options = data.map((value: any) => {
      return { label: value.name, value: value.id };
    });
    const value = options.find((item: any) => parseInt(item.value) === findValue);
    return {
      options,
      value,
    };
  };

  /**
   * Change states
   * @param moduleConfig ''
   */
  /**
   * Handles context changes and updates related state
   * - Updates calendar ranges based on selected scenario
   * - Updates current context state
   * - Handles navigation based on user permissions
   * - Tracks context changes in Mouseflow
   *
   * @param {Object} newContext - New context values to save
   */
  const handleSaveContext = async (newContext: any) => {
    try {
      const { history } = props;
      const context = newContext.context;
      const { scenario, workspace, origin } = context;
      // set ranges calendar by scenario
      const newRanges = await getCacheRangesByScenario(scenario.value, client);
      if (newRanges) {
        localStorage.setItem("range-start-time", newRanges.start);
        localStorage.setItem("range-end-time", newRanges.end);
      }
      // Set current context
      setCurrentWorkspace(workspace);
      setCurrentScenario(scenario);
      setCurrentOrigin(origin);
      // Redirect
      const edhContext = `${workspace?.value}/${scenario?.value}/${origin?.value}`;
      const abilities = state?.base?.base?.user?.abilities;
      if (!abilities?.can_access_group_manager) {
        history.push(`/home/${edhContext}`);
      } else {
        mouseflow.actionTag("action_groups_manager_enrollments", state?.base?.isMouseflowEnabled);
        history.push(`/editor/groups-manager/${edhContext}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * first query context
   */
  /**
   * Fetches initial context data using GraphQL
   * - Retrieves workspaces, scenarios, and origins
   * - Filters origins by ASSIGNATION type and visibility
   * - Sets up initial context state
   * - Handles data transformation for select components
   */
  const getQueryContext = async () => {
    try {
      const variablesQuery = {
        query: GET_CONTEXT,
        variables: {
          scenarioFilter: {
            workspaceId: parseInt(workspace),
          },
          scenarioId: parseInt(scenario),
          originFilter: {
            type: "ASSIGNATION",
            isVisible: true,
          },
        },
      };
      const { data, loading } = await client.query(variablesQuery);

      let workspaces = voidSelect;
      let scenarios = voidSelect;
      let origins = voidSelect;

      if (loading) return null;

      if (data) {
        workspaces = getContextValues(data.base.workspaces, parseInt(workspace));
        scenarios = getContextValues(data.base.scenarios, parseInt(scenario));
        const optionOrigins = data.data.origins.map((value: any) => {
          return { label: `${value.id} - ${value.label}`, value: value.id };
        });
        let valueOrigin = null;
        if (origin) {
          valueOrigin = optionOrigins.find(
            (item: any) => parseInt(item.value) === parseInt(origin),
          );
          origins = {
            options: optionOrigins,
            value: valueOrigin,
          };
        }
        setWorkspaces(workspaces);
        setScenarios(scenarios);
        setOrigins(origins);
        setCurrentWorkspace(workspaces.value);
        setCurrentScenario(scenarios.value);
        setCurrentOrigin(origins.value);
        setGetContext(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Initialize context data when component mounts or context is reset
  useEffect(() => {
    if (!getContext) {
      getQueryContext();
    }
  });

  return (
    <>
      <div className={cx(css.contextScenario)} onClick={(): void => setChangeCx(true)}>
        <section className={css.contextScenario_title}>
          <p className={css.label}>AMBIENTE</p>
          <p className={css.info}>{currentWorkspace?.label || "-"}</p>
        </section>
        <section className={css.contextScenario_title}>
          <p className={css.label}>ESCENARIO</p>
          <p className={css.info}>{currentScenario?.label || "-"}</p>
        </section>
        <section className={css.contextScenario_title}>
          <p className={css.label}>PROCESO</p>
          <p className={css.info}>{currentOrigin?.label || "-"}</p>
        </section>
      </div>
      {workspaces && scenarios && origins && (
        <ContextModal
          open={changeCx}
          workspace={workspaces}
          scenario={scenarios}
          origin={origins}
          onClose={(): void => setChangeCx(false)}
          onSave={(newContext: any) => handleSaveContext(newContext)}
        />
      )}
    </>
  );
};

export default withRouter(ContextScenario);
