import React, { useState, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardNotification, Checkbox, RadioButton, Text } from "@foris/avocado-suite";
import { Types, FormPageType } from "../../context/formData.reducer";
import { AppContext } from "../../context/EditSessionsContext";
import { useWarnings } from "../../hooks/useWarnings";
import { Day } from "@models/ISchema";
import FormItem from "../FormEdit/FormItem";
import styles from "./daysSection.module.scss";
import ReadableValuesList from "../readable-values-list";

interface DaysSectionProps {
  infoMessage?: string;
  disabled?: boolean;
}

const dayLabelToDay = {
  "day-list.short-names.monday": Day.Monday,
  "day-list.short-names.tuesday": Day.Tuesday,
  "day-list.short-names.wednesday": Day.Wednesday,
  "day-list.short-names.thursday": Day.Thursday,
  "day-list.short-names.friday": Day.Friday,
  "day-list.short-names.saturday": Day.Saturday,
  "day-list.short-names.sunday": Day.Sunday,
};

const DaysSection: React.FC<DaysSectionProps> = ({ infoMessage, disabled = false }) => {
  const { state, dispatch } = useContext(AppContext);
  const [daysBySelection, setDaysBySelection] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(!state?.form?.isEditionEnabled?.["days"]);
  const { t } = useTranslation();

  const [warnings, setWarnings] = useWarnings(
    {
      onlyOneDay: {
        message: "assignation-edit.form.notifications.days-validations.only-one-day",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          const selectedCreateSession = form?.selectedCreateSession;
          return !selectedCreateSession && selection === "hours" && (blocks?.days?.length ?? 0) > 1;
        },
      },
      daySelection: {
        message: "assignation-edit.form.notifications.days-validations.day-required",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          return selection === "hours" && !blocks?.day && !blocks?.days?.length;
        },
      },
    },
    form => !form?.savedSessionsToCreateIds && !form?.selectedSessions?.length,
  );
  const editedSessions = state?.form?.editedSessions;

  const someDeletedSessionIsSelected = useMemo(() => {
    return state?.form?.selectedSessions?.some(session =>
      state?.form?.sessionsToDelete?.hasOwnProperty(session.id),
    );
  }, [state?.form?.selectedSessions, state?.form?.sessionsToDelete]);

  const onCheckboxDayClick = (day: Day) => () => {
    const blocks = editedSessions.blocks;
    const { days } = blocks;

    const newDays = daysBySelection[day]
      ? days?.filter(dayToFilter => dayToFilter !== day) ?? []
      : [...(days || []), day];
    const currentDay = newDays[0];

    setDaysBySelection(prevState => ({
      ...prevState,
      [day]: !prevState[day],
    }));

    dispatch({
      type: Types.BlocksEditedSessions,
      payload: {
        blocks: {
          ...blocks,
          days: newDays,
          day: currentDay,
        },
      },
    });
  };

  const onRadiobuttonDayClick = (day: Day) => () => {
    dispatch({
      type: Types.BlocksEditedSessions,
      payload: {
        ...editedSessions,
        blocks: {
          ...editedSessions.blocks,
          day: day,
        },
      },
    });
  };

  const selectedRadioDay = useMemo(
    () => (!editedSessions?.blocks ? null : editedSessions?.blocks?.day),
    [editedSessions?.blocks],
  );

  const readOnlyData = useMemo(() => {
    const day = Object.keys(dayLabelToDay)
      .find(key => dayLabelToDay[key] === selectedRadioDay)
      ?.replace("short-names", "full-names");

    return [
      {
        title: `${t("assignation-edit.form.days.title")}:`,
        value: day ? t(day) : t("assignation-edit.form.notifications.without-assignation"),
      },
    ];
  }, [selectedRadioDay, t, state?.form?.editedSessions?.blocks]);

  /**
   * Handle Day's form validations
   */
  useEffect(() => {
    setWarnings(state?.form);
  }, [state?.form?.assignmentEdited?.blocks, state?.form?.editedSessions?.blocks]);

  if (!(editedSessions?.blocks?.selected === "hours")) {
    return <></>;
  }

  return (
    <FormItem
      title={t("assignation-edit.form.days.title")}
      type="day"
      infoMessage={infoMessage}
      isDisabled={disabled}
      onShowReadOnlyContent={setIsReadOnly}
    >
      <section className={styles.days}>
        {isReadOnly ? (
          <ReadableValuesList className={styles.days_readOnly} items={readOnlyData} />
        ) : (
          <>
            <Text className={styles.days_label} type="sm">
              {t("assignation-edit.form.days.subtitle")}
            </Text>

            <div className={styles.days_options}>
              {/* Show checkbox selectors only in the creation of a new session */}
              {state?.form?.selectedCreateSession &&
                Object.entries(dayLabelToDay).map(([label, day]) => (
                  <Checkbox
                    key={day}
                    disabled={someDeletedSessionIsSelected || disabled}
                    checked={daysBySelection[day]}
                    value={daysBySelection[day]}
                    labelRight={t(label)}
                    className={styles.optionItem}
                    onChange={onCheckboxDayClick(day as Day)}
                  />
                ))}

              {/* Show radio button selectors only in the edition of a session */}
              {!state?.form?.selectedCreateSession &&
                Object.entries(dayLabelToDay).map(([label, day]) => (
                  <RadioButton
                    key={day}
                    disabled={someDeletedSessionIsSelected || disabled}
                    checked={day === selectedRadioDay}
                    value={daysBySelection[day]}
                    labelRight={t(label)}
                    className={styles.optionItem}
                    onChange={onRadiobuttonDayClick(day as Day)}
                  />
                ))}
            </div>
          </>
        )}

        {warnings.map((warning, key) => (
          <CardNotification
            key={key}
            className={styles.days_notification}
            state="warning"
            title={t("assignation-edit.form.notifications.days-validations.title")}
            outlined
          >
            {t(warning)}
          </CardNotification>
        ))}
      </section>
    </FormItem>
  );
};

export default DaysSection;
