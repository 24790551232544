/**
 * ActionCards component
 *
 * Displays the main action card for editing schedules and links,
 * including advanced search, group management, package creation,
 * and access to various related reports and change history.
 *
 * Also renders additional grouped action cards (RestActions) below the main block.
 */

import React, { FC, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, DropdownButton, Divider } from "@foris/avocado-suite";
import { ContextEDH } from "@context/ContextEDH";
import * as mouseflow from "@utils/mouseflow";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import Search from "../search";
import RestActions from "../rest-actions";
import SectionBox from "../section-box";
import css from "./actionCards.module.scss";

const ActionCards: FC = () => {
  const { state } = useContext(ContextEDH);
  const { t } = useTranslation();
  const history = useHistory();

  const base = state?.base?.base;
  const userCanAccessToGroupsManager = base?.user?.abilities?.can_access_group_manager;
  const userCanCreate = base?.user?.permissions?.create;
  // const userCanEdit = base?.user?.permissions?.update;

  const canAccessEDH = useMemo(() => {
    return base?.modules?.some(item => item?.code === "scheduler_editor" && item?.accesible);
  }, [base?.modules]);

  const userCanEditLinkSection = useMemo(() => {
    const abilities = base?.user?.abilities;
    const createGroupWithinLinkCreation = state?.base?.createGroupWithinLinkCreation;

    if (createGroupWithinLinkCreation === "MANDATORY") {
      return abilities?.can_create_groups_in_bundle_view && abilities?.can_edit_links_and_sections;
    }

    return abilities?.can_edit_links_and_sections;
  }, [base?.user, state?.base?.createGroupWithinLinkCreation]);

  // Navigation URLs used in the buttons and dropdowns
  const {
    changeHistoryUrl,
    linkChangeHistoryUrl,
    packagesAndGroupsReportUrl,
    groupsManagerUrl,
    createSectionUrl,
    createPackageUrl,
    getPackageClashReportUrl,
    getDarwinUrl,
  } = useNavigationUrl();

  const historyChangeOptions = useMemo(() => {
    const options = [
      {
        label: t("home.action-cards.editing-schedules.dropdown-button-chanlog.btn-link"),
        onClick: () => history.push(linkChangeHistoryUrl()),
      },
    ];

    if (userCanAccessToGroupsManager) {
      options.unshift({
        label: t("home.action-cards.editing-schedules.dropdown-button-chanlog.btn-group"),
        onClick: () => {
          mouseflow.actionTag(
            "action_groups_manager_main_change_history",
            state?.base?.isMouseflowEnabled,
          );
          history.push(changeHistoryUrl());
        },
      });
    }

    return options;
  }, [userCanAccessToGroupsManager, t, history, changeHistoryUrl, linkChangeHistoryUrl]);

  const reportsOptions = useMemo(() => {
    if (!getDarwinUrl()) return [];

    return [
      {
        label: t(
          "home.action-cards.editing-schedules.dropdown-button-reports.btn-package-and-group",
        ),
        onClick: () => history.push(packagesAndGroupsReportUrl()),
      },
      {
        label: t("home.action-cards.editing-schedules.dropdown-button-reports.btn-package"),
        onClick: () => window.open(getPackageClashReportUrl(), "_blank"),
      },
    ];
  }, [getDarwinUrl, packagesAndGroupsReportUrl, getPackageClashReportUrl, t]);

  return (
    <div className={css.actionCards}>
      {canAccessEDH && (
        <SectionBox
          icon="calendar-edh"
          title={t("home.action-cards.editing-schedules.title")}
          description={t("home.action-cards.editing-schedules.description")}
          customContent={
            <>
              <Search />
              <Divider className={css.actionCards_divider} />
            </>
          }
          buttons={
            <>
              {userCanAccessToGroupsManager && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    mouseflow.actionTag("action_groups_manager", state?.base?.isMouseflowEnabled);
                    history.push(groupsManagerUrl());
                  }}
                >
                  {t("home.action-cards.editing-schedules.btn-group-management")}
                </Button>
              )}
              {userCanCreate && userCanEditLinkSection && (
                <Button variant="secondary" onClick={() => history.push(createSectionUrl())}>
                  {t("home.action-cards.editing-schedules.btn-create-link")}
                </Button>
              )}
              {userCanCreate && !!base?.user?.abilities?.can_edit_packages && (
                <Button variant="secondary" onClick={() => history.push(createPackageUrl())}>
                  {t("home.action-cards.editing-schedules.btn-create-package")}
                </Button>
              )}

              {/* Dropdown for report-related actions */}
              <DropdownButton variant="secondary" items={reportsOptions}>
                {t("home.action-cards.editing-schedules.dropdown-button-reports.title")}
              </DropdownButton>
              {/* Dropdown for change history actions */}
              <DropdownButton variant="secondary" items={historyChangeOptions}>
                {t("home.action-cards.editing-schedules.dropdown-button-chanlog.title")}
              </DropdownButton>
            </>
          }
        />
      )}

      {/* Additional grouped action cards */}
      <RestActions />
    </div>
  );
};

export default ActionCards;
