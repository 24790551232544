import React from "react";
import cx from "classnames";
import styles from "./recommendationsSectionDetails.module.scss";
import { EditedSession } from "@modules/sections/EditSessions/context/formData.reducer";
import { Package, Session } from "@models/ISchema";
import { CardNotification, Heading, Text } from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";

interface RecommendationsSectionDetailsProps {
  selectedSessionsCount: number;
  selectedSession?: Session | EditedSession | null;
  linkPackages?: Package[];
  themeMode?: "light" | "dark";
}

function RecommendationsSectionDetails({
  selectedSessionsCount = 0,
  selectedSession,
  themeMode,
  linkPackages = [],
}: RecommendationsSectionDetailsProps) {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        styles.recommendationsSectionDetails,
        themeMode === "dark" && styles.recommendationsSectionDetails__dark,
      )}
    >
      <Heading className={styles.recommendationsSectionDetails_title} type="h4">
        {selectedSessionsCount === 1
          ? t("assignation-edit.recommendation-card.details.single-session-selected", {
              sessionId: selectedSession?.id,
            })
          : t("assignation-edit.recommendation-card.details.sessions-selected", {
              count: selectedSessionsCount,
            })}
      </Heading>

      {selectedSessionsCount !== 1 ? (
        <CardNotification
          state="info"
          title={t("assignation-edit.recommendation-card.details.info-title")}
        >
          {t("assignation-edit.recommendation-card.details.info-message")}
        </CardNotification>
      ) : (
        <div className={styles.recommendationsSectionDetails_content}>
          <Heading className={styles.contentTitle} type="h4">
            {t("assignation-edit.recommendation-card.details.session-packages")}
          </Heading>

          <ul className={styles.packagesList}>
            {linkPackages?.length ? (
              linkPackages?.map(pkg => (
                <li key={pkg?.id} className={styles.packagesList_item}>
                  <Text type="sm">
                    {pkg?.code}{" "}
                    {pkg?.complete
                      ? t("assignation-edit.recommendation-card.details.completed-package")
                      : t("assignation-edit.recommendation-card.details.incomplete-package")}{" "}
                    vac: {pkg?.vacancies ?? "-"}
                  </Text>
                </li>
              ))
            ) : (
              <li>
                <Text type="sm">
                  {t("assignation-edit.recommendation-card.details.no-packages-assigned")}
                </Text>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default RecommendationsSectionDetails;
