/**
 * SidebarNavigation component provides a collapsible navigation menu with role-based access control.
 *
 * Key features:
 * - Context-aware navigation items based on user's current location (home vs other pages)
 * - Role-based menu item visibility using user permissions and abilities
 * - Integration with react-burger-menu for mobile-friendly sliding navigation
 * - External link handling for Darwin admin functions
 * - Mouseflow tracking for specific navigation actions
 *
 * The menu adapts its content based on:
 * - User's current location (home/non-home views)
 * - User permissions (create, edit, group management)
 * - Module access (bookings)
 * - Custom URL configurations (SIAN integration)
 */

import React, { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Text, Divider } from "@foris/avocado-suite";
import { slide as Menu } from "react-burger-menu";
import useContextUrl from "@common/hooks/useContextUrl";
import * as mouseflow from "@utils/mouseflow";
import { ContextEDH } from "@context/ContextEDH";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import "./sidebarNavigation.scss";

/**
 * Interface representing the visibility state of the burger menu
 */
export interface MenuVisibilityState {
  isOpen: boolean;
}

/**
 * Props for the SidebarNavigation component
 * @property {boolean} isVisible - Controls the visibility state of the sidebar
 * @property {function} onSidebarToggle - Callback function to handle sidebar visibility changes
 */
interface SidebarNavigationProps {
  isVisible: boolean;
  onSidebarToggle: (state: boolean) => void;
}

const SidebarNavigation: FC<SidebarNavigationProps> = ({ isVisible, onSidebarToggle }) => {
  const history = useHistory();
  const location = useLocation();
  const { state } = useContext(ContextEDH);
  const base = state?.base?.base;
  const accessToBookingUser =
    base?.modules?.find(item => item.code === "bookings").accesible || false;

  const userCanCreate = base?.user?.permissions?.create;
  const userCanEdit = base?.user?.permissions?.update;
  const userCanAccessToGroupsManager = base?.user?.abilities?.can_access_group_manager;
  const userCanDeleteSessionsInHolidays = base?.user?.abilities?.can_delete_sessions_in_holidays;
  const sianLink = useMemo(() => {
    return (base?.customUrls ?? []).find(url => url.code === "SIAN");
  }, [base?.customUrls]);

  const { t } = useTranslation();

  const {
    getDarwinUrl,
    advancedSearchUrl,
    groupsManagerUrl,
    bookingListUrl,
    createSectionUrl,
    createPackageUrl,
    packagesAndGroupsReportUrl,
    getPackageClashReportUrl,
    changeHistoryUrl,
    linkChangeHistoryUrl,
    holidaysUrl,
  } = useNavigationUrl();

  const { origin, scenario } = useContextUrl();

  // Determines if user can edit link sections based on abilities and group creation requirements
  const userCanEditLinkSection = useMemo(() => {
    const abilities = base?.user?.abilities;
    const createGroupWithinLinkCreation = state?.base?.createGroupWithinLinkCreation;

    if (createGroupWithinLinkCreation === "MANDATORY") {
      return abilities?.can_create_groups_in_bundle_view && abilities?.can_edit_links_and_sections;
    }

    return abilities?.can_edit_links_and_sections;
  }, [base?.user, state?.base?.createGroupWithinLinkCreation]);

  // Construct report URLs only if Darwin login is configured
  const groupsAndPackageReportUrl = !!getDarwinUrl()
    ? `${getDarwinUrl()}groups_manager/group_packages/${origin}?scenario_id=${scenario}`
    : "";
  const packageClashReportUrl = !!getDarwinUrl()
    ? `${getDarwinUrl()}resultados/report_package_clashes_edh/${origin}?scenario_id=${scenario}`
    : "";

  // Determines if user is on home page to show appropriate navigation items
  const isHome = location?.pathname?.includes("home") || location?.pathname === "/";

  return (
    <Menu
      isOpen={isVisible}
      className="bm-menu"
      onStateChange={(state: MenuVisibilityState) => onSidebarToggle(state.isOpen)}
    >
      {isHome && (
        <div className="content">
          <Text className="sidebar-title">{t("sidebar-menu.administration.title")}</Text>

          <a
            href={`${getDarwinUrl()}#`}
            className="bm-item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("sidebar-menu.administration.modify-account")}
          </a>

          <a
            href={`${getDarwinUrl()}admin`}
            target="_blank"
            rel="noopener noreferrer"
            className="bm-item"
          >
            {t("sidebar-menu.administration.configuration")}
          </a>

          <a
            href={`${getDarwinUrl()}ambients`}
            target="_blank"
            rel="noopener noreferrer"
            className="bm-item"
          >
            {t("sidebar-menu.administration.manage-environments")}
          </a>

          <a
            href={`${getDarwinUrl()}scenarios`}
            target="_blank"
            rel="noopener noreferrer"
            className="bm-item"
          >
            {t("sidebar-menu.administration.manage-scenarios")}
          </a>
        </div>
      )}

      {!isHome && (
        <div className="content">
          <Link to={`${advancedSearchUrl()}`} className="bm-item">
            {t("sidebar-menu.administration.search-advances")}
          </Link>

          {userCanAccessToGroupsManager && (
            <Link to={`${groupsManagerUrl()}`} className="bm-item">
              {t("sidebar-menu.administration.groups-management")}
            </Link>
          )}

          {(userCanCreate || userCanEdit) && accessToBookingUser && (
            <Link to={`${bookingListUrl()}`} className="bm-item">
              {t("sidebar-menu.administration.reservations")}
            </Link>
          )}

          <Divider className="divider" />

          <Text className="sidebar-title">{t("sidebar-menu.creation.title")}</Text>

          {userCanCreate && userCanEditLinkSection && (
            <Link to={`${createSectionUrl()}`} className="bm-item">
              {t("sidebar-menu.creation.create-link")}
            </Link>
          )}

          {userCanCreate && !!base?.user?.abilities?.can_edit_packages && (
            <Link to={`${createPackageUrl()}`} className="bm-item">
              {t("sidebar-menu.creation.create-package")}
            </Link>
          )}

          <Divider className="divider" />

          <Text className="sidebar-title">{t("sidebar-menu.reports.title")}</Text>

          {!!groupsAndPackageReportUrl && (
            <Link to={`${packagesAndGroupsReportUrl()}`} className="bm-item">
              {t("sidebar-menu.reports.package-and-group")}
            </Link>
          )}

          {packageClashReportUrl && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${getPackageClashReportUrl()}`}
              className="bm-item"
            >
              {t("sidebar-menu.reports.package")}
            </a>
          )}

          <Divider className="divider" />

          <Text className="sidebar-title">{t("sidebar-menu.histories.title")}</Text>

          <Link to={`${linkChangeHistoryUrl()}`} className="bm-item">
            {t("sidebar-menu.histories.change-history")}
          </Link>

          {userCanAccessToGroupsManager && (
            <Link
              to={`${changeHistoryUrl()}`}
              className="bm-item"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                mouseflow.actionTag(
                  "action_groups_manager_main_change_history",
                  state?.base?.isMouseflowEnabled,
                );
                history.push(changeHistoryUrl());
              }}
            >
              {t("sidebar-menu.histories.group-change-history")}
            </Link>
          )}

          <Divider className="divider" />

          {!!userCanDeleteSessionsInHolidays && (
            <>
              <Text className="sidebar-title">{t("sidebar-menu.mass-operations.title")}</Text>
              <Link to={`${holidaysUrl()}`} className="bm-item">
                {t("sidebar-menu.mass-operations.delete-sessions-on-holidays")}
              </Link>
            </>
          )}

          {userCanAccessToGroupsManager && sianLink?.url && (
            <>
              <Divider className="divider" />
              <Text className="sidebar-title">{t("sidebar-menu.special-accesses.title")}</Text>
              <a href={sianLink?.url} target="_blank" rel="noopener noreferrer" className="bm-item">
                {t("sidebar-menu.special-accesses.request-vacancies")}
              </a>
            </>
          )}
        </div>
      )}
    </Menu>
  );
};

export default SidebarNavigation;
