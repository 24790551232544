import React, { useContext, useMemo } from "react";
import { Classroom, RecommendationAlternatives } from "@models/ISchema";
import { EditedSession, Types } from "@modules/sections/EditSessions/context/formData.reducer";
import { AppContext } from "@modules/sections/EditSessions/context/EditSessionsContext";
import { ContextApp } from "@config/Context/contextApp";
import { useTranslation } from "react-i18next";
import RecommendationsSectionRow from "../recommendations-section-row";

interface RecommendationsSectionClassroomsProps {
  alternatives: RecommendationAlternatives;
  editedSession: EditedSession;
}

const RecommendationsSectionClassrooms = ({
  alternatives,
  editedSession,
}: RecommendationsSectionClassroomsProps) => {
  const { dispatch, state } = useContext(AppContext);
  const { user } = useContext(ContextApp);
  const { t } = useTranslation();

  const canAssignClassrooms =
    user?.abilities?.can_assign_classrooms && state?.form?.isEditionEnabled?.["classrooms"];

  const editedSessionClassroomsById = useMemo(() => {
    return editedSession?.classrooms?.reduce((acc, classroom) => {
      acc[classroom?.id] = classroom;
      return acc;
    }, {});
  }, [editedSession]);

  const handleSelectClassroom = (classroom: Classroom) => {
    const isSelected = !!editedSessionClassroomsById?.[classroom?.id];

    if (!classroom?.usable || !canAssignClassrooms) {
      return;
    }

    const classroomList = [...(editedSession?.classrooms ?? [])];

    if (!isSelected) {
      const adaptedClassroom = {
        ...classroom,
        label: `${classroom?.code} - ${classroom?.name} - ${classroom?.building?.campus?.code ||
          ""}`,
        value: classroom?.id,
        isDisabled: !classroom?.usable,
      };

      classroomList.push(adaptedClassroom);
    } else {
      const classroomIndex = classroomList.findIndex(c => c?.id === classroom?.id);
      classroomList.splice(classroomIndex, 1);
    }

    dispatch({
      type: Types.ClassroomEditedSessions,
      payload: {
        classrooms: classroomList,
      },
    });
  };

  const classrooms = useMemo(() => {
    return alternatives?.classrooms?.map(classroom => ({
      label: classroom?.code,
      isActive: !!editedSessionClassroomsById?.[classroom?.id],
      isDisabled: !classroom?.usable || !canAssignClassrooms,
      onClick: () => handleSelectClassroom(classroom),
    }));
  }, [alternatives?.classrooms, editedSessionClassroomsById, canAssignClassrooms]);

  return (
    <RecommendationsSectionRow
      title={`${t("assignation-edit.recommendation-card.alternatives.classroom-alternatives")}:`}
      items={classrooms}
    />
  );
};

export default RecommendationsSectionClassrooms;
