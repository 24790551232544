import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-suite";
import { useApolloClient } from "react-apollo";
import { IOption } from "@common/components/Select/Select";
import { TERMS_QUERY } from "../../graphql/groups.queries";
import { Term } from "@models/ISchema";
import { PathParams } from "@edhtypes/general";
import css from "./termSelect.module.scss";

interface TermOption {
  label: string;
  value: string;
}

const getOriginalTermOption = (term: Term): TermOption => {
  if (!term?.code || !term?.id) {
    return {
      label: "",
      value: "",
    };
  }

  const termName = term?.name ? ` - ${term?.name}` : "";

  return {
    label: `${term?.code}${termName}`,
    value: term?.id,
  };
};

const getTermOption = (term: Term | TermOption): TermOption => {
  const termOption = term as TermOption;

  if (termOption?.value && termOption?.label) return termOption;

  return getOriginalTermOption(term as Term);
};

interface TermSelectProps {
  className?: string;
  defaultTerm?: TermOption | Term;
  externalOptions?: TermOption[];
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  onSelectTerm?: (term?: Term) => void;
  placeholder?: string;
  sendValues?: (payload: { term: string | null }) => void;
}

const savedOptions: { [key: string]: Term } = {};

const TermSelect: React.FC<TermSelectProps> = props => {
  const { t } = useTranslation();

  const {
    defaultTerm,
    className = "",
    externalOptions = [],
    isClearable = true,
    label = t("bundle.create-group.forms.select-period.label"),
    sendValues,
    onSelectTerm,
    isDisabled = false,
    placeholder,
  } = props;
  const client = useApolloClient();
  const { scenario }: PathParams = useParams();
  const [valueTerm, setValueTerm] = useState<TermOption | null>(null);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const defaultTermOption = getTermOption(defaultTerm);
  const [page, setPage] = useState(0);

  const queryOptions = (searchTerm = "", page = 1) => {
    const pageSize = 20;

    return {
      query: TERMS_QUERY,
      variables: {
        scenarioId: scenario,
        filter: {
          hasWeeks: true,
          pagination: { page, size: pageSize },
          fields: {
            searchTerm,
          },
        },
      },
    };
  };

  const getTermOptions = async (searchTerm = "", page = 1) => {
    try {
      const query = queryOptions(searchTerm, page);
      const { data } = await client.query(query);
      const terms = data?.data?.terms || {};
      const options = (terms?.items ?? []).map((term: Term) => {
        if (!(term.id in savedOptions)) {
          savedOptions[term.id] = term;
        }

        return getTermOption(term);
      });

      return {
        options: [...(page === 1 ? externalOptions : []), ...options],
        pageInfo: terms?.pageInfo,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const loadTerms = async () => {
    const hasSameTerm = prevSearchTerm === searchTerm;
    const newSearchPage = hasSameTerm ? page + 1 : 1;

    setPrevSearchTerm(searchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await getTermOptions(searchTerm, newSearchPage);

    return {
      options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  useEffect(() => {
    if (!!defaultTerm) {
      setValueTerm(defaultTermOption);
      sendValues?.({
        term: defaultTermOption.value,
      });
    }
  }, [defaultTerm]);

  const handleOnChange = (termOption: IOption) => {
    setValueTerm(termOption || null);
    sendValues?.({
      term: termOption?.value || null,
    });

    onSelectTerm?.(savedOptions?.[termOption?.value]);
  };

  return (
    <SelectPagination
      className={cx(className, css.termSelect)}
      onInputChange={setSearchTerm}
      label={label}
      value={valueTerm}
      debounceTimeout={500}
      placeholder={
        placeholder ? placeholder : t("bundle.create-group.forms.select-period.placeholder")
      }
      isClearable={isClearable}
      onChange={handleOnChange}
      loadOptions={loadTerms}
      disabled={isDisabled}
    />
  );
};

export default TermSelect;
