/**
 * Search component
 *
 * Handles advanced search redirection and context validation.
 * If no route parameters are present but context is available,
 * the user is redirected to the appropriate home route.
 *
 * It also initializes and stores time ranges in localStorage based on the scenario,
 * and allows selection of a search dimension which affects the redirection URL.
 */

import React, { FC, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@foris/avocado-suite";
import { useParams, Redirect } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import ContextSearch from "@common/layout/components/context-search";
import { getCacheRangesByScenario } from "@config/CalendarRanges/calendarRanges";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import { PathParams } from "@edhtypes/general";
import css from "./search.module.scss";

const Search: FC = () => {
  // Holds the selected dimension value coming from ContextSearch
  const [valueSelect, setValueSelect] = useState("");

  // Extracts route parameters from the URL
  const { origin, scenario, workspace }: PathParams = useParams();

  // Access the global app context
  const context: IContextApp = useContext(ContextApp);

  // Apollo client instance for cache/data access
  const client = useApolloClient();

  // Translation hook
  const { t } = useTranslation();

  // React Router history for programmatic navigation
  const history = useHistory();

  // Navigation URLs used throughout the app
  const { advancedSearchUrl, advancedSearchWithValueSelectUrl, homeUrl } = useNavigationUrl();

  // If no URL parameters are present but context exists, redirect to the home route
  if (!workspace && !scenario && !origin) {
    if (context.user.context) {
      const { workspaceId, scenarioId, originId } = context.user.context;
      if (workspaceId && scenarioId) {
        const routeHome = homeUrl(workspaceId, scenarioId, originId);
        return <Redirect to={routeHome} />;
      }
    }
  }

  // Fetches and stores date range values in localStorage based on current scenario
  const ranges = async () => {
    const newRanges = await getCacheRangesByScenario(scenario, client);
    if (newRanges) {
      localStorage.setItem("range-start-time", newRanges.start);
      localStorage.setItem("range-end-time", newRanges.end);
    }
  };

  // Updates the selected value from ContextSearch
  // Special case: converts "league" to "link"
  const getDimension = (value: string) => {
    if (value === "league") {
      setValueSelect("link");
    } else {
      setValueSelect(value);
    }
  };

  // Fetch range on component load
  ranges();

  // Selects the appropriate advanced search URL based on selected value
  const routeAdvSearch = valueSelect
    ? advancedSearchWithValueSelectUrl(valueSelect)
    : advancedSearchUrl();

  return (
    <div className={css.search}>
      {/* Search input that sends selected dimension back to parent */}
      <ContextSearch className={css.search_contextSearch} sendDimension={getDimension} />

      {/* Button to navigate to the advanced search page */}
      <Button
        className={css.search_button}
        variant="ghost"
        rightIcon="chevron-right"
        onClick={() => history.push(routeAdvSearch)}
      >
        {t("home.action-cards.editing-schedules.search.btn-advanced-search")}
      </Button>
    </div>
  );
};

export default Search;
