import { useState } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { IParams } from "@models/IParams";
import { CubeQuery, Recommendation } from "@models/ISchema";
import sessionsRecommendationCubeQuery from "../graphql/sessionsRecommendation.query.graphql";

interface IVariables {
  scenarioId: string;
  originId: string;
  sessionIds: string[];
}

export const useGetSessionsRecommendations = (): [
  {
    response: { [sessionId: string]: Recommendation } | null;
    isLoading: boolean;
    hasErrors: boolean;
  },
  (sessionIds: string[]) => void,
] => {
  const { origin: originId, scenario: scenarioId }: IParams = useParams();
  const apolloClient = useApolloClient();
  const [recommendationsResponse, setRecommendationsResponse] = useState<{
    [sessionId: string]: Recommendation;
  } | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const variables: IVariables = {
    scenarioId,
    originId,
    sessionIds: null,
  };

  const getSessionsRecomendations = async (sessionIds: string[]) => {
    setIsLoading(true);
    setHasErrors(false);

    const { data, errors } = await apolloClient.query({
      query: sessionsRecommendationCubeQuery,
      variables: { ...variables, sessionIds },
      fetchPolicy: "no-cache",
    });

    setIsLoading(false);
    setHasErrors(!!errors);

    if (errors) {
      console.error("[sessionsRecommendations error]", errors);
      return null;
    }

    const recommendations = (data?.cube as CubeQuery)?.sessionRecommendations;
    const recommendationsBySessionId: {
      [id: string]: Recommendation;
    } = recommendations?.recommendation.reduce((acc, recommendation) => {
      acc[recommendation.session.id] = recommendation;
      return acc;
    }, {});

    setRecommendationsResponse(recommendationsBySessionId);
  };

  return [
    {
      response: recommendationsResponse,
      isLoading,
      hasErrors,
    },
    getSessionsRecomendations,
  ];
};
