import { useContext, useMemo } from "react";
import { ContextEDH } from "@context/ContextEDH";
import { Module } from "@models/ISchema";

const useModulesByCode = () => {
  const { state } = useContext(ContextEDH);

  const modulesByCode = useMemo(() => {
    const modules = state?.base?.base?.modules || [];
    const modulesByCode: { [code: string]: Module } = {};

    modules.forEach(module => {
      modulesByCode[module.code] = module;
    });

    return modulesByCode;
  }, [state?.base?.base?.modules]);

  return {
    modulesByCode,
  };
};

export default useModulesByCode;
