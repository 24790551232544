import React, { useState, useRef, useContext } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Text } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import useClickOutside from "@common/hooks/useClickOutside";
import { ContextEDH } from "@context/ContextEDH";
import { Types } from "@context/base.reducer";
import scss from "./menuLanguage.module.scss";

const MenuLanguage = () => {
  const ref = useRef(null);
  const { state, dispatch } = useContext(ContextEDH);
  const {
    i18n: { changeLanguage },
    t,
  } = useTranslation();

  const [dropdown, setDropdown] = useState(false);

  const locale = state.base.locale;

  const handleChangeLocale = () => {
    const newLocale = locale === "en" ? "es" : "en";
    changeLanguage(newLocale);
    dispatch({ type: Types.setLocale, payload: newLocale });
    localStorage.setItem("locale", newLocale);
  };

  useClickOutside({
    ref,
    onClickOutside: () => setDropdown(false),
  });

  return (
    <div className={scss.menuLanguage} ref={ref}>
      <button
        type="button"
        className={cx(scss.menuLanguage_btn, dropdown && scss.menuLanguage_btn__active)}
        onClick={() => setDropdown(!dropdown)}
      >
        <Text className={scss.btn_label}>{t("user-menu.change-language.title")}</Text>
      </button>
      {dropdown && (
        <ul className={scss.menuLanguage_dropdown}>
          <button
            type="button"
            className={cx(scss.item, locale === "en" && scss.item__active)}
            onClick={handleChangeLocale}
          >
            <Text className={scss.item_label}>{t("user-menu.change-language.en")}</Text>
            {locale === "en" && (
              <Icon className="iconCheck" name="check" size="md" color="neutral-70" />
            )}
          </button>
          <button
            type="button"
            className={cx(scss.item, locale === "es" && scss.item__active)}
            onClick={handleChangeLocale}
          >
            <Text className={scss.item_label}>{t("user-menu.change-language.es")}</Text>
            {locale === "es" && (
              <Icon className="iconCheck" name="check" size="md" color="neutral-70" />
            )}
          </button>
        </ul>
      )}
    </div>
  );
};

export default MenuLanguage;
