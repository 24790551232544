/**
 * Custom hook that prevents page scrolling by toggling body overflow
 * Useful for modals, drawers, and other overlays that should prevent background scrolling
 *
 * Features:
 * - Simple overflow-based scroll locking
 * - Automatic cleanup on unmount
 * - No layout shifts or position calculations needed
 * - Clean and efficient implementation
 */

import { useEffect } from "react";

/**
 * Hook to control document body scroll locking using overflow property
 *
 * When locked:
 * - Sets body overflow to 'hidden'
 * - Prevents all scrolling while maintaining position
 *
 * When unlocked:
 * - Sets body overflow to 'auto'
 * - Restores normal scrolling behavior
 *
 * @param {boolean} isLocked - Whether to lock (true) or unlock (false) scrolling
 */
export const useScrollLock = (isLocked: boolean) => {
  // Toggle body overflow and ensure cleanup on unmount
  useEffect(() => {
    document.body.style.overflow = isLocked ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isLocked]);
};
