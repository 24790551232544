import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Text } from "@foris/avocado-suite";
import styles from "./readableValuesList.module.scss";

interface ReadableValuesListProps {
  className?: string;
  items: {
    title: string;
    value: string;
  }[];
}

const ReadableValuesList = ({ items = [], className }: ReadableValuesListProps) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.readableValues, className)}>
      {items?.map(({ title, value }, key) => (
        <Text key={key} className={styles.readableValues_item}>
          <b className={styles.title}>{title}</b> {value}
        </Text>
      ))}

      {items?.length === 0 && (
        <Text className={styles.readableValues_item}>
          {t("assignation-edit.form.notifications.without-assignation")}
        </Text>
      )}
    </div>
  );
};

export default ReadableValuesList;
