/**
 * ContextModal provides a modal interface for selecting workspace, scenario, and origin
 * with internationalization support and URL-based language switching.
 *
 * Features:
 * - Three-level cascading selection (workspace → scenario → origin)
 * - i18n support with dynamic language switching
 * - URL-based language flag detection
 * - Controlled modal state management
 * - Validation for save button activation
 */

import React, { useState, useEffect, FC } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button } from "@foris/avocado-suite";
import Modal from "@common/components/modal/Modal";
import ScenariosSelect from "@common/layout/components/context-select/components/scenarios-select";
import OriginSelect from "@common/layout/components/context-select/components/origins-select";
import WorkspaceSelect from "@common/layout/components/context-select/components/workspace-select";
import css from "./contextModal.module.scss";

/**
 * Represents a single select option value
 * @property {string} value - Unique identifier
 * @property {string} label - Display text
 */
interface SelectValue {
  value: string;
  label: string;
}

/**
 * Represents a select component's options and current value
 * @property {SelectValue} value - Currently selected value
 * @property {Array<SelectValue>} options - Available options
 */
interface SelectOptions {
  value: SelectValue;
  options: any;
}

/**
 * Props for the ContextModal component
 * @property {boolean} open - Controls modal visibility
 * @property {() => void} onClose - Callback when modal is closed
 * @property {SelectOptions} workspace - Workspace selection options and value
 * @property {SelectOptions} scenario - Scenario selection options and value
 * @property {SelectOptions} origin - Origin selection options and value
 * @property {(context: any) => void} onSave - Callback when context is saved
 */
interface ContextModalProps {
  open: boolean;
  onClose: () => void;
  workspace: SelectOptions;
  scenario: SelectOptions;
  origin: SelectOptions;
  onSave: any;
}

/**
 * Modal component for context selection with cascading dropdowns
 * Manages workspace, scenario, and origin selection with i18n support
 *
 * @param {ContextModalProps} props - Component properties
 * @returns {JSX.Element} Modal with context selection interface
 */
const ContextModal: FC<ContextModalProps> = (props: ContextModalProps) => {
  const [open, setChange] = useState(props.open);
  const [workspaceValue, setWorkspace] = useState(props.workspace?.value);
  const [scenarioValue, setScenario] = useState(props.scenario?.value);
  const [originValue, setOrigin] = useState(props.origin?.value || null);
  const [showButtonLanguage, setShowButtonLanguage] = useState<boolean>(false);

  const location = useLocation();
  const { t, i18n } = useTranslation();

  // Synchronize modal open state with props
  useEffect(() => {
    const queryParms = new URLSearchParams(location.search);
    const flagLanguage = queryParms.get("flagLang");

    if (flagLanguage?.length > 0) {
      setShowButtonLanguage(true);
    }
  }, []);

  // Synchronize modal open state with props
  useEffect(() => {
    if (open !== props.open) setChange(props.open);
  }, [open, props.open]);

  /**
   * Changes the application language to English
   * Only available when flagLang URL parameter is present
   */
  const changeLanguage = () => {
    i18n.changeLanguage("en");
  };

  /**
   * Legacy data validation function - currently disabled
   * Validates and synchronizes selection states with props
   */
  /* const validateData = (): void => {
    const { workspace, scenario, origin } = props;
    const newWorkspace = workspaceValue ? workspaceValue.value : null;
    if (workspace.value.value !== newWorkspace) setWorkspace(workspace?.value);
    const newScenario = scenarioValue ? scenarioValue.value : null;
    if (scenario.value.value !== newScenario) setScenario(scenario?.value);
    const newOrigin = originValue ? originValue?.value : null;
    if (origin?.value?.value !== newOrigin) setOrigin(origin?.value);
  };
 */
  // Enable save button only when all selections are made
  let activeSave = false;
  if (workspaceValue && scenarioValue && originValue) activeSave = true;

  return (
    <Modal
      title={t("context-modal.title")}
      show={open}
      onClose={(): void => {
        props.onClose();
      }}
      headerColor="#294870"
    >
      <div className={css.context_content}>
        <div className={css.context_select}>
          <div className={css.input_select}>
            <label>{t("context-modal.atmosphere")}</label>
            <WorkspaceSelect
              workspaceValue={workspaceValue}
              onChange={(value: any): void => {
                setWorkspace(value);
                setScenario(null);
              }}
            />
          </div>
          <div className={css.input_select}>
            <label>{t("context-modal.scenery")}</label>
            <ScenariosSelect
              workspaceValue={workspaceValue}
              scenarioValue={scenarioValue}
              onChange={(value: any): void => {
                setScenario(value);
                setOrigin(null);
              }}
            />
          </div>
          <div className={css.input_select}>
            <label>{t("context-modal.process")}</label>
            <OriginSelect
              scenarioValue={scenarioValue}
              originValue={originValue}
              onChange={(value: any) => setOrigin(value)}
            />
          </div>
        </div>
        <footer
          className={cx(css.context_form, showButtonLanguage && css.context_form__btnTranslation)}
        >
          {showButtonLanguage && (
            <Button onClick={changeLanguage}>{t("context-modal.btn-change-language")}</Button>
          )}
          <Button
            disabled={!activeSave}
            onClick={(): void => {
              props.onClose();
              props.onSave({
                context: {
                  workspace: workspaceValue,
                  scenario: scenarioValue,
                  origin: originValue,
                },
              });
            }}
          >
            {t("context-modal.btn-save")}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

export default ContextModal;
