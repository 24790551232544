import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { orderBy } from "lodash";
import { DataQuery, Block } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { AppContext } from "../context/EditSessionsContext";
import { Types, Option } from "../context/linkData.reducer";
import blocksRangesQuery from "../graphql/blocksRangesData.query.graphql";

interface useGetBlocksProps {
  shoudSetContext?: boolean;
}

export const useGetBlocks = (
  props?: useGetBlocksProps,
): [
  { dataStart: Option[]; dataEnd: Option[]; rawBlocks: Block[]; isLoading: boolean },
  () => void,
] => {
  const { shoudSetContext = true } = props ?? {};
  const { scenario }: IParams = useParams();
  const { state, dispatch } = useContext(AppContext);
  const client = useApolloClient();
  const [dataStart, setDataStart] = useState<Option[]>(null);
  const [dataEnd, setDataEnd] = useState<Option[]>(null);
  const [rawBlocks, setRawBlocks] = useState<Block[]>(null);
  const [isLoading, setIsLoading] = useState(false);

  const variables = {
    scenarioId: scenario,
  };

  const uniqueValues = (value: string[][]) => {
    const options = [];
    const unique = value.flat().filter((item, pos) => value.flat().indexOf(item) === pos);
    unique.map((hour: any) => options.push({ label: hour.replace(":00", ""), value: hour }));
    return options;
  };

  const formatOptionsRanges = (blocks: Block[]) => {
    let startOptions = [];
    let endOptions = [];
    if (blocks) {
      // array of starting and ending time
      const timesStart = blocks.map(item => [item.startingTime]);
      const timesEnd = blocks.map(item => [item.endingTime]);
      // filter unique value
      startOptions = uniqueValues(timesStart);
      endOptions = uniqueValues(timesEnd);
    }

    return {
      startOptions: orderBy(startOptions, ["asc"]),
      endOptions: orderBy(endOptions, ["asc"]),
    };
  };

  const getBlocksInfo = async () => {
    try {
      setIsLoading(true);
      const { data, errors } = await client.query({
        query: blocksRangesQuery,
        variables,
        fetchPolicy: "no-cache",
      });

      if (errors) {
        console.debug("[GetBlocks error]", errors);
        setIsLoading(false);
        return null;
      }

      const dataQuery: DataQuery = data.data;
      const format = formatOptionsRanges(dataQuery.allBlocks);

      if (format.startOptions && format.endOptions) {
        setDataStart(format.startOptions);
        setDataEnd(format.endOptions);
        setRawBlocks(dataQuery.allBlocks);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!state.link?.blockRanges && dataStart && dataEnd && shoudSetContext) {
      dispatch({
        type: Types.BlockRanges,
        payload: {
          start: dataStart,
          end: dataEnd,
        },
      });
    }
  }, [dataStart, dataEnd, shoudSetContext]);

  useEffect(() => {
    if (rawBlocks && rawBlocks.length > 0 && shoudSetContext) {
      const key = (block: Block) => `${block.startingTime} - ${block.endingTime}`;

      // define unique start-end blocks
      const [uniqueRawBlocks] = rawBlocks.reduce(
        ([uniques, uniqueTable], block) => {
          if (key(block) in uniqueTable) return [uniques, uniqueTable];
          uniqueTable[key(block)] = true;
          (uniques as Block[]).push(block);
          return [uniques, uniqueTable];
        },
        [[], {}],
      );

      dispatch({
        type: Types.RawBlocks,
        payload: uniqueRawBlocks,
      });
    }
  }, [rawBlocks, shoudSetContext]);

  return [
    {
      dataStart,
      dataEnd,
      rawBlocks,
      isLoading,
    },
    getBlocksInfo,
  ];
};
