import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@foris/avocado-ui";
import { Text } from "@foris/avocado-suite";
import useDisableSection from "../hooks/useDisableSection";
import Modal from "@modules/sections/GroupsManager/components/Modal/Modal";
import { useTranslation } from "react-i18next";

interface DeleteSectionModalProps {
  isOpen?: boolean;
  onCancel?: () => void;
}

const DeleteSectionModal = ({ isOpen, onCancel }: DeleteSectionModalProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { handleDisableSection, validations, setValidations, isLoading } = useDisableSection({
    onSuccess: () => setShowSuccessMessage(true),
  });

  const handleOnClose = () => {
    if (isLoading || showSuccessMessage) return;

    setValidations([]);
    onCancel?.();
  };

  const handleCloseSuccessMessage = () => {
    if (!showSuccessMessage) return;

    setShowSuccessMessage(false);
    history.push("/");
  };

  return (
    <>
      <Snackbar
        type="confirm"
        setValueActive={handleCloseSuccessMessage}
        active={showSuccessMessage}
        icon="circle-full-check"
        duration={5}
      >
        {t("dimension.section.delete-section.success-message")}
      </Snackbar>

      <Modal
        typeState="confirm"
        show={isOpen}
        title={t("dimension.section.delete-section.confirm-modal.title")}
        subtitle={t("dimension.section.delete-section.confirm-modal.subtitle")}
        onClose={() => !isLoading && handleOnClose?.()}
        colorButtonPrincipal="danger"
        textButtonPrincipal={t("dimension.section.delete-section.confirm-modal.action-btn")}
        textButtonSecondary={t("generals.cancel")}
        onClickPrincipal={() => handleDisableSection()}
        onClickSecondary={() => !isLoading && handleOnClose?.()}
        isLoading={isLoading}
        isDisabledButtonPrincipal={showSuccessMessage}
        isDisabledButtonSecondary={showSuccessMessage}
      >
        <Text>{t("dimension.section.delete-section.confirm-modal.description")}</Text>
      </Modal>

      <Modal
        typeState="error"
        show={!!validations?.length}
        title={t("dimension.section.delete-section.error-modal.title")}
        onClose={() => !isLoading && handleOnClose()}
        textButtonSecondary={t("generals.ok")}
        onClickSecondary={() => !isLoading && handleOnClose()}
        isLoading={isLoading}
      >
        {validations.map((validation, index) => (
          <>
            <Text key={index}>{validation?.message}</Text>
            {index < validations.length - 1 && <br key={`br-${index}`} />}
          </>
        ))}
      </Modal>
    </>
  );
};

export default DeleteSectionModal;
