import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Heading, RoundButton, Select } from "@foris/avocado-suite";
import cx from "classnames";
import Layout from "@common/layout/Layout";
import AdvanceSearchClassRoom from "@dimensions/classroom/advance-search";
import AdvanceSearchInstructor from "@dimensions/instructor/advance-search";
import AdvanceSearchPopulation from "@dimensions/population/advance-search";
import AdvanceSearchCourse from "@dimensions/course/advance-search";
import AdvanceSearchSection from "@dimensions/section/advance-search";
import AdvanceSearchGroup from "@dimensions/group/advance-search";
import AdvanceSearchLink from "@dimensions/link/advance-search";
import AdvanceSearchPackage from "@dimensions/package/advance-search";
import { Header } from "@common/components";
import { getOptionsFilter } from "@common/layout/components/context-search/utils/contextOptions.util";
import { PathParams } from "@edhtypes/general";
import css from "./advanceSearch.module.scss";

const DIMENSION_COMPONENT: Record<string, React.FC | null> = {
  classroom: AdvanceSearchClassRoom,
  instructor: AdvanceSearchInstructor,
  population: AdvanceSearchPopulation,
  course: AdvanceSearchCourse,
  section: AdvanceSearchSection,
  group: AdvanceSearchGroup,
  league: AdvanceSearchLink,
  package: AdvanceSearchPackage,
};

const AdvanceSearch: React.FC = (props: any) => {
  const { dimension }: PathParams = useParams();
  const [selectedDimension, setSelectedDimension] = useState(null);

  useEffect(() => {
    if (dimension) {
      const matchedDimension = getOptionsFilter().find(value => value.value === dimension) || null;
      setSelectedDimension(matchedDimension);
    }
  }, [dimension]);

  const { t } = useTranslation();

  const SelectComponent = selectedDimension ? DIMENSION_COMPONENT[selectedDimension?.value] : null;

  return (
    <Layout contextSearch={false}>
      <Header
        title={t("advanced-search.breadcrumb.current")}
        breadcrumbItems={[
          {
            path: "/",
            title: t("advanced-search.breadcrumb.previous"),
          },
          {
            title: t("advanced-search.breadcrumb.current"),
          },
        ]}
      />
      <section className={cx(css.cntAdvanceSearch)}>
        <Heading className={css.cntAdvanceSearch_title} type="h2">
          <RoundButton icon="chevron-left" variant="ghost" onClick={() => props.history.goBack()} />
          {t("advanced-search.title")}
        </Heading>

        <Select
          className={css.cntAdvanceSearch_select}
          label={t("advanced-search.select-dimension.label")}
          placeholder={t("advanced-search.select-dimension.placeholder")}
          value={selectedDimension}
          options={getOptionsFilter()}
          onChange={(value: any) => {
            setSelectedDimension(value);
          }}
        />

        {SelectComponent && <SelectComponent />}
      </section>
    </Layout>
  );
};

export default AdvanceSearch;
