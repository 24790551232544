import { DimensionType } from "@models/IDimension";
import useContextUrl from "./useContextUrl";

interface BaseParams {
  contextUrl: string;
  baseUrl: string;
}

const routesConfig = {
  advancedSearch: "/editor/advanceSearch/",
  editor: "/scheduler/editor/",
  bundle: "/editor/vacancies/",
  searchResults: "/scheduler/editor/searchBy/",
  changeHistory: "/editor/groups-manager/change_history/",
  linkChangeHistory: "/editor/link/change-history/",
  packagesAndGroupsReport: "/report/packages-and-groups/",
  groupsManager: "/editor/groups-manager/",
  createSection: "/editor/create-section/",
  createPackage: "/editor/create-package/",
  bookingList: "/booking/list/",
  home: "/home/",
  darwinUrl: process.env.REACT_APP_DARWINED_URL,
  holidays: "/editor/session/holidays/",
};

const getAdvancedSearchUrl = ({
  contextUrl,
  baseUrl,
  dimension,
}: BaseParams & {
  dimension?: DimensionType;
}) => {
  if (dimension) {
    return `${baseUrl}${dimension}/${contextUrl}`;
  }

  return baseUrl + contextUrl;
};

/**
 * Custom hook that generates URLs for navigation within the application based on the current context.
 *
 * @returns An object containing functions to generate URLs for advanced search, editor, and bundle pages.
 */
const useNavigationUrl = () => {
  const { scenario, origin, workspace } = useContextUrl();
  const contextUrl = `${workspace}/${scenario}/${origin}`;

  return {
    advancedSearchUrl: (dimension?: DimensionType) =>
      getAdvancedSearchUrl({ contextUrl, baseUrl: routesConfig.advancedSearch, dimension }),
    editorUrl: (dimension: DimensionType, id: string) =>
      `${routesConfig.editor}${dimension}/${contextUrl}/${id}`,
    bundleUrl: (bundleId: string) => `${routesConfig.bundle}${contextUrl}/${bundleId}`,
    advancedSearchResultsUrl: (dimension: DimensionType, id: string) =>
      `${routesConfig.searchResults}${dimension}/${contextUrl}/?advance=${id}`,
    advancedSearchWithValueSelectUrl: (valueSelect: string) =>
      `${routesConfig.advancedSearch}${valueSelect}/${contextUrl}`,
    changeHistoryUrl: () => `${routesConfig.changeHistory}${contextUrl}`,
    linkChangeHistoryUrl: () => `${routesConfig.linkChangeHistory}${contextUrl}`,
    packagesAndGroupsReportUrl: () => `${routesConfig.packagesAndGroupsReport}${contextUrl}`,
    groupsManagerUrl: () => `${routesConfig.groupsManager}${contextUrl}`,
    createSectionUrl: () => `${routesConfig.createSection}${contextUrl}`,
    createPackageUrl: () => `${routesConfig.createPackage}${contextUrl}`,
    bookingListUrl: () => `${routesConfig.bookingList}${contextUrl}`,
    homeUrl: (workspaceId: string, scenarioId: string, originId: string) =>
      `${routesConfig.home}${workspaceId}/${scenarioId}/${originId}`,
    getPackageClashReportUrl: () =>
      `${routesConfig.darwinUrl}resultados/report_package_clashes_edh/${origin}?scenario_id=${scenario}`,
    getDarwinUrl: () => `${routesConfig.darwinUrl}`,
    holidaysUrl: () => `${routesConfig.holidays}${contextUrl}`,
  };
};

export default useNavigationUrl;
