/**
 * Main application layout that provides the core UI structure and navigation framework.
 *
 * Key features:
 * - Responsive navigation with collapsible sidebar for mobile devices
 * - Dynamic document title management based on current route context
 * - Contextual search integration with optional display control
 * - Scenario-aware routing with workspace and origin context preservation
 * - Scroll locking mechanism for improved mobile UX when sidebar is open
 *
 * @param {boolean} contextSearch - Controls the visibility of the search autocomplete component
 * @param {React.ReactNode} children - Content to be rendered within the layout structure
 */

import React, { FC, ClassAttributes, useEffect, useState, useMemo, useRef } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Icon } from "@foris/avocado-icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Heading } from "@foris/avocado-suite";
import { useScrollLock } from "@common/hooks/useScrollLock";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import useModulesByCode from "@common/hooks/useModulesByCode";
import useContextUrl from "@common/hooks/useContextUrl";
import SidebarNavigation from "./components/sidebar-navigation";
import UserMenu from "./components/user-menu";
import ContextScenario from "./components/context-scenario";
import ContextSearch from "./components/context-search";
import logo from "@assets/svg/logoDarwin.svg";
import "@assets/scss/app.scss";
import css from "./layout.module.scss";
import useClickOutside from "@common/hooks/useClickOutside";

interface LayoutProps extends ClassAttributes<any> {
  contextSearch: boolean;
  children?: React.ReactNode | undefined;
}

// Maps route contexts to their respective page titles for document title management
const pageTitles = {
  EDH: "home.modules.scheduler-editor",
  Booking: "home.modules.booking",
};

const Layout: FC<LayoutProps> = ({ contextSearch, children }) => {
  const menuRef = useRef(null);
  const { workspace, scenario, origin } = useContextUrl();
  const { homeUrl } = useNavigationUrl();
  const { modulesByCode } = useModulesByCode();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isShowUserMenu, setIsShowUserMenu] = useState(false);

  // Determines current module context for title and UI customization
  const isInBooking = location?.pathname.includes("booking");
  const pageTitle = isInBooking ? t(pageTitles.Booking) : t(pageTitles.EDH);

  // Constructs context-aware home URL preserving workspace, scenario, and origin parameters
  const urlHome = workspace && scenario && origin ? homeUrl(workspace, scenario, origin) : "/home`";

  // Prevents main content scrolling when mobile navigation is active
  useScrollLock(isSidebarOpen);
  const canAccessEDH = useMemo(() => {
    return Object.values(modulesByCode)?.some(
      item => item?.code === "scheduler_editor" && item?.accesible,
    );
  }, [modulesByCode]);

  const isEdhPath = useMemo(() => {
    const regex = /\/(home|auth)/;
    return !regex.test(location?.pathname) && location?.pathname !== "/";
  }, [location?.pathname]);

  // Updates document title to reflect current module context
  useEffect(() => {
    document.title = `Darwin${isEdhPath ? ` | ${pageTitle}` : ""}`;
  }, [pageTitle]);

  if (!canAccessEDH && isEdhPath) {
    history.replace(urlHome);
  }

  useClickOutside({
    ref: menuRef,
    onClickOutside: () => setIsShowUserMenu(false),
  });

  return (
    <section className={css.layout}>
      {/* Top fixed menu (mobile/sidebar behavior) */}
      <SidebarNavigation isVisible={isSidebarOpen} onSidebarToggle={setIsSidebarOpen} />

      <header className={cx(css.header, "container-row", "row--noWrap")}>
        {/* Burger menu toggle */}
        <button className={css.btnMenu} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <Icon name="menu" size="lg" color="neutral-50" />
        </button>

        {/* Logo with link to contextual home */}
        <Link to={urlHome} className={css.cntImage}>
          <img
            src={logo}
            alt="Editor de Horarios"
            className={css.img}
            width="108.203px"
            height="28.668px"
          />
        </Link>

        {/* Current module title */}
        {isEdhPath && (
          <Heading type="h3" className={css.header_moduleName}>
            {pageTitle}
          </Heading>
        )}

        {/* Optional contextual search bar */}
        <div className={css.cntInfo}>
          {!!contextSearch && <ContextSearch className={css.cntInfo_autocomplete} />}
        </div>

        {/* Context scenario selector */}
        <ContextScenario />

        {/* Top-level main menu */}
        <div ref={menuRef}>
          <button
            onClick={() => setIsShowUserMenu(!isShowUserMenu)}
            className={cx(css.containerUser, isShowUserMenu && css.containerUser__active)}
          >
            <div className={css.containerUser_btn}>
              <Icon name="user" filled color="neutral-00" />
            </div>
          </button>
          {isShowUserMenu && <UserMenu />}
        </div>
      </header>

      {/* Page content */}
      {children}
    </section>
  );
};

export default Layout;
