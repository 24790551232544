import { useState } from "react";
import { useMutation } from "react-apollo";
import useContextUrl from "@common/hooks/useContextUrl";
import disableSectionMutation from "../graphql/disableSection.mutation.graphql";
import { EditSectionPayload } from "@models/ISchema";
import { IErrorWarning } from "@models/Errors/ErrorWarning";
import SectionDelete from "@models/Errors/SectionDelete";

interface useDisableSectionProps {
  onSuccess?: () => void;
  onError?: () => void;
}

const useDisableSection = (props?: useDisableSectionProps) => {
  const { onSuccess, onError } = props || {};
  const { scenario, origin, id } = useContextUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [validations, setValidations] = useState<IErrorWarning[]>([]);
  const [disableSection] = useMutation(disableSectionMutation);

  const handleDisableSection = async (skipValidations = false) => {
    try {
      setIsLoading(true);
      setValidations([]);

      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: {
          sectionId: id,
          dryRun: false,
          skipValidations,
        },
      };

      const { data } = await disableSection({ variables });
      const result: EditSectionPayload = data.cube.disableSection;

      if (result.validationErrors && result.validationErrors.length > 0) {
        const errorsValidations = [];
        result.validationErrors.forEach(error => {
          const errorSection = new SectionDelete(error);
          errorsValidations.push(errorSection.getMessage());
        });

        setValidations(errorsValidations);
        onError?.();
      }

      if (result.commited) {
        onSuccess?.();
      }

      setIsLoading(false);
    } catch (error) {
      console.error("[useDisableSection]", error);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    validations,
    handleDisableSection,
    setValidations,
  };
};

export default useDisableSection;
