import React, { useContext, useMemo } from "react";
import { Icon } from "@foris/avocado-icons";
import { Instructor, RecommendationAlternatives } from "@models/ISchema";
import { EditedSession, Types } from "@modules/sections/EditSessions/context/formData.reducer";
import { AppContext } from "@modules/sections/EditSessions/context/EditSessionsContext";
import RecommendationsSectionRow from "../recommendations-section-row";
import { ContextApp } from "@config/Context/contextApp";
import { useTranslation } from "react-i18next";

interface RecommendationsSectionInstructorsProps {
  alternatives: RecommendationAlternatives;
  editedSession: EditedSession;
}

const RecommendationsSectionInstructors = ({
  alternatives,
  editedSession,
}: RecommendationsSectionInstructorsProps) => {
  const { dispatch, state } = useContext(AppContext);
  const { user } = useContext(ContextApp);
  const { t } = useTranslation();

  const canAssignInstructors =
    user?.abilities?.can_assign_instructors && state?.form?.isEditionEnabled?.["instructors"];

  const editedSessionInstructorsById = useMemo(() => {
    return editedSession?.instructors?.reduce((acc, instructor) => {
      acc[instructor?.id] = instructor;
      return acc;
    }, {});
  }, [editedSession]);

  const handleSelectInstructor = (instructor: Instructor) => {
    const isSelected = !!editedSessionInstructorsById?.[instructor?.id];

    if (!instructor?.usable || !canAssignInstructors) {
      return;
    }

    const instructorsList = [...(editedSession?.instructors ?? [])];

    if (!isSelected) {
      const adaptedInstructor = {
        ...instructor,
        label: `${instructor.code} - ${instructor.name}`,
        value: instructor.id,
        isDisabled: !instructor?.usable,
      };

      instructorsList.push(adaptedInstructor);
    } else {
      const instructorIndex = instructorsList.findIndex(i => i.id === instructor.id);
      instructorsList.splice(instructorIndex, 1);
    }

    dispatch({
      type: Types.InstructorEditedSessions,
      payload: {
        instructors: instructorsList,
      },
    });
  };

  const instructors = useMemo(() => {
    return alternatives?.instructors?.map(instructor => ({
      label: (
        <>
          {!!alternatives?.assignedInstructors?.find(
            aInstructor => aInstructor?.id === instructor?.id,
          ) && <Icon name="alert-triangle" color="warning-50" />}

          {instructor?.code}
        </>
      ),
      isActive: !!editedSessionInstructorsById?.[instructor?.id],
      isDisabled: !instructor?.usable || !canAssignInstructors,
      onClick: () => handleSelectInstructor(instructor),
    }));
  }, [
    alternatives?.assignedInstructors,
    alternatives?.instructors,
    editedSessionInstructorsById,
    canAssignInstructors,
  ]);

  return (
    <RecommendationsSectionRow
      title={`${t("assignation-edit.recommendation-card.alternatives.instructor-alternatives")}:`}
      items={instructors}
    />
  );
};

export default RecommendationsSectionInstructors;
