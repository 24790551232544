import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useApolloClient } from "react-apollo";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-suite";
import { ISelectorCourseMultiKey, IOption } from "./ISelectors";
import { coursesMultiKey } from "../graphql/courseMultiKey.queries";
import { IParams } from "@models/IParams";
import { Course, PageInfo } from "@models/ISchema";
import css from "./selectors.module.scss";

const renderSuggestion = (course: Course, showTitle = true, showContent = true) => {
  return (
    <section className={cx(css.cntOptions)}>
      {showTitle && (
        <p className={css.cntOptions_title}>
          {`${course.code} `}
          <span className={css.bold}>{course.name}</span>
        </p>
      )}
      {showContent && (
        <>
          <div className={cx(css.detailOption, "container-row")}>
            <p className={css.detailOption_item}>
              SE:
              <span className={css.bold}>{course?.curriculum?.program?.campus?.code || "-"}</span>
            </p>
            <p className={css.detailOption_item}>
              ES:{" "}
              <span className={css.bold}>
                {course?.curriculum?.program?.department?.code || "-"}
              </span>
            </p>

            <p className={css.detailOption_item}>
              MO:
              <span className={css.bold}>{course?.curriculum?.program?.modality?.code || "-"}</span>
            </p>

            <p className={css.detailOption_item}>
              JO:{" "}
              <span className={css.bold}>{course?.curriculum?.program?.shift?.code || "-"}</span>
            </p>

            <p className={css.detailOption_item}>
              NI: <span className={css.bold}>{course?.level || "-"}</span>
            </p>
          </div>
          <div className={cx(css.detailOption, "container-row")}>
            <p className={css.detailOption_item}>
              {`CA: ${course?.curriculum?.program?.code || "-"}`}
              <span className={css.bold}>{course?.curriculum?.program?.name || "-"}</span>
            </p>
            <p className={css.detailOption_item}>
              CU
              <span className={css.bold}>{` ${course?.curriculum?.code || "-"}`}</span>
            </p>
          </div>
        </>
      )}
    </section>
  );
};

const CourseMultiKey = (props: ISelectorCourseMultiKey) => {
  const {
    className,
    onCallback,
    defaultCourse,
    programId,
    modalityId,
    campusId,
    isDisabled,
    label,
    placeholder,
  } = props;
  const { scenario: scenarioId }: IParams = useParams();
  const client = useApolloClient();
  const [options, setOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState<IOption>();
  const [listTerms, setListTerms] = useState([]);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  const getCourses = async (searchValue = "") => {
    const terms = [...listTerms.map(term => term.value), searchValue]?.filter(term => !!term);
    try {
      const { data } = await client.query({
        query: coursesMultiKey,
        variables: {
          scenarioId,
          filter: {
            pagination: { size: 30, page },
            fields: {
              terms: terms?.length ? terms : undefined,
              active: true,
              program: !!(programId || modalityId || campusId)
                ? {
                    id: programId
                      ? {
                          eq: programId,
                        }
                      : undefined,
                    campusId: campusId
                      ? {
                          eq: campusId,
                        }
                      : undefined,
                    modalityId: modalityId
                      ? {
                          eq: modalityId,
                        }
                      : undefined,
                  }
                : undefined,
            },
          },
        },
      });
      const courses: Course[] = data.data.courses.items;
      const pageInfo: PageInfo = data?.data?.courses?.pageInfo;

      const formatOptions = courses.map(course => ({
        value: {
          idCampus: course.curriculum.program.campus.id,
          idDepartment: course.curriculum.program.department.id,
          idModality: course.curriculum.program.modality.id,
          idShift: course.curriculum.program.shift.id,
          objLevel: course.level,
          idProgram: course.curriculum.program.id,
          idCourse: course.id,
          idCurriculum: course.curriculum.id,
        },
        key: course,
        label: renderSuggestion(course),
      }));

      const newOptions = page === 1 ? formatOptions : [...options, ...formatOptions];

      setOptions(newOptions);

      return {
        courses: newOptions,
        pageInfo,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = async (newValue, actionMeta) => {
    const option =
      newValue !== null && Array.isArray(newValue) ? newValue[newValue.length - 1] : newValue;
    switch (actionMeta.action) {
      case "create-option":
        break;
      case "pop-value":
        setListTerms(newValue);
        setSelectedOption(null);
        await getCourses(newValue);
        break;
      case "select-option":
        setSelectedOption({
          ...option,
          label: renderSuggestion(option.key, true, false),
        });
        await getCourses();
        break;
      case "clear":
        setListTerms([]);
        setSelectedOption(null);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (inputValue: any, actionMeta: any) => {
    if (selectedOption) setListTerms([]);
    if (!inputValue) return;
    switch (actionMeta.action) {
      case "input-change":
        setSelectedOption(null);
        break;
    }
  };

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = newSearchTerm && prevSearchTerm === newSearchTerm ? page + 1 : 1;

    setPrevSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { courses, pageInfo } = await getCourses(newSearchTerm);

    return {
      options: courses,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  useEffect(() => {
    if (defaultCourse)
      setSelectedOption({
        value: {
          idCampus: defaultCourse.curriculum.program.campus.id,
          idDepartment: defaultCourse.curriculum.program.department.id,
          idModality: defaultCourse.curriculum.program.modality.id,
          idShift: defaultCourse.curriculum.program.shift.id,
          objLevel: defaultCourse.level,
          idProgram: defaultCourse.curriculum.program.id,
          idCourse: defaultCourse.id,
          idCurriculum: defaultCourse.curriculum.id,
        },
        key: defaultCourse,
        label: renderSuggestion(defaultCourse, true, false),
      });
  }, [defaultCourse]);

  useEffect(() => {
    if (selectedOption) onCallback(selectedOption.value);
    else onCallback(null);
  }, [selectedOption]);

  return (
    <div className={cx(css.container, className)}>
      <SelectPagination
        className={css.selectors}
        label={label}
        placeholder={placeholder}
        isClearable
        value={selectedOption || listTerms}
        debounceTimeout={1000}
        loadOptions={loadOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        disabled={isDisabled}
      />

      {!!selectedOption && (
        <div className={css.container_informationBox}>
          {renderSuggestion(selectedOption.key, false, true)}
        </div>
      )}
    </div>
  );
};

export default CourseMultiKey;
