import React, { useContext, useMemo } from "react";
import { Day, RecommendationAlternatives } from "@models/ISchema";
import { EditedSession, Types } from "@modules/sections/EditSessions/context/formData.reducer";
import { AppContext } from "@modules/sections/EditSessions/context/EditSessionsContext";
import { ContextApp } from "@config/Context/contextApp";
import { TabDay } from "../recommendations-section/RecommendationsSection";
import RecommendationsSectionRow from "../recommendations-section-row";
import { useTranslation } from "react-i18next";

interface RecommendationsSectionScheduleProps {
  alternatives: RecommendationAlternatives;
  activeDay: TabDay;
  editedSession: EditedSession;
}

const getAdaptedTime = (time: string) => {
  const [hours, minutes] = time.split(":");
  return `${hours?.length === 1 ? `0${hours}` : hours}:${
    minutes?.length === 1 ? `0${minutes}` : minutes
  }`;
};

const getIsSelectedSchedule = (day: Day, startTime: string, editedSession: EditedSession) => {
  const { blocks } = editedSession ?? {};
  const [hours, minutes] = (blocks?.startTime ?? "")?.split(":");
  const blockStartTime = `${hours}:${minutes}`;
  const adaptedStartTime = getAdaptedTime(startTime);

  return blocks?.day === day && blockStartTime === adaptedStartTime && blocks?.selected === "hours";
};

const RecommendationsSectionSchedule = ({
  alternatives,
  activeDay,
  editedSession,
}: RecommendationsSectionScheduleProps) => {
  const { dispatch, state } = useContext(AppContext);
  const { user } = useContext(ContextApp);
  const { t } = useTranslation();

  const canAssignBlocks =
    user?.abilities?.can_assign_blocks && state?.form?.isEditionEnabled?.["blocks"];

  const isSelected = useMemo(
    () => getIsSelectedSchedule(activeDay.enName, alternatives?.start_time, editedSession),
    [activeDay.enName, alternatives?.start_time, editedSession],
  );

  const handleSelectSchedule = (day: Day, startTime: string) => {
    if (!canAssignBlocks || isSelected) {
      return;
    }

    dispatch({
      type: Types.BlocksEditedSessions,
      payload: {
        blocks: {
          ...editedSession?.blocks,
          startTime: `${getAdaptedTime(startTime)}:00`,
          day,
        },
      },
    });
  };

  return (
    <RecommendationsSectionRow
      title={`${t("assignation-edit.recommendation-card.alternatives.schedule-alternative")}:`}
      items={[
        {
          label: (
            <>
              {activeDay?.label} {getAdaptedTime(alternatives?.start_time)}
            </>
          ),
          isActive: isSelected,
          isDisabled: editedSession?.blocks?.selected !== "hours" || !canAssignBlocks,
          onClick: () => handleSelectSchedule(activeDay.enName, alternatives?.start_time),
        },
      ]}
    />
  );
};

export default RecommendationsSectionSchedule;
