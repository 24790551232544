import React, { useContext, useState, useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Snackbar } from "@foris/avocado-ui";
import { AppContext } from "../../context/EditSessionsContext";
import { Types, EditedSession } from "../../context/formData.reducer";
import ClassroomSection from "./classroom-section";
import Repeat from "../../components/Repeat/Repeat";
import DaysSection from "../../components/days-section";
import BlockRange from "../../components/BlockRange/BlockRange";
import FormHeader from "../../components/FormEdit/FormHeader";
import InstructorSection from "./instructor-section";
import { sessionsHaveTheSameResources } from "../../utils/sessionsHaveTheSameResources";
import { IEvent } from "@modules/sections/ISections";
import { Section, Session } from "@models/ISchema";
import { ContextApp } from "@config/Context/contextApp";
import { CardNotification } from "@foris/avocado-suite";
import AbilitiesMessage from "./AbilitiesMessage";
import WeeksForkCard from "../../components/weeks-fork-card";
import RecommendationsSection from "./recommendations-section";
import css from "./formEdit.module.scss";

interface ILocationState {
  sessionId?: number;
  event?: IEvent;
}

const EditSession: React.FC = () => {
  const { user } = useContext(ContextApp);
  const { state, dispatch } = useContext(AppContext);
  const [currentEditedSessions, setCurrentEditedSessions] = useState<EditedSession>({});
  const [forkingIsAllowed, setForkingIsAllowed] = useState(true);
  const [originalWeeksBySessionId, setOriginalWeeksBySessionId] = useState<{
    [key: string]: string[];
  }>({});
  const location = useLocation();
  const locationState: ILocationState = location.state;
  const eventState = locationState?.event;
  const [isInitialEditSet, setIsInitialEditSet] = useState(false);
  const [activeSanckbar, setActiveSanckbar] = useState(false);
  const [forkedWeeksBySessionIds, setForkedWeeksBySessionIds] = useState({});
  const [showRecommendations, setShowRecommendations] = useState(false);

  const selectedSessions = state?.form?.selectedSessions;
  const abilities = user?.abilities;

  const { t } = useTranslation();

  /**
   * Allow/Disallow the sessions forking
   */
  useEffect(() => {
    const sameIntervals = (state?.form?.selectedSessions ?? [])
      .filter((session: EditedSession) => !session?.id?.includes("-"))
      .map((session: EditedSession) => originalWeeksBySessionId[session.id] || [])
      .reduce(
        ({ eq, prev }, curr) => ({
          eq: !prev || !eq ? eq : JSON.stringify(prev) === JSON.stringify(curr),
          prev: curr,
        }),
        { eq: true, prev: null },
      ).eq;

    const intervalsHasBeenEdited = Boolean(state?.form?.assignmentEdited?.intervals);
    const someSelectedSessionHasDeletedWeeks = selectedSessions.some(session =>
      Object.prototype.hasOwnProperty.call(state?.form?.removedWeekIdsBySessionId, session.id),
    );
    const someSelectedSessionIsNew = selectedSessions.some(session => session?.id?.includes("-"));
    const sessionHasClonnedWeeks = selectedSessions.some(session => {
      return state?.form?.sessionsToCreate?.some(
        sessionToCreate =>
          sessionToCreate?.isCloned && sessionToCreate?.session?.id === session?.id,
      );
    });

    setForkingIsAllowed(
      sameIntervals &&
        intervalsHasBeenEdited &&
        someSelectedSessionHasDeletedWeeks &&
        !someSelectedSessionIsNew &&
        !sessionHasClonnedWeeks &&
        Object.keys(state?.form?.unmarkedWeekIdsBySessionId ?? {}).length > 0,
    );
  }, [
    state?.form?.assignmentEdited,
    state?.form?.assignmentSame,
    state?.form?.removedWeekIdsBySessionId,
    state?.form?.unmarkedWeekIdsBySessionId,
    selectedSessions,
  ]);

  useEffect(() => {
    if (!locationState?.sessionId || !state?.link?.sections?.length) return;

    const { sessionId } = locationState;
    let selectedSession: EditedSession = {};

    state?.link?.sections.forEach((section: Section) => {
      const sessions = section.sessions || [];
      const unasignedSessions = section.unasignedSessions || [];
      const allSessions = [...sessions, ...unasignedSessions];

      allSessions.forEach(session => {
        if (!Object.keys(selectedSession).length && parseInt(session.id || "0") === sessionId) {
          selectedSession = session;
        }
      });
    });

    if (Object.keys(selectedSession).length && !state?.form?.sessionFromLocationSelected) {
      dispatch({ type: Types.SelectedSessions, payload: selectedSession });
      dispatch({ type: Types.SetSessionFromLocationSelected, payload: true });
    }
  }, []);

  useEffect(() => {
    if (!isInitialEditSet && !!eventState?.id && !!state?.form?.editedSessions?.blocks) {
      setIsInitialEditSet(true);

      dispatch({
        type: Types.BlocksEditedSessions,
        payload: {
          blocks: {
            ...state?.form?.editedSessions?.blocks,
            startTime: eventState?.resource?.blockRange?.start?.startingTime,
            endTime: eventState?.resource?.blockRange?.end?.endingTime,
            day: eventState?.resource?.blockRange?.start?.day,
          },
        },
      });
    }

    if (
      Boolean(state?.form?.editedSessions) &&
      !sessionsHaveTheSameResources(currentEditedSessions, state?.form?.editedSessions)
    ) {
      setCurrentEditedSessions(state?.form?.editedSessions);
    }
  }, [state?.form?.editedSessions]);

  useEffect(() => {
    if (Boolean(currentEditedSessions)) {
      dispatch({ type: Types.SavedSessions, payload: [currentEditedSessions] });
    }
  }, [currentEditedSessions]);

  useEffect(() => {
    if (!state?.link?.sections) return;

    const sortedIds = (sessions: any[]) =>
      sessions
        .slice()
        .sort((a, b) => (a.id || "").localeCompare(b.id || ""))
        .map(session => session.id || "");

    setOriginalWeeksBySessionId(
      state.link.sections.reduce((acc: any, section: Section) => {
        const sessionsWithSchedules = section.sessions || [];
        const sessionsWithoutSchedules = section.unasignedSessions || [];
        const allSessions = [...sessionsWithSchedules, ...sessionsWithoutSchedules];

        allSessions.forEach((session: Session) => {
          acc[session.id] = sortedIds(session.assignment?.intervals || []);
        });

        return acc;
      }, {}),
    );
  }, [state?.link?.sections]);

  useEffect(() => {
    const isForkedSession = (session: any) => Boolean(session?.forkedWeeksByOriginSessions);

    const newForkedWeeksBySessionIds = state?.form?.sessionsToCreate
      ?.filter(isForkedSession)
      ?.reduce((acc: any, session: any) => {
        const weeksBySessionIds = Object.entries(session?.forkedWeeksByOriginSessions || {});
        weeksBySessionIds.forEach(([sessionId, weekIds]) => {
          if (!acc[sessionId]) acc[sessionId] = [];
          acc[sessionId].push(weekIds);
        });
        return acc;
      }, {});

    const uniqueForkedWeeksBySessionIds = Object.keys(newForkedWeeksBySessionIds || {}).reduce(
      (acc: any, key: string) => {
        acc[key] = Array.from(new Set(newForkedWeeksBySessionIds[key].flat()));
        return acc;
      },
      {},
    );

    setForkedWeeksBySessionIds(uniqueForkedWeeksBySessionIds);
  }, [state?.form?.sessionsToCreate]);

  useEffect(() => {
    const removedWeekIdsBySessionId = state?.form?.removedWeekIdsBySessionId;

    const forkingAlreadyMade = state?.form?.selectedSessions?.some(session => {
      const sessionWeekForkings = forkedWeeksBySessionIds[session?.id] ?? [];
      const currentRemovedWeeks = removedWeekIdsBySessionId[session?.id] ?? [];

      return sessionWeekForkings.some(weeksForking => {
        return (
          Array.isArray(weeksForking) &&
          Array.isArray(currentRemovedWeeks) &&
          weeksForking.length > 0 &&
          currentRemovedWeeks.length > 0 &&
          JSON.stringify(weeksForking) === JSON.stringify(currentRemovedWeeks)
        );
      });
    });

    if (forkingAlreadyMade) setForkingIsAllowed(false);
  }, [forkedWeeksBySessionIds]);

  return (
    <>
      <Snackbar
        type="confirm"
        setValueActive={(value: any) => setActiveSanckbar(value)}
        active={activeSanckbar}
        icon="circle-check"
        duration={3}
      >
        {t("assignation-edit.form.notifications.session-created")}
      </Snackbar>
      <section className={css.formEdit}>
        <>
          {selectedSessions?.length ? (
            <div className={css.formEdit_content}>
              <section className={cx(css.fields)}>
                <section className={cx(css.fields_content)}>
                  <FormHeader />
                  <AbilitiesMessage className={css.infoMessage} />
                  <BlockRange disabled={!abilities?.can_assign_blocks} />
                  <DaysSection disabled={!abilities?.can_assign_blocks} />
                  <InstructorSection disabled={!abilities?.can_assign_instructors} />
                  <ClassroomSection disabled={!abilities?.can_assign_classrooms} />
                  <Repeat
                    disabled={!abilities?.can_assign_intervals}
                    originalWeeksBySessionId={originalWeeksBySessionId}
                  />

                  {forkingIsAllowed && (
                    <WeeksForkCard
                      onWeeksForked={() => {
                        setActiveSanckbar(true);
                      }}
                    />
                  )}
                </section>
                <section
                  className={cx(
                    css.recommendationsContainer,
                    showRecommendations &&
                      !!state?.link?.recommendations &&
                      css.recommendationsContainer__open,
                  )}
                >
                  <RecommendationsSection
                    isOpen={showRecommendations}
                    isLoading={
                      state?.link?.isLoadingRecommendations || !state?.link?.recommendations
                    }
                    hasErrors={state?.link?.hasErrorsRecommendations}
                    onToggle={() => setShowRecommendations(!showRecommendations)}
                  />
                </section>
              </section>
            </div>
          ) : (
            <>
              <FormHeader />
              <CardNotification
                state="info"
                title={t("assignation-edit.form.notifications.no-sessions-selected.title")}
                outlined
              >
                {t("assignation-edit.form.notifications.no-sessions-selected.description")}
              </CardNotification>
            </>
          )}
        </>
      </section>
    </>
  );
};

export default EditSession;
