import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { CardNotification } from "@foris/avocado-suite";
import { AppContext } from "../../context/EditSessionsContext";
import { Types } from "../../context/switchPage.reducer";
import { Types as TypeForm } from "../../context/formData.reducer";
import { useGetLink } from "../../hooks/useGetLinks";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import useContextUrl from "@common/hooks/useContextUrl";
import Compare from "../../components/Compare/Compare";
import IncidenceTable from "../../components/Incidence/IncidenceTable";
import useSessionsCrudMutation from "../../hooks/useSessionsCrudMutation";
import SavedSectionsList from "../../components/saved-sections-list";
import ResultsEditHeader from "../../components/results-edit-header";
import Modal from "@modules/sections/GroupsManager/components/Modal/Modal";
import css from "./resultEdit.module.scss";

interface IResultsEdit {
  heightHeader: number;
}
const ResultsEdit: React.FC<IResultsEdit> = (props: IResultsEdit) => {
  const { heightHeader } = props;
  const history = useHistory();
  const { id } = useContextUrl();
  const { state, dispatch } = useContext(AppContext);
  const [dataLinks, getDataLinks] = useGetLink();
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [resultSessionsCrud, submitSessionsCrud] = useSessionsCrudMutation({
    dryRun: false,
    skipValidations: true,
  });

  const { editorUrl } = useNavigationUrl();
  const { t } = useTranslation();

  const savedSessions = state?.form?.savedSessions ?? [];
  const sessionsToDelete = useMemo(() => Object.values(state?.form?.sessionsToDelete ?? []), [
    state?.form?.sessionsToDelete,
  ]);

  const numberOfSavedSessions = savedSessions?.length ?? 0;
  const numberOfSessionsToCreate = state?.form?.sessionsToCreate?.length ?? 0;
  const numberOfSessionsToDelete = sessionsToDelete?.length ?? 0;

  const numberOfEditedSessions = useMemo(
    () =>
      [...(savedSessions ?? [])].filter(session => !session?.isNew && !session?.isCloned).length,
    [savedSessions],
  );

  const numberOfNewSessions = useMemo(
    () => [...(savedSessions ?? [])].filter(session => session?.isNew || session?.isCloned).length,
    [savedSessions],
  );
  const someEditionMade = Boolean(
    numberOfSessionsToDelete + numberOfSavedSessions + numberOfSessionsToCreate,
  );

  const selectedEdit = state?.result?.selectSession;
  const selectedCreate = state?.result?.selectCreateSession;
  const loading = state?.page?.loading;

  const submitForm = () => {
    if (!loading && someEditionMade) {
      dispatch({ type: Types.SetLoading, payload: true });
      submitSessionsCrud();
    }
  };

  useEffect(() => {
    const responseData = resultSessionsCrud?.data;
    if (someEditionMade) {
      const validateEdited = numberOfEditedSessions > 0;
      const validateCreate = numberOfNewSessions + numberOfSessionsToCreate > 0;
      const validateDeleted = numberOfSessionsToDelete > 0;

      const goToResultView =
        (validateEdited && !validateCreate && responseData?.commited) ||
        (!validateEdited && validateCreate && responseData) ||
        (validateEdited && validateCreate && responseData) ||
        validateDeleted;

      if (goToResultView && !dataLinks && Boolean(responseData)) {
        getDataLinks();
      }
    }
    if (responseData?.error) setError(true);
  }, [resultSessionsCrud]);

  useEffect(() => {
    if (Boolean(dataLinks) && Boolean(state?.link?.info) && !confirm) {
      setConfirm(true);
      dispatch({ type: Types.SetLoading, payload: false });
    }
  }, [dataLinks]);

  if (loading) return null;

  return (
    <>
      {confirm && (
        <Modal
          typeState="confirm"
          title={t("assignation-edit.incidence-table.notifications.saved-changes.title")}
          show={confirm}
          textButtonPrincipal={t(
            "assignation-edit.incidence-table.notifications.saved-changes.principal-action",
          )}
          onClickPrincipal={() => {
            dispatch({
              type: TypeForm.CleanForm,
              payload: true,
            });

            history.push(editorUrl("link", id), {
              currentSelectedWeek: state.link.selectedWeek,
            });
          }}
          textButtonSecondary={t(
            "assignation-edit.incidence-table.notifications.saved-changes.secondary-action",
          )}
          // Reload the page to force recommendations use the new sessions
          onClickSecondary={() => window.location.reload()}
        >
          {t("assignation-edit.incidence-table.notifications.saved-changes.description")}
        </Modal>
      )}

      {error && (
        <Modal
          typeState="error"
          title={t("assignation-edit.incidence-table.notifications.error-validations.title")}
          show={error}
          textButtonPrincipal={t(
            "assignation-edit.incidence-table.notifications.error-validations.principal-action",
          )}
          onClickPrincipal={() => window.location.reload()}
        >
          {t("assignation-edit.incidence-table.notifications.error-validations.description")}
        </Modal>
      )}

      <div className={css.resultEdit}>
        {!!(selectedEdit || selectedCreate || sessionsToDelete) && (
          <ResultsEditHeader onSubmit={submitForm} />
        )}

        {!confirm && (
          <section className={cx(css.content, css[`content__height${heightHeader}`])}>
            <aside className={cx(css.aside)}>
              <SavedSectionsList />
            </aside>

            <section className={cx(css.results)}>
              {selectedEdit || selectedCreate || sessionsToDelete ? (
                <section className={css.results_content}>
                  <Compare />
                  <IncidenceTable />
                </section>
              ) : (
                <CardNotification
                  state="info"
                  title={t("assignation-edit.incidence-table.notifications.info-message.title")}
                  className={css.initCard}
                >
                  {t("assignation-edit.incidence-table.notifications.info-message.description")}
                </CardNotification>
              )}
            </section>
          </section>
        )}
      </div>
    </>
  );
};

export default ResultsEdit;
